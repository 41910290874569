import React from "react";
import "../../stylesheets/react.scss";
import { KSTKAnalyticsBreadcrumb } from "./KSTKAnalyticsBreadcrumb";
import { KSTKAnalyticsModuleReportList } from "./KSTKAnalyticsModuleReportList";

export class KSTKAnalyticsCategoryView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="kstkCategoryView">
        {/* <KSTKAnalyticsBreadcrumb changePage={this.props.changePage} /> */}
        {this.props.selectedCategory ? (
          <div className="kstkCategoryViewHeader">
            <h1>{this.props.selectedCategory.name}</h1>
            {this.props.menuMode == "reportView" ? (
              <p>{this.props.selectedCategory.description}</p>
            ) : (
              <p>{this.props.selectedCategory.moduleDescription}</p>
            )}
          </div>
        ) : null}
        {this.props.menu ? (
          <div style={{ width: "100%" }}>
            {Object.keys(this.props.menu).map((cat) => (
              <React.Fragment>
                {/* Check if there are valid categories */}
                {Object.keys(this.props.menu).length > 1 || cat != "Sem categoria" ? (
                  Object.values(this.props.menu[cat]).length > 0 ? (
                    <h3>{cat}</h3>
                  ) : null
                ) : null}
                {/* Create module/report menu entries */}
                <KSTKAnalyticsModuleReportList
                  list={this.props.menu[cat]}
                  catModuleReportClick={this.props.catModuleReportClick}
                  getFavoriteStatus={this.props.getFavoriteStatus}
                  addRemoveFavorites={this.props.addRemoveFavorites}
                />
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
