import React from "react";
import "../../stylesheets/react.scss";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";

export class KSTKAnalyticsFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="kstkFooter">
        {this.props.org && this.props.org.sponsorsLogoSrc ? (
          <img className="kstkFooterSponsorsLogo" src={this.props.org.sponsorsLogoSrc} />
        ) : null}
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://kstk-knowledge-support.pt/wp-content/uploads/2020/05/Politica-de-Privacidade-da-Plataforma-KSTK-Predictive-Analytics.pdf"
            className="kstkLinkFooter"
            style={{ marginLeft: "64px" }}
          >
            {this.props.translateText("privacyPolicy")}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://kstk-knowledge-support.pt/wp-content/uploads/2020/05/Poli%CC%81tica-de-Cookies-da-Plataforma-KSTK-Predictive-Analytics.pdf"
            className="kstkLinkFooter"
          >
            {this.props.translateText("cookiePolicy")}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://kstk-knowledge-support.pt/wp-content/uploads/2020/05/Termos-e-Condições-de-Utilização-da-Plataforma-KSTK-Predictive-Analytics.pdf"
            className="kstkLinkFooter"
          >
            {this.props.translateText("termsAndConditions")}
          </a>
        </div>
        <div className="kstkFooterSpacer"></div>
        <div>
          © {new Date().getFullYear()} - {this.props.translateText("footerCopyright")}
        </div>
        <div className="kstkAnalyticsLanguageButtons">
          <KSTKAnalyticsButton
            action="kstkSmallTertiaryButton"
            type="button"
            text="PT"
            clickFunction={this.props.changeLanguage}
            clickFunctionArgs="pt-PT"
          />
          <KSTKAnalyticsButton
            action="kstkSmallTertiaryButton"
            type="button"
            text="EN"
            clickFunction={this.props.changeLanguage}
            clickFunctionArgs="en-GB"
          />
        </div>
        <div className="kstkLogoFooter"></div>
      </div>
    );
  }
}
