var app = angular.module("analytics");

app.controller("ProductsCtrl", [
  "$scope",
  "$mdPanel",
  "productFactory",
  "exportUiGridService",
  "$mdDialog",
  function ($scope, $mdPanel, productFactory, exportUiGridService, $mdDialog) {
    var self = this;
    var mode = "create";

    $scope.$parent.setTitle("Produtos");

    var columns = [
      {
        field: "product",
        displayName: "Produto",
        sort: {
          priority: 0,
          direction: "asc",
        },
      },
    ];

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: "Export all data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel(
              "sheet 1",
              $scope.gridApi,
              "all",
              "all"
            );
          },
          order: 110,
        },
        {
          title: "Export visible data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel(
              "sheet 1",
              $scope.gridApi,
              "visible",
              "visible"
            );
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    productFactory.setParentScope($scope);

    $scope.clearForm = function () {
      // productFactory.setSelected({});
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      productFactory.setSelected(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          productFactory.setSelected(row.entity);
        } else {
          $scope.selectedRow = null;
          productFactory.setSelected(null);
        }
      });
    };

    function getProducts() {
      productFactory.getAll().then(function (data) {
        $scope.gridOptions.data = data;
      });
    }

    getProducts();

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "ProductsPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/productsPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getProducts();
        },
        locals: {
          categoryScope: $scope,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
      getProducts();
    };

    $scope.create = function (event) {
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      mode = "modify";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      var confirm = $mdDialog
        .confirm()
        .title("Eliminar este registo?")
        .ariaLabel("Confirmar eliminação")
        .ok("Sim")
        .cancel("Não");

      $mdDialog.show(confirm).then(
        function () {
          $scope.$parent.showLoader();
          clientsFactory.delete($scope.selectedRow.user).then(function () {
            productFactory.delete($scope.selectedRow.id).then(function () {
              $scope.$parent.hideLoader();
              getProducts();
            });
          });
        },
        function () {
          return;
        }
      );
    };
  },
]);
