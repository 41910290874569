"use strict";

var app = angular.module("analytics");

app.controller("CreateVirtualClassPanelCtrl", [
  "$scope",
  "$mdDialog",
  "genericScope",
  "genericFactory",
  function ($scope, $mdDialog, genericScope, genericFactory) {
    var parentScope = genericFactory.getParentScope();
    $scope.genericScope = genericScope;
    $scope.createConfig = {};
    $scope.searchTerm = {};
    $scope.originalClasses = [];
    $scope.virtualClasses = [];
    $scope.students = [];
    let classes = $scope.genericScope.getFromTableData("Class_Plan_Classes");
    let currentUserClasses = $scope.genericScope.currentUser.class;
    let classCache = {};

    $scope.clearSearchTerm = function (field) {
      $scope.searchTerm[field] = "";
    };

    let originalClassesSourceArray = [];

    // Get original classes from currentUser
    if (currentUserClasses != null && Array.isArray(currentUserClasses) && currentUserClasses.length > 0) {
      originalClassesSourceArray = JSON.parse(JSON.stringify(currentUserClasses));
      let classesArray = [];

      originalClassesSourceArray.forEach((cls) => {
        classesArray.push({ class: cls });
      });
      originalClassesSourceArray = classesArray;
    }
    // Get original classes from grid data
    else if (genericScope.gridOptions.data != null && genericScope.gridOptions.data.length > 0) {
      originalClassesSourceArray = JSON.parse(JSON.stringify(genericScope.gridOptions.data));
    }

    originalClassesSourceArray.forEach((el) => {
      let filteredClass =
        classCache[el.class] != null
          ? classCache[el.class]
          : classes.filter(
              (cls) =>
                cls.year == getCurrentScholarYear() &&
                cls.id == el.class &&
                (cls.virtualClass == null || cls.virtualClass == "Não")
            );
      if (filteredClass != null && filteredClass.length > 0) {
        filteredClass = filteredClass[0];
        if (classCache[el.class] == null) {
          classCache[el.class] = filteredClass;
        }
        $scope.originalClasses.push({
          year: filteredClass.year,
          cluster: filteredClass.cluster,
          school: filteredClass.school,
          class: filteredClass.id,
          parsedClass: filteredClass.class,
        });
      }
    });

    classes.forEach((el) => {
      if (el.virtualClass != null && el.virtualClass == "Sim" && el.year == getCurrentScholarYear()) {
        if (
          currentUserClasses == null ||
          currentUserClasses == "" ||
          (Array.isArray(currentUserClasses) && currentUserClasses.length == 0) ||
          (Array.isArray(currentUserClasses) && currentUserClasses.indexOf(el.id) != -1)
        ) {
          $scope.virtualClasses.push({
            year: el.year,
            cluster: el.cluster,
            school: el.school,
            class: el.id,
            parsedClass: el.class,
          });
        }
      }
    });

    if ($scope.virtualClasses.length > 0) {
      sortArrayByParsedClass($scope.virtualClasses);
    }

    function sortArrayByParsedClass(array) {
      array.sort(function (a, b) {
        if (
          a.parsedClass.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
          b.parsedClass.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        if (
          a.parsedClass.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          b.parsedClass.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }

        return 0;
      });
    }

    $scope.$watch(
      "[createConfig]",
      function (createConfig) {
        if (
          $scope.createConfig.originalClasses &&
          $scope.createConfig.originalClasses.length > 0 &&
          $scope.createConfig["studentsFlagCheckbox"] &&
          $scope.createConfig["studentsFlagCheckbox"][""] == true
        ) {
          $scope.students = $scope.genericScope.gridOptions.data.filter(
            (stud) => $scope.createConfig.originalClasses.indexOf(stud.class) != -1
          );
        }
      },
      true
    );

    $scope.createVirtualClass = function () {
      $scope.genericScope.parentScope.$parent.showLoader();
      let studentsToInsertInVirtualClass = [];
      let virtualClassInfo = $scope.virtualClasses.filter((cls) => cls.class == $scope.createConfig.virtualClass)[0];
      if ($scope.createConfig.students != null && $scope.createConfig.students.length > 0) {
        let selectedStudentsInfo = $scope.genericScope.gridOptions.data.filter(
          (stud) => $scope.createConfig.students.indexOf(stud.id) != -1
        );
        selectedStudentsInfo.forEach((stud) => {
          if ($scope.createConfig.originalClasses.indexOf(stud.class) != -1 && stud.nif != null && stud.nif != "") {
            let newStud = JSON.parse(JSON.stringify(stud));
            delete newStud.id;
            newStud.cluster = virtualClassInfo.cluster;
            newStud.school = virtualClassInfo.school;
            newStud.originalClass = JSON.parse(JSON.stringify(newStud.class));
            newStud.class = virtualClassInfo.class;
            studentsToInsertInVirtualClass.push(newStud);
          }
        });
      } else {
        let studentsToInsert = $scope.genericScope.gridOptions.data.filter(
          (stud) => $scope.createConfig.originalClasses.indexOf(stud.class) != -1
        );
        studentsToInsert.forEach((stud) => {
          if ($scope.createConfig.originalClasses.indexOf(stud.class) != -1 && stud.nif != null && stud.nif != "") {
            let newStud = JSON.parse(JSON.stringify(stud));
            delete newStud.id;
            newStud.cluster = virtualClassInfo.cluster;
            newStud.school = virtualClassInfo.school;
            newStud.originalClass = JSON.parse(JSON.stringify(newStud.class));
            newStud.class = virtualClassInfo.class;
            studentsToInsertInVirtualClass.push(newStud);
          }
        });
      }
      // Remove duplicate students to insert by nif
      if (studentsToInsertInVirtualClass.length > 0) {
        removeMatchingDuplicateElements(
          $scope.genericScope.gridOptions.data,
          studentsToInsertInVirtualClass,
          ["year", "nif", "class"],
          ["year", "nif", "class"],
          [null, null, null]
        );
        if (studentsToInsertInVirtualClass.length == 0) {
          $scope.genericScope.parentScope.alertStudentsAlreadyInVirtualClass();
          genericScope.closePanel();
          $scope.genericScope.parentScope.$parent.hideLoader();
        } else if (studentsToInsertInVirtualClass.length > 0) {
          // Get last virtual class class number
          let virtualClassOrderNumber = 0;
          let filteredExistingStudsInVirtualClass = $scope.genericScope.gridOptions.data.filter(
            (stud) => $scope.createConfig.virtualClass == stud.class
          );
          if (filteredExistingStudsInVirtualClass.length > 0) {
            filteredExistingStudsInVirtualClass.forEach((stud) => {
              if (stud.classOrder != null && stud.classOrder > virtualClassOrderNumber) {
                virtualClassOrderNumber = stud.classOrder;
              }
            });
          }
          // Order students
          studentsToInsertInVirtualClass.sort(function (a, b) {
            if (
              a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
              b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) {
              return -1;
            }

            if (
              a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
              b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) {
              return 1;
            }
          });
          for (let n = 0; n < studentsToInsertInVirtualClass.length; n++) {
            let stud = studentsToInsertInVirtualClass[n];
            virtualClassOrderNumber += 1;
            stud.classOrder = virtualClassOrderNumber;
          }
          let numInsertedStuds = 0;
          studentsToInsertInVirtualClass.forEach((stud) => {
            genericFactory.create(stud).then(() => {
              numInsertedStuds += 1;
              if (numInsertedStuds == studentsToInsertInVirtualClass.length) {
                $scope.genericScope.parentScope.alertStudentsInsertedInVirtualClass(numInsertedStuds);
                genericScope.closePanel();
                $scope.genericScope.parentScope.$parent.hideLoader();
              }
            });
          });
        } else {
          $scope.genericScope.parentScope.alertNoValidStudentsToInsertInVirtualClass();
          genericScope.closePanel();
          $scope.genericScope.parentScope.$parent.hideLoader();
        }
      } else {
        $scope.genericScope.parentScope.alertNoValidStudentsToInsertInVirtualClass();
        genericScope.closePanel();
        $scope.genericScope.parentScope.$parent.hideLoader();
      }
    };
  },
]);
