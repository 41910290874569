import React, { useState } from "react";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";
import KSTKAnalyticsPopUp from "./KSTKAnalyticsPopUp";

const KSTKAnalyticsCallToActionCard = ({
  image,
  topic,
  description,
  callToActionButton,
  callToActionText,
  callToActionVideoLink,
}) => {
  const [openPopUps, setOpenPopUps] = useState([]);
  const openClosePopUps = (e, id) => {
    e.stopPropagation();
    const newPopUpsArray = [...openPopUps];
    if (openPopUps.indexOf(id) == -1) {
      newPopUpsArray.push(id);
    } else {
      newPopUpsArray.splice(newPopUpsArray.indexOf(id), 1);
    }
    setOpenPopUps(newPopUpsArray);
  };

  return (
    <React.Fragment>
      <div className="kstkCallToActionCardWrapper">
        <div className="kstkCallToActionCardImageWrapper">
          <img className="kstkCallToActionCardImage" src={"../../images/" + image + ".svg"}></img>
        </div>
        <div className="kstkCallToActionCardDescription">
          <div className="kstkCallToActionCardDescriptionTitle">{topic}</div>
          <div className="kstkCallToActionCardDescriptionText">{description}</div>
          <div className="spacer"></div>
          <div className="kstkCallToActionCardDescriptionButton">
            {callToActionButton == null || callToActionButton == true ? (
              <KSTKAnalyticsButton
                action="kstkSmallPrimaryButton"
                text={callToActionText}
                clickFunction={openClosePopUps}
                icon="arrow-forward"
                targetElement={topic + "video"}
              />
            ) : null}
          </div>
          {openPopUps && openPopUps.indexOf(topic + "video") != -1 ? (
            <KSTKAnalyticsPopUp
              id={topic + "video"}
              togglePopUp={openClosePopUps}
              popUpInfo={{ guideVideo: callToActionVideoLink }}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default KSTKAnalyticsCallToActionCard;
