var app = angular.module("analytics");

app.controller("ModulesCtrl", [
  "$scope",
  "$mdPanel",
  "clientsFactory",
  "productFactory",
  "categoryFactory",
  "organizationFactory",
  "moduleFactory",
  "exportUiGridService",
  "$mdDialog",
  "$state",
  function (
    $scope,
    $mdPanel,
    clientsFactory,
    productFactory,
    categoryFactory,
    organizationFactory,
    moduleFactory,
    exportUiGridService,
    $mdDialog,
    $state
  ) {
    var self = this;
    var mode = "create";

    $scope.$parent.setTitle("Módulos");

    $scope.isAdmin = clientsFactory.isAdmin();
    $scope.isOrgAdmin = clientsFactory.isOrgAdmin();
    $scope.isCPAdmin = clientsFactory.isCPAdmin();

    if (!$scope.isAdmin && !$scope.isOrgAdmin && !$scope.isCPAdmin) {
      $state.go("home");
    } else {
      $scope.organizationData = organizationFactory.data;
      $scope.categoryData = categoryFactory.data;

      productFactory.getAll().then(function (products) {
        $scope.productData = products;
      });
      getModules();
    }

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.getProName = function (proId) {
      var filteredPro = $scope.productData.filter((e) => e.id === proId);

      if (filteredPro.length) {
        return filteredPro[0].product;
      } else {
        return "";
      }
    };

    $scope.getCatName = function (catId) {
      var filteredCat = $scope.categoryData.filter((e) => e.id === catId);

      if (filteredCat.length) {
        return filteredCat[0].name;
      } else {
        return "";
      }
    };

    var columns = [
      {
        field: "proName",
        displayName: "Produto",
      },
      {
        field: "catName",
        displayName: "Categoria",
      },
      {
        field: "subCatName",
        displayName: "Subcategoria",
      },
      {
        field: "name",
        displayName: "Nome",
        sort: {
          priority: 0,
          direction: "asc",
        },
      },
      {
        field: "order",
        displayName: "Ordem",
      },
      {
        field: "value",
        displayName: "Valor Ref",
      },
      {
        field: "collection",
        displayName: "Colecção",
      },
      {
        field: "dest",
        displayName: "Destino",
      },
      {
        field: "disabled",
        displayName: "Desactivado",
      },
      {
        field: "description",
        displayName: "Descrição",
      },
    ];

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: "Export all data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "all", "all");
          },
          order: 110,
        },
        {
          title: "Export visible data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "visible", "visible");
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    moduleFactory.setParentScope($scope);

    $scope.clearForm = function () {
      // moduleFactory.setSelected({});
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      moduleFactory.setSelected(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          moduleFactory.setSelected(row.entity);
        } else {
          $scope.selectedRow = null;
          moduleFactory.setSelected(null);
        }
      });
    };

    function getModules() {
      moduleFactory.getAll().then(function (data) {
        data.forEach(function (mod) {
          if (mod.product && Array.isArray(mod.product)) {
            mod.proName = [];
            mod.product.forEach((pro) => {
              if ($scope.getProName(pro) != null && $scope.getProName(pro) != "") {
                mod.proName.push($scope.getProName(pro));
              }
            });
            mod.proName = mod.proName.toString();
          }
          mod.catName = $scope.getCatName(mod.category);
          mod.subCatName = $scope.getCatName(mod.subCategory);
        });
        $scope.gridOptions.data = data;
      });
    }

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "ModulesPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/modulesPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getModules();
        },
        locals: {
          categoryScope: $scope,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
      getModules();
    };

    $scope.create = function (event) {
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      mode = "modify";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      var confirm = $mdDialog.confirm().title("Eliminar este registo?").ariaLabel("Confirmar eliminação").ok("Sim").cancel("Não");

      $mdDialog.show(confirm).then(
        function () {
          $scope.$parent.showLoader();
          clientsFactory.delete($scope.selectedRow.user).then(function () {
            moduleFactory.delete($scope.selectedRow.id).then(function () {
              $scope.$parent.hideLoader();
              getModules();
            });
          });
        },
        function () {
          return;
        }
      );
    };
  },
]);
