var app = angular.module("analytics");

app.controller("RecoverPassCtrl", [
  "$scope",
  "$http",
  "clientsFactory",
  "$state",
  function ($scope, $http, clientsFactory, $state) {
    if (clientsFactory.isAuthenticated()) {
      $state.go("home");
    }

    $scope.$parent.setTitle("Password Recovery");

    $scope.email = "";
    $scope.captcha = null;

    $scope.resetPassword = function () {
      // var xhr = new XMLHttpRequest();

      // xhr.open('POST', 'https://www.google.com/recaptcha/api/siteverify', true);
      // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      // xhr.onload = function () {
      //     // do something to response
      //     console.log(this.responseText);
      // };
      // xhr.send(JSON.stringify($scope.captcha));

      clientsFactory
        .validate($scope.captcha)
        .then(function (data) {
          if (data.data.success) {
            clientsFactory
              .reset($scope.email)
              .then(() => {
                alert("Foi-lhe enviado um email com a sua nova password");
                $state.go("login");
              })
              .catch(() =>
                alert("Ocorreu um erro ao tentar recuperar a sua password")
              );
          } else {
            alert("Erro na validação do Captcha");
          }
        })
        .catch(function () {
          alert("Erro na validação do Captcha");
        });
    };
  },
]);
