export function changeInterfaceByProduct(product) {
  var style = document.createElement("style");
  style.innerHTML = `
            .menuLogo {
              background-image: url(../../images/logo_green_education.svg);
            }
            .sidenavLogo {
              background-image: url(../../images/logo_white_education.svg);
            }
            .menuSearchBox, .menuDropdownMenu {
              box-shadow: 0px 3px 10px #0f6fff;
            }
            .primaryButton:not([disabled]) {
              background-color: #0f6fff !important;
              color: white !important;
            }
            .secondaryButton:not([disabled]) {
              background-color: white !important;
              border: 1.5px solid #0f6fff;
              color: #0f6fff !important;
              box-shadow: none !important;
            }
            .secondaryButton:not([disabled]) ng-md-icon {
              fill: #0f6fff !important;
            }
            .tertiaryButton:not([disabled]) {
              color: #0f6fff !important;
              box-shadow: none !important;
            }
            .tertiaryButton:not([disabled]) ng-md-icon {
              fill: #0f6fff !important;
            }
            .md-on {
                background-color: #0f6fff !important;
            }
            `;
  document.head.appendChild(style);
}
