import React, { useEffect, useState, useRef } from "react";
import "../../stylesheets/react.scss";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";

const KSTKAnalyticsHeader = ({
  breadCrumb,
  user,
  settings,
  changePage,
  logout,
  currentModuleReport,
  getFavoriteStatus,
  addRemoveFavorites,
  openCloseSmartContents,
  clientsFactory,
  smartContents,
  smartContentsMode,
  translateText,
}) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const [favorite, setFavorite] = useState(getFavoriteStatus(currentModuleReport));

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const settingsMenuClick = (menuEntry) => {
    setOpen(false);
    changePage(menuEntry.destination, menuEntry.args);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleAddRemoveFavorites = (e, menuEntry) => {
    e.stopPropagation();
    setFavorite(!favorite);
    addRemoveFavorites(menuEntry);
  };

  const handleOpenSmartContents = (e, targetElement, mode) => {
    openCloseSmartContents(mode);
  };

  return (
    <div className="kstkHeader">
      <div className="kstkHeaderBreadcrumb">
        {breadCrumb ? breadCrumb : null}
        {currentModuleReport ? (
          <React.Fragment>
            {getFavoriteStatus(currentModuleReport) ? (
              <div
                className="kstkHeaderBreadcrumbFavorite"
                onClick={(e) => handleAddRemoveFavorites(e, currentModuleReport)}
              >
                <ion-icon name="heart"></ion-icon>
              </div>
            ) : (
              <div
                className="kstkHeaderBreadcrumbNotFavorite"
                onClick={(e) => handleAddRemoveFavorites(e, currentModuleReport)}
              >
                <ion-icon name="heart-outline"></ion-icon>
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>

      <div className="spacer"></div>
      <div className="kstkHeaderSmartContentsControllers">
        {smartContents && smartContents.filter((smartContent) => smartContent.type == "Guia").length > 0 ? (
          <KSTKAnalyticsButton
            title="Guias de Apoio"
            action="kstkSmallTertiaryButton"
            icon={smartContentsMode == "guides" ? "help-circle" : "help-circle-outline"}
            clickFunction={handleOpenSmartContents}
            clickFunctionArgs="guides"
          />
        ) : null}
        {smartContents && smartContents.filter((smartContent) => smartContent.type == "Notificação").length > 0 ? (
          <KSTKAnalyticsButton
            title="Notificações"
            action="kstkSmallTertiaryButton"
            icon={smartContentsMode == "notifications" ? "notifications" : "notifications-outline"}
            clickFunction={handleOpenSmartContents}
            clickFunctionArgs="notifications"
          />
        ) : null}
      </div>
      {user && <div className="username">{user.name + " " + user.surname}</div>}
      <div className="kstkHeaderSettingsMenuIcon" onClick={(e) => setOpen(!open)}>
        {!open ? <ion-icon name="chevron-down"></ion-icon> : <ion-icon name="chevron-up"></ion-icon>}
      </div>
      {open && (
        <div className="kstkAnalyticsSettingsMenu" ref={node}>
          {Object.values(settings)
            .filter(
              (menuEntry) =>
                menuEntry.destination &&
                ((menuEntry.admin && clientsFactory.isAdmin()) ||
                  (menuEntry.cpAdmin && clientsFactory.isCPAdmin()) ||
                  (menuEntry.vfxEduAdmin && clientsFactory.isVFXEduAdmin()) ||
                  (menuEntry.orgAdmin && clientsFactory.isOrgAdmin()) ||
                  !menuEntry.admin)
            )
            .map((menuEntry) => (
              <div className="kstkAnalyticsSettingsMenuItem" onClick={() => settingsMenuClick(menuEntry)}>
                <div className="kstkAnalyticsSettingsMenuItemIcon">
                  {menuEntry.name == "Permissões" ? (
                    <ion-icon name="people"></ion-icon>
                  ) : (
                    <ion-icon name="settings"></ion-icon>
                  )}
                </div>
                <div>{menuEntry.name}</div>
              </div>
            ))}
          <div className="kstkAnalyticsSettingsMenuItem" onClick={() => logout()}>
            <div className="kstkAnalyticsSettingsMenuItemIcon">
              <ion-icon name="log-out"></ion-icon>
            </div>
            <div>{translateText("logout")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KSTKAnalyticsHeader;
