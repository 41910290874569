import { changeInterfaceByProduct } from "./productInterfaceStylizer";

var app = angular.module("analytics");

app.controller("HomeCtrl", [
  "$scope",
  "categoryFactory",
  "organizationFactory",
  "reportFactory",
  "clientsFactory",
  "clientPreferencesFactory",
  "permissionsFactory",
  "moduleFactory",
  "$state",
  "$stateParams",
  "$mdDialog",
  "localizationFactory",
  "$timeout",
  function (
    $scope,
    categoryFactory,
    organizationFactory,
    reportFactory,
    clientsFactory,
    clientPreferencesFactory,
    permissionsFactory,
    moduleFactory,
    $state,
    $stateParams,
    $mdDialog,
    localizationFactory,
    $timeout
  ) {
    var self = this;

    $scope.$parent.setTitle("");

    // Translate text

    $scope.translateText = function (text) {
      return localizationFactory.translateText(text);
    };

    self.gotCategoryData = false;
    self.gotOrganizationData = false;

    $scope.reportSrc = "";
    $scope.report = reportFactory;
    $scope.reportData;
    $scope.showReport = false;
    $scope.hasOrgMenu = false;
    $scope.menuDataLoaded = false;
    $scope.dashboardWidth = "5000px";
    $scope.menuData = $scope.$parent.menuData;

    $scope.menuSearchBoxSearchTerm = "";
    $scope.reportsSearchTerm = "";
    $scope.opModulesSearchTerm = "";
    $scope.auxModulesSearchTerm = "";
    $scope.reportsSearchTerm = "";
    $scope.docModulesSearchTerm = "";

    $scope.reports = [];
    $scope.opModules = [];
    $scope.auxModules = [];
    $scope.docModules = [];

    // Maintenance warning
    //$scope.$parent.alertMaintenance();

    $scope.refreshHomepage = function () {
      $scope.refreshHp = true;
      $timeout(function () {
        $scope.refreshHp = false;
      }, 0);
    };

    $scope.currentUser = $scope.client.getUser().user;

    // Send as props to the React Homepage
    $scope.user = $scope.$parent.user;
    if ($scope.user == null) {
      window.location = "/#!/login";
      window.location.reload();
    }
    clientPreferencesFactory.getByProperty("user", $scope.user.id).then(function (preferencesData) {
      if (preferencesData.length > 0) {
        $scope.$parent.clientPreferences = preferencesData[0];
        $scope.userPreferences = $scope.$parent.clientPreferences;
      }
    });

    $scope.closeSystemAlert = function (alert) {
      if ($scope.userPreferences.viewedSystemAlerts == null) {
        $scope.userPreferences.viewedSystemAlerts = [];
      }
      $scope.userPreferences.viewedSystemAlerts.push(alert);

      clientPreferencesFactory.modify($scope.userPreferences.id, $scope.userPreferences).then(function () {
        $scope.refreshHomepage();
      });
    };

    $scope.moduleReportClick = $scope.$parent.moduleReportClick;
    $scope.getFavoriteStatus = $scope.$parent.getFavoriteStatus;
    $scope.addRemoveFavorites = $scope.$parent.addRemoveFavorites;

    $scope.$parent.atHomepage = true;
    $scope.$parent.refreshSmartContents();

    $scope.exploreClick = function () {
      if ($scope.menuList != null) {
        let max = $scope.menuList.length - 1 + 0.4;
        let menuListRandomItemIndex = Math.floor(Math.random() * max);
        let menuListRandomItem = $scope.menuList[menuListRandomItemIndex];
        $scope.$parent.catModuleReportClick(menuListRandomItem.elementId, "moduleReportClick", menuListRandomItem);
      }
    };

    if ($scope.currentUser && $scope.currentUser.orgType) {
      changeInterfaceByProduct($scope.currentUser.orgType);
    }

    $scope.clearSearchTerm = function (searchTerm) {
      $scope[searchTerm] = "";
    };

    if (!$scope.client.getUser().user) {
      var lsAuth = getAuthentication();
      // var lsAuth = getAuthentication();
      if (lsAuth != null) {
        //

        if (new Date(lsAuth.created).getTime() + lsAuth.ttl > new Date().getTime()) {
          $scope.client.setUser(lsAuth);
          if (http) {
            http.defaults.headers.common["Authorization"] = lsAuth.id;
          }
        }
      }
    }
    // if ($scope.client.getUser().id) {
    //   if (http) {
    //     http.defaults.headers.common['Authorization'] = lsAuth.value.id;
    //   }
    // }

    $scope.showPolicyDialog = function () {
      $mdDialog
        .show({
          controller: "PolicyDialogCtrl",
          templateUrl: "templates/policyDialog.html",
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: false,
        })
        .then(
          function (answer) {
            //Yes
          },
          function () {
            //No
          }
        );
    };

    if (
      $scope.currentUser != null &&
      !$scope.currentUser.privPolicyTermsUseFlag &&
      !$scope.currentUser.isKSTK &&
      !clientsFactory.isAdmin()
    ) {
      $scope.showPolicyDialog();
    } else {
      if ($scope.currentUser && $scope.currentUser.hasToResetPassword) {
        $scope.$parent.atHomepage = false;
        $state.go("client");
      }
    }

    $scope.organizationData = null;
    if (clientsFactory.getUser().user) {
      self.orgId = clientsFactory.getUser().user.organization;
    }

    /* categoryFactory.getAll().then(function (categoryData) {
      $scope.categoryData = categoryData;
      self.gotCategoryData = true;

      $scope.getAdminMenuData($scope);
    });

    organizationFactory.getAll().then(function (organizationData) {
      $scope.organizationData = organizationData;
      self.gotOrganizationData = true;

      $scope.getAdminMenuData($scope);

      var clientOrg = $scope.client.getUser().user.organization;
      organizationData.forEach(org => {
        if (org.id === clientOrg) {
          $scope.clientLogo = org.logo;
        }
      });
    }); */

    // reportFactory.getAll();

    categoryFactory.getAll().then(function (categoryData) {
      $scope.categoryData = categoryData;
      self.gotCategoryData = true;

      if ($scope.client.isAdmin()) {
        $scope.getAdminMenuData($scope);
      } else {
        $scope.getMainMenuData($scope);
      }
    });

    organizationFactory.getAll().then(function (organizationData) {
      $scope.organizationData = organizationData;
      self.gotOrganizationData = true;

      if ($scope.client.isAdmin()) {
        $scope.getAdminMenuData($scope);
      } else {
        $scope.getMainMenuData($scope);
      }

      var clientOrg = $scope.client.getUser().user.organization;
      organizationData.forEach((org) => {
        if (org.id === clientOrg) {
          $scope.clientLogo = org.logo;
          $scope.clientOrgName = org.name;
          $scope.clientOrgType = org.orgType;
          if ($scope.currentUser.orgType == null && org.orgType != null && !$scope.client.isAdmin()) {
            changeInterfaceByProduct(org.orgType);
            $scope.currentUser.orgType = org.orgType;
            clientsFactory.updateUsr($scope.currentUser);
            clientsFactory.modify($scope.currentUser.id, $scope.currentUser).then(function () {});
          }
          $scope.clientHasSponsorsLogo = org.hasSponsorsLogo;
          $scope.clientHasOrganizationLogo = org.hasOrganizationLogo;
        }
      });
    });

    reportFactory.getAll();

    $scope.getAdminMenuData = function ($scope) {
      if (self.gotCategoryData && self.gotOrganizationData) {
        if ($scope.client.isAdmin()) {
          var menu = {};
          var menuList = [];

          reportFactory.getAll().then(function (reportData) {
            $scope.reportData = reportData;

            $scope.categoryData
              .sort(function compareCategories(a, b) {
                if (a.order < b.order) {
                  return -1;
                }

                if (a.order > b.order) {
                  return 1;
                }

                return 0;
              })
              .forEach((cat) => {
                menu[cat.id] = cat;
                menu[cat.id].reports = [];

                // menuList.push({
                //     name: cat.name,
                //     isCat: null
                // });

                var tempRepMenu = [];

                $scope.reportData
                  .sort(function compareReports(a, b) {
                    if (a.order < b.order) {
                      return -1;
                    }

                    if (a.order > b.order) {
                      return 1;
                    }

                    return 0;
                  })
                  .forEach((rep) => {
                    if (rep.category === cat.id) {
                      var tempRep = rep;
                      if ($scope.organizationData.filter((o) => o.id === rep.organization).length) {
                        tempRep.name =
                          $scope.organizationData.filter((o) => o.id === rep.organization)[0].name +
                          ": " +
                          tempRep.name;

                        tempRep.href = "#/report";

                        menu[cat.id].reports.push(tempRep);

                        tempRepMenu.push(tempRep);
                      }
                    }
                  });

                if (tempRepMenu.length) {
                  menuList.push({
                    name: cat.name,
                    isCat: null,
                  });

                  menuList = menuList.concat(tempRepMenu);
                }
              });

            var tempMenu = {};
            var tempMenuList = [];

            for (var entry in menu) {
              if (menu[entry].reports.length > 0) {
                tempMenu[entry] = menu[entry];
              }
            }

            $scope.menuData = tempMenu;
            $scope.dashboardWidth = 300 * Object.keys($scope.menuData).length + 20 + "px";
          });
        } else {
          var org = $scope.client.getUser().user.organization;

          reportFactory.getByOrganizationId(org).then(function (reportData) {
            $scope.reportData = reportData;
            var menu = {};
            var menuList = [];

            permissionsFactory.getByProperty("user", $scope.client.getUser().user.id).then(function (permissionData) {
              if (permissionData.length) {
                var permReports = permissionData[0].reports;

                $scope.categoryData
                  .sort(function compareCategories(a, b) {
                    if (a.order < b.order) {
                      return -1;
                    }

                    if (a.order > b.order) {
                      return 1;
                    }

                    return 0;
                  })
                  .forEach((cat) => {
                    menu[cat.id] = cat;
                    menu[cat.id].reports = [];

                    // menuList.push({
                    //     name: cat.name,
                    //     isCat: null
                    // });

                    var tempRepMenu = [];

                    $scope.reportData
                      .sort(function compareReports(a, b) {
                        if (a.order < b.order) {
                          return -1;
                        }

                        if (a.order > b.order) {
                          return 1;
                        }

                        return 0;
                      })
                      .forEach((rep) => {
                        if (rep.organization === org && rep.category === cat.id && permReports.indexOf(rep.id) > -1) {
                          var tempRep = rep;

                          tempRep.href = "#/report";

                          menu[cat.id].reports.push(tempRep);

                          tempRepMenu.push(tempRep);
                        }
                      });

                    if (tempRepMenu.length) {
                      menuList.push({
                        name: cat.name,
                        isCat: null,
                      });

                      menuList = menuList.concat(tempRepMenu);
                    }
                  });

                var tempMenu = {};
                var tempMenuList = [];

                for (var entry in menu) {
                  if (menu[entry].reports.length > 0) {
                    tempMenu[entry] = menu[entry];
                  }
                }

                $scope.menuData = tempMenu;
                $scope.dashboardWidth = 300 * Object.keys($scope.menuData).length + 20 + "px";
              }
            });
          });
        }
      }
    };

    $scope.getMainMenuData = function ($scope) {
      if (self.gotCategoryData && self.gotOrganizationData) {
        var org = $scope.client.getUser().user.organization;

        reportFactory.getByOrganizationId(org).then(function (reportData) {
          $scope.reportData = reportData;
          moduleFactory.getAll().then(function (modulesData) {
            $scope.modulesData = modulesData;

            let menu = {};
            let menuList = [];

            permissionsFactory.getByProperty("user", $scope.client.getUser().user.id).then(function (permissionData) {
              if (permissionData.length) {
                let permReports = permissionData[0].reports;
                let permModules = permissionData[0].modules;

                for (let j = 0; j < permModules.length; j++) {
                  let permMod = permModules[j];
                  $scope.modulesData.forEach((mod) => {
                    if (mod.value == permMod) {
                      $scope.userModules.push(mod);
                    }
                  });
                }

                $scope.categoryData
                  .sort(function compareCategories(a, b) {
                    if (a.order < b.order) {
                      return -1;
                    }

                    if (a.order > b.order) {
                      return 1;
                    }

                    return 0;
                  })
                  .forEach((cat) => {
                    menu[cat.id] = cat;
                    menu[cat.id].reports = [];

                    // menuList.push({
                    //     name: cat.name,
                    //     isCat: null
                    // });

                    let tempRepMenu = [];

                    $scope.reportData
                      .sort(function compareReports(a, b) {
                        if (a.order < b.order) {
                          return -1;
                        }

                        if (a.order > b.order) {
                          return 1;
                        }

                        return 0;
                      })
                      .forEach((rep) => {
                        if (rep.organization === org && rep.category === cat.id && permReports.indexOf(rep.id) > -1) {
                          let tempRep = rep;

                          tempRep.href = "#/report";
                          tempRep.catName = cat.name;
                          tempRep.elementId = cat.id + "catRep" + tempRep.id;

                          menu[cat.id].reports.push(tempRep);

                          tempRepMenu.push(tempRep);
                        }
                      });

                    if (tempRepMenu.length) {
                      /* menuList.push({
                          name: cat.name,
                          isCat: null
                        }); */

                      $scope.reports = $scope.reports.concat(tempRepMenu);
                      menuList = menuList.concat(tempRepMenu);
                    }
                  });

                $scope.categoryData
                  .sort(function compareCategories(a, b) {
                    if (a.order < b.order) {
                      return -1;
                    }

                    if (a.order > b.order) {
                      return 1;
                    }

                    return 0;
                  })
                  .forEach((cat) => {
                    menu[cat.id] = cat;
                    menu[cat.id].modules = [];

                    // menuList.push({
                    //     name: cat.name,
                    //     isCat: null
                    // });

                    let tempModMenu = [];

                    $scope.modulesData
                      .sort(function compareModules(a, b) {
                        if (
                          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        ) {
                          return -1;
                        }

                        if (
                          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        ) {
                          return 1;
                        }

                        return 0;
                      })
                      .forEach((mod) => {
                        if (mod.category === cat.id && permModules.indexOf(mod.value) > -1) {
                          let tempMod = mod;

                          tempMod.href = "/#/" + mod.destination;
                          tempMod.catName = cat.name;
                          tempMod.elementId = cat.id + "catMod" + tempMod.id;

                          menu[cat.id].modules.push(tempMod);

                          tempModMenu.push(tempMod);
                        }
                      });

                    if (tempModMenu.length) {
                      /* menuList.push({
                          name: cat.name,
                          isCat: null
                        }); */

                      tempModMenu.forEach((mod) => {
                        if (cat.name == "Módulos Operacionais") {
                          $scope.opModules.push(mod);
                        } else if (cat.name == "Módulos de Apoio") {
                          $scope.auxModules.push(mod);
                        } else if (cat.name == "Documentos") {
                          $scope.docModules.push(mod);
                        }
                      });
                      menuList = menuList.concat(tempModMenu);
                    }
                  });

                var tempMenu = {};
                var tempMenuList = [];

                for (var entry in menu) {
                  if (menu[entry].reports.length > 0) {
                    tempMenu[entry] = menu[entry];
                  }
                  if (menu[entry].modules.length > 0) {
                    tempMenu[entry] = menu[entry];
                  }
                }

                $scope.menuData = tempMenu;
                $scope.menuList = menuList;
                $scope.menuDataLength = Object.keys($scope.menuData).length;
              }
            });
          });
        });
      }
    };

    organizationFactory.getAll().then(function (orgData) {
      $scope.organizationData = orgData;

      for (var i = 0; i < $scope.organizationData.length; i++) {
        if ($scope.organizationData[i].id === self.orgId) {
          /* if ($scope.organizationData[i].orgType != "Education" && $scope.organizationData[i].report && $scope.organizationData[i].report !== "report" && $scope.organizationData[i].report.trim() != "") {
            $scope.showReport = true;
            var node = document.querySelector('#homeReportFrame');
            if (node) {
              node.setAttribute('src', $scope.organizationData[i].report);
            }
          } else if ($scope.organizationData[i].orgType == "Education") {
            $scope.hasOrgMenu = true;
          } */
          $scope.hasOrgMenu = true;
        }
      }
    });

    $scope.mainMenuClick = function (menuEntry) {
      if (menuEntry.tabs != null) {
        if (menuEntry.dest != null) {
          $state.go(menuEntry.dest);
        } else {
          $state.go("genericModule", {
            moduleCollection: menuEntry.collection,
            moduleValue: menuEntry.value,
          });
        }
      } else if (menuEntry.tabs == null) {
        reportFactory.setSelectedReport(menuEntry);
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });

        $state.go("report");
      }
    };
  },
]);
