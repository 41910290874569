import React from "react";

export class KSTKAnalyticsBreadcrumb extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="kstkAnalyticsBreadcrumb">
        <div className="kstkAnalyticsBreadcrumbItem" onClick={() => this.props.changePage("home")}>
          <span className="kstkAnalyticsBreadcrumbItemName">Início</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
    );
  }
}
