var app = angular.module("analytics");

app.controller("LogsCtrl", [
  "$scope",
  "$mdPanel",
  "clientsFactory",
  "logsFactory",
  "organizationFactory",
  "exportUiGridService",
  "$state",
  function ($scope, $mdPanel, clientsFactory, logsFactory, organizationFactory, exportUiGridService, $state) {
    var self = this;
    var mode = "create";

    $scope.$parent.setTitle("Logs");

    $scope.organizationData = organizationFactory.data;

    var columns = [
      {
        field: "id",
        visible: false,
      },
      {
        field: "who",
      },
      {
        field: "orgName",
      },
      {
        field: "type",
      },
      {
        field: "details",
      },
      {
        field: "date",
      },
    ];

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: "Export all data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "all", "all");
          },
          order: 110,
        },
        {
          title: "Export visible data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "visible", "visible");
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    logsFactory.setParentScope($scope);

    $scope.clearForm = function () {
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      logsFactory.setSelected(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          logsFactory.setSelected(row.entity);
        } else {
          $scope.selectedRow = null;
          $scope.clearForm();
        }
      });
    };

    function getLogs() {
      if (!clientsFactory.isAdmin()) {
        $state.go("home");
      } else {
        logsFactory.getAll().then(function (data) {
          $scope.logsData = data;
          $scope.logsData.map((el) => {
            el.orgName = $scope.getOrgName(el.organization);

            return el;
          });

          $scope.gridOptions.data = $scope.logsData;
        });
      }
    }

    getLogs();

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "LogsPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/logsPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getLogs();
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
    };

    $scope.create = function (event) {
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      mode = "modify";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      logsFactory.delete($scope.selectedRow.id);
    };
  },
]);
