import React, { useState } from "react";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";

const KSTKAnalyticsInput = ({id, autocomplete, label, type, name, value, disabled, onInputChange, inputAfterIconFunction, inputAfterIconStyleFunction }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = (e) => {
    setPasswordShown(passwordShown ? false : true);
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="kstkAnalyticsInputWrapper">
        <h4>{label}</h4>
        <div className="kstkAnalyticsInputContainer">
          <input
            className="kstkAnalyticsInput"
            id={id}
            autocomplete={autocomplete}
            type={type != "password" ? type : passwordShown ? "text" : "password"}
            name={name}
            value={value}
            onChange={(e) => onInputChange(e)}
            disabled={disabled}
          ></input>
          <div className="kstkInputAfterIcons">
            {type == "password" ? (
              <div className="kstkInputShowPasswordIcon">
                  <KSTKAnalyticsButton
                    action="kstkSmallIconTertiaryButton"
                    type="button"
                    icon={passwordShown ? "eye-off" : "eye"}
                    clickFunction={togglePasswordVisiblity}
                  />
              </div>
            ) : null}
            {inputAfterIconFunction ? (
              <div className="kstkInputAfterIcon">
                <ion-icon
                  style={inputAfterIconStyleFunction ? inputAfterIconStyleFunction(name) : null}
                  name={inputAfterIconFunction(name)}
                ></ion-icon>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default KSTKAnalyticsInput;