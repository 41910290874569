import React, { useEffect } from "react";

const KSTKAnalyticsPopUp = ({ togglePopUp, popUpInfo, id }) => {
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      togglePopUp(event, id);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);
  return (
    <React.Fragment>
      <div className="kstkAnalyticsPopUpWrapper" id={id}>
        <div className="kstkAnalyticsPopUpContent">
          <div className="kstkAnalyticsPopUpClose">
            <ion-icon onClick={(e) => togglePopUp(e, id)} name="close-outline"></ion-icon>
          </div>
          {popUpInfo && popUpInfo.guideVideo && popUpInfo.guideVideo != "" ? (
            <iframe
              width="600"
              height="350"
              src={popUpInfo.guideVideo + "?rel=0"}
              title="Guia Predictive Analytics"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen;"
              allowfullscreen
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
              color="white"
              modestbranding="1"
              rel="0"
              showinfo="0"
            ></iframe>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default KSTKAnalyticsPopUp;
