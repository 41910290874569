import React from "react";

export class KSTKAnalyticsModuleReportList extends React.Component {
  constructor(props) {
    super(props);
  }
  handleAddRemoveFavorites(e, menuEntry) {
    e.stopPropagation();
    this.forceUpdate();
    this.props.addRemoveFavorites(menuEntry);
  }
  render() {
    return (
      <div class="kstkCategoryViewModuleReportRow">
        {this.props.list &&
          this.props.list.length > 0 &&
          Object.values(this.props.list).map((catModuleReport) => (
            <div
              className="kstkCatModuleReport"
              onClick={() =>
                catModuleReport.src || catModuleReport.reportBuilderSrc
                  ? this.props.catModuleReportClick(
                      catModuleReport.category + "catRep" + catModuleReport.id,
                      "moduleReportClick",
                      catModuleReport
                    )
                  : this.props.catModuleReportClick(
                      catModuleReport.category + "catMod" + catModuleReport.id,
                      "moduleReportClick",
                      catModuleReport
                    )
              }
            >
              <div className="kstkCatModuleReportIcons">
                <div className="kstkCatModuleReportIconsIcon">
                  {catModuleReport.icon ? (
                    <ion-icon name={catModuleReport.icon}></ion-icon>
                  ) : catModuleReport.src ? (
                    <ion-icon name="bar-chart"></ion-icon>
                  ) : (
                    <ion-icon name="grid"></ion-icon>
                  )}
                </div>
                {this.props.getFavoriteStatus && this.props.addRemoveFavorites ? (
                  <React.Fragment>
                    {this.props.getFavoriteStatus(catModuleReport) ? (
                      <div
                        className="kstkCatModuleReportIconsFavorite"
                        onClick={(e) => this.handleAddRemoveFavorites(e, catModuleReport)}
                      >
                        <ion-icon name="heart"></ion-icon>
                      </div>
                    ) : (
                      <div
                        className="kstkCatModuleReportIconsNotFavorite"
                        onClick={(e) => this.handleAddRemoveFavorites(e, catModuleReport)}
                      >
                        <ion-icon name="heart-outline"></ion-icon>
                      </div>
                    )}
                  </React.Fragment>
                ) : null}
              </div>
              <div>{catModuleReport.name}</div>
              {/* {catModuleReport.src ? (
              <div onClick={() => null}>
                <iframe
                  className="kstkCategoryViewReport"
                  id="reportFrame"
                  width="100%"
                  height="100%"
                  src={catModuleReport.src}
                  frameborder="0"
                  allowFullScreen="true"
                ></iframe>
              </div>
            ) : (
              <span>{catModuleReport.moduleDescription}</span>
            )} */}
            </div>
          ))}
      </div>
    );
  }
}
