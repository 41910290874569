'use strict';

var app = angular.module('analytics');

app.controller('StudentListingTranspPanelCtrl', [
  '$scope',
  '$mdDialog',
  'genericScope',
  'genericFactory',
  'transps',
  'splitDataBySelectedTransports',
  function ($scope, $mdDialog, genericScope, genericFactory, transps, splitDataBySelectedTransports) {
    var parentScope = genericFactory.getParentScope();
    $scope.genericScope = genericScope;
    $scope.exportConfig = {};
    $scope.transps = transps;
    $scope.splitDataBySelectedTransports = splitDataBySelectedTransports;

    $scope.exportStudentListingTransp = function() {
      splitDataBySelectedTransports($scope.exportConfig.transp);
      genericScope.closePanel();
    }
  }
]);
