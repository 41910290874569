'use strict';

var app = angular.module('analytics');

app.controller('ModulesPanelCtrl', [
  '$scope',
  'categoryFactory',
  'moduleFactory',
  function ($scope, categoryFactory, moduleFactory) {
    $scope.selected = moduleFactory.getSelected();
    var parentScope = moduleFactory.getParentScope();
    $scope.categoryData = categoryFactory.data;
    $scope.productData = parentScope.productData;
    $scope.searchTerm = "";
    $scope.clearSearchTerm = function () {
      $scope.searchTerm = "";
    };

    $scope.save = function () {
      if ($scope.selected.id) { //modify
        moduleFactory.modify($scope.selected.id, $scope.selected);
      } else { //create
        moduleFactory.create($scope.selected);
      }
      parentScope.closePanel();
    };

  }
]);
