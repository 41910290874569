'use strict';

var app = angular.module('analytics');

app.controller('ProductsPanelCtrl', [
  '$scope',
  'categoryFactory',
  'productFactory',
  function ($scope, categoryFactory, productFactory) {
    $scope.selected = productFactory.getSelected();
    var parentScope = productFactory.getParentScope();
    $scope.categoryData = categoryFactory.data;
    $scope.searchTerm = "";
    $scope.clearSearchTerm = function () {
      $scope.searchTerm = "";
    };

    $scope.save = function () {
      if ($scope.selected.id) { //modify
        productFactory.modify($scope.selected.id, $scope.selected);
      } else { //create
        productFactory.create($scope.selected);
      }
      parentScope.closePanel();
    };

  }
]);
