"use strict";

var app = angular.module("analytics");

app.controller("OrganizationReportBatchAttributionPanelCtrl", [
  "$scope",
  "organizationFactory",
  "storageFactory",
  "reportFactory",
  "clientsFactory",
  "permissionsFactory",
  "$mdDialog",
  function (
    $scope,
    organizationFactory,
    storageFactory,
    reportFactory,
    clientsFactory,
    permissionsFactory,
    $mdDialog
  ) {
    $scope.selected = organizationFactory.getSelected();
    var parentScope = organizationFactory.getParentScope();
    $scope.attribution = {};

    reportFactory
      .getByOrganizationId($scope.selected.id)
      .then(function (reportData) {
        $scope.reports = reportData;
      });

    $scope.reportBatchAttribution = function () {
      if (
        $scope.attribution.profile &&
        $scope.attribution.report &&
        Array.isArray($scope.attribution.profile) &&
        Array.isArray($scope.attribution.report) &&
        $scope.attribution.profile.length > 0 &&
        $scope.attribution.report.length > 0
      ) {
        clientsFactory
          .getByProperty("organization", $scope.selected.id)
          .then((clientsData) => {
            parentScope.$parent.showLoader();
            //Remove clients from organization that dont have the desired profile ;)
            for (let j = 0; j < clientsData.length; ) {
              let client = clientsData[j];
              if (
                client.profile &&
                $scope.attribution.profile.indexOf(client.profile) == -1
              ) {
                clientsData.splice(j, 1);
              } else {
                j++;
              }
            }
            let clientsUpdateTries = 0;
            let clientsPermissionsUpdated = 0;
            clientsData.forEach((client) => {
              permissionsFactory
                .getByProperty("user", client.id)
                .then((userPermission) => {
                  if (
                    userPermission &&
                    Array.isArray(userPermission) &&
                    userPermission.length > 0
                  ) {
                    userPermission = userPermission[0];
                    let userReportsUpdatedFlag = false;
                    $scope.attribution.report.forEach((reportToAdd) => {
                      if (
                        userPermission.reports &&
                        Array.isArray(userPermission.reports)
                      ) {
                        if (userPermission.reports.indexOf(reportToAdd) == -1) {
                          userReportsUpdatedFlag = true;
                          userPermission.reports.push(reportToAdd);
                        }
                      } else {
                        userPermission.reports = [];
                        userPermission.reports.push(reportToAdd);
                      }
                    });
                    if (userReportsUpdatedFlag) {
                      clientsPermissionsUpdated += 1;
                    }
                    permissionsFactory
                      .modify(userPermission.id, userPermission)
                      .then(() => {
                        console.log("User reports updated");
                        clientsUpdateTries += 1;
                        if (clientsUpdateTries == clientsData.length) {
                          $mdDialog.show(
                            $mdDialog
                              .alert()
                              .clickOutsideToClose(true)
                              .title("Atribuição Concluída")
                              .textContent(
                                "Foram atualizados os reports de " +
                                  clientsPermissionsUpdated +
                                  " de " +
                                  clientsUpdateTries +
                                  " utilizadores."
                              )
                              .ariaLabel(
                                "Foram atualizados os reports de " +
                                  clientsPermissionsUpdated +
                                  " de " +
                                  clientsUpdateTries +
                                  " utilizadores."
                              )
                              .ok("Ok")
                          );
                          parentScope.$parent.hideLoader();
                          parentScope.closePanel();
                        }
                      });
                  } else {
                    console.log("User has no permissions");
                    clientsUpdateTries += 1;
                    if (clientsUpdateTries == clientsData.length) {
                      $mdDialog.show(
                        $mdDialog
                          .alert()
                          .clickOutsideToClose(true)
                          .title("Atribuição Concluída")
                          .textContent(
                            "Foram atualizados os reports de " +
                              clientsPermissionsUpdated +
                              " de " +
                              clientsUpdateTries +
                              " utilizadores."
                          )
                          .ariaLabel(
                            "Foram atualizados os reports de " +
                              clientsPermissionsUpdated +
                              " de " +
                              clientsUpdateTries +
                              " utilizadores."
                          )
                          .ok("Ok")
                      );
                      parentScope.$parent.hideLoader();
                      parentScope.closePanel();
                    }
                  }
                });
            });
          });
      }
    };
  },
]);
