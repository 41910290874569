var app = angular.module("analytics");

app.controller("ReportCtrl", [
  "$scope",
  "reportFactory",
  "reportingReportFactory",
  "$mdSidenav",
  "storageFactory",
  "clientsFactory",
  "$state",
  function ($scope, reportFactory, reportingReportFactory, $mdSidenav, storageFactory, clientsFactory, $state) {
    $scope.myDocs = 0;
    $scope.currentUser = clientsFactory.getUser().user;
    $scope.report = reportFactory.getSelectedReport();

    if ($scope.report == null || $scope.report == "") {
      $state.go("home");
    }

    $scope.$parent.setTitle("Indicadores > " + $scope.report.name);

    if ($scope.report.reportBuilderSrc) {
      reportingReportFactory
        .getReportFilteredBy({ id: $scope.report.reportBuilderSrc })
        .then(function (reportFilteredByData) {
          if (Array.isArray(reportFilteredByData.data) && reportFilteredByData.data.length) {
            let filteredBy =
              reportFilteredByData.data[0].filterBy != null ? reportFilteredByData.data[0].filterBy : "ORG";
            let queryString = "&filterDataBy=" + filteredBy;
            if (window.location.host.indexOf("localhost") > -1) {
              document
                .querySelector("#reportFrame")
                .setAttribute(
                  "src",
                  "http://localhost:3001/reports/" + $scope.report.reportBuilderSrc + "?mode=view" + queryString
                );
            } else {
              document
                .querySelector("#reportFrame")
                .setAttribute(
                  "src",
                  "https://reportbuilder.analytics.kstk.pt/reports/" +
                    $scope.report.reportBuilderSrc +
                    "?mode=view" +
                    queryString
                );
            }
          }
        });
    } else if ($scope.report.src) {
      document.querySelector("#reportFrame").setAttribute("src", $scope.report.src);
    }

    $scope.toggleDocs = function () {
      $mdSidenav("report-right").toggle();
    };

    if ($scope.currentUser.hasToResetPassword) {
      $state.go("client");
    }

    storageFactory.listFilesInContainer($scope.report.id).then((data) => {
      $scope.myDocs = data.data.map((el) => {
        return {
          container: el.container,
          name: el.name,
          mtime: el.mtime.replace("T", " ").split(".")[0],
          size: Math.round(el.size / 1024),
        };
      });
    });

    $scope.$parent.log({
      type: "report view",
      details: $scope.report.name,
      date: new Date(),
    });
  },
]);
