import { changeInterfaceByProduct } from "./productInterfaceStylizer";

var app = angular.module("analytics");

app.controller("GenericCategoryViewCtrl", [
  "$scope",
  "categoryFactory",
  "organizationFactory",
  "reportFactory",
  "clientsFactory",
  "permissionsFactory",
  "moduleFactory",
  "$state",
  "$stateParams",
  "$mdDialog",
  function (
    $scope,
    categoryFactory,
    organizationFactory,
    reportFactory,
    clientsFactory,
    permissionsFactory,
    moduleFactory,
    $state,
    $stateParams,
    $mdDialog
  ) {
    var self = this;

    $scope.$parent.setTitle("");

    $scope.catID = $stateParams.catID;
    $scope.menuMode = $stateParams.menuMode;

    self.gotCategoryData = false;
    self.gotOrganizationData = false;

    $scope.reportSrc = "";
    $scope.report = reportFactory;
    $scope.reportData;
    $scope.showReport = false;
    $scope.hasOrgMenu = false;
    $scope.menuData = null;
    $scope.menuClick = $scope.$parent.menuClick;
    $scope.changePage = $scope.$parent.changePage;
    $scope.getFavoriteStatus = $scope.$parent.getFavoriteStatus;
    $scope.addRemoveFavorites = $scope.$parent.addRemoveFavorites;

    $scope.currentUser = $scope.client.getUser().user;

    if ($scope.currentUser && $scope.currentUser.orgType) {
      changeInterfaceByProduct($scope.currentUser.orgType);
    }

    $scope.clearSearchTerm = function (searchTerm) {
      $scope[searchTerm] = "";
    };

    if (!$scope.client.getUser().user) {
      var lsAuth = getAuthentication();
      if (lsAuth != null) {
        if (new Date().getTime < lsAuth.timestamp + lsAuth.ttl * 1000) {
          $scope.client.setUser(lsAuth.value);
          if (http) {
            http.defaults.headers.common["Authorization"] = lsAuth.value.id;
          }
        }
      }
    }

    $scope.showPolicyDialog = function () {
      $mdDialog
        .show({
          controller: "PolicyDialogCtrl",
          templateUrl: "templates/policyDialog.html",
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: false,
        })
        .then(
          function (answer) {
            //Yes
          },
          function () {
            //No
          }
        );
    };

    if (
      $scope.currentUser != null &&
      !$scope.currentUser.privPolicyTermsUseFlag &&
      !$scope.currentUser.isKSTK &&
      !clientsFactory.isAdmin()
    ) {
      $scope.showPolicyDialog();
    } else {
      if ($scope.currentUser && $scope.currentUser.hasToResetPassword) {
        $state.go("client");
      }
    }

    $scope.organizationData = null;
    if (clientsFactory.getUser().user) {
      self.orgId = clientsFactory.getUser().user.organization;
    }

    categoryFactory.getAll().then(function (categoryData) {
      $scope.categoryData = categoryData;
      $scope.selectedCategory = $scope.categoryData.filter((cat) => cat.id == $scope.catID)[0];
      self.gotCategoryData = true;

      $scope.getMainMenuData($scope);
    });

    organizationFactory.getAll().then(function (organizationData) {
      $scope.organizationData = organizationData;
      self.gotOrganizationData = true;

      $scope.getMainMenuData($scope);

      var clientOrg = $scope.client.getUser().user.organization;
      organizationData.forEach((org) => {
        if (org.id === clientOrg) {
          $scope.clientLogo = org.logo;
          $scope.clientOrgName = org.name;
          $scope.clientOrgType = org.orgType;
          $scope.clientHasSponsorsLogo = org.hasSponsorsLogo;
          $scope.clientHasOrganizationLogo = org.hasOrganizationLogo;
        }
      });
    });

    reportFactory.getAll();

    $scope.getMainMenuData = function ($scope) {
      if (self.gotCategoryData && self.gotOrganizationData) {
        var org = $scope.client.getUser().user.organization;

        reportFactory.getByProperties({ category: $scope.catID }, null, true).then(function (reportData) {
          $scope.reportData = reportData;
          moduleFactory.getByProperties({ category: $scope.catID }, null, true).then(function (modulesData) {
            $scope.modulesData = modulesData;

            let menu = {};

            permissionsFactory.getByProperty("user", $scope.client.getUser().user.id).then(function (permissionData) {
              if (permissionData.length) {
                let items = [];
                let subCategoriesMap = {};

                if ($scope.menuMode == "moduleView") {
                  for (let j = 0; j < permissionData[0].modules.length; j++) {
                    let item = permissionData[0].modules[j];
                    $scope.modulesData.forEach((mod) => {
                      if (mod.value == item) {
                        if (mod.subCategory != null) {
                          let subCategory = $scope.categoryData.filter((cat) => cat.id == mod.subCategory)[0];
                          mod.subCategory = subCategory.name;
                          if (subCategoriesMap[subCategory.id] == null) {
                            subCategoriesMap[subCategory.id] = subCategory;
                          }
                        }
                        if ($scope.selectedCategory.icon) {
                          mod.icon = $scope.selectedCategory.icon;
                        }
                        items.push(mod);
                      }
                    });
                  }
                } else if ($scope.menuMode == "reportView") {
                  for (let j = 0; j < permissionData[0].reports.length; j++) {
                    let item = permissionData[0].reports[j];
                    $scope.reportData.forEach((rep) => {
                      if (rep.id == item) {
                        if (rep.subCategory != null) {
                          let subCategory = $scope.categoryData.filter((cat) => cat.id == rep.subCategory)[0];
                          rep.subCategory = subCategory.name;
                          if (subCategoriesMap[subCategory.id] == null) {
                            subCategoriesMap[subCategory.id] = subCategory;
                          }
                        }
                        if ($scope.selectedCategory.icon) {
                          rep.icon = $scope.selectedCategory.icon;
                        }
                        items.push(rep);
                      }
                    });
                  }
                }

                items.filter((item) => item.category == $scope.catID);
                // Get subCategoriesArr
                let subCategoriesArr = [];
                if (Object.keys(subCategoriesMap).length > 0) {
                  subCategoriesArr = Object.values(subCategoriesMap).sort(function (a, b) {
                    /* items.sort(function compareModules(a, b) {
                      if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
                        return -1;
                      }

                      if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
                        return 1;
                      }

                      return 0;
                    }); */

                    if (a.order < b.order) {
                      return -1;
                    }
                    if (a.order > b.order) {
                      return 1;
                    }
                  });
                  for (let j = 0; j < subCategoriesArr.length; j++) {
                    let subCat = subCategoriesArr[j];
                    subCategoriesArr[j] = subCat.name;
                  }
                  subCategoriesArr.push("Sem categoria");
                }

                let menuDataBySubCat = {};

                //Get menu without subCategories
                if (subCategoriesArr.length == 0 || subCategoriesArr.length < 2) {
                  items.forEach((item) => {
                    if (item.subCategory != null) {
                      if (menuDataBySubCat[item.subCategory] == null) {
                        menuDataBySubCat[item.subCategory] = [];
                      }
                      menuDataBySubCat[item.subCategory].push(item);
                    } else {
                      if (menuDataBySubCat["Sem categoria"] == null) {
                        menuDataBySubCat["Sem categoria"] = [];
                      }
                      menuDataBySubCat["Sem categoria"].push(item);
                    }
                  });
                }
                //Get menu with subCategories
                else {
                  subCategoriesArr.forEach((subCat) => {
                    items.forEach((item) => {
                      if (menuDataBySubCat[subCat] == null) {
                        menuDataBySubCat[subCat] = [];
                      }
                      if ("Sem categoria" && item.subCategory == null) {
                        menuDataBySubCat[subCat].push(item);
                      } else if (subCat == item.subCategory) {
                        menuDataBySubCat[subCat].push(item);
                      }
                    });
                  });
                }

                // Order items in subCats
                for (const subCat in menuDataBySubCat) {
                  if (Object.hasOwnProperty.call(menuDataBySubCat, subCat)) {
                    let items = menuDataBySubCat[subCat];
                    if ($scope.menuMode == "moduleView") {
                      items.sort(function compareModules(a, b) {
                        if (
                          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        ) {
                          return -1;
                        }

                        if (
                          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        ) {
                          return 1;
                        }

                        return 0;
                      });
                    } else if ($scope.menuMode == "reportView") {
                      items.sort(function (a, b) {
                        if (a.order < b.order) {
                          return -1;
                        }
                        if (a.order > b.order) {
                          return 1;
                        }
                      });
                    }
                  }
                }

                $scope.menuData = menuDataBySubCat;
              }
            });
          });
        });
      }
    };
  },
]);
