var app = angular.module("analytics");

app.controller("MyFilesCtrl", [
  "$scope",
  "organizationFactory",
  "storageFactory",
  "clientsFactory",
  "$mdPanel",
  function (
    $scope,
    organizationFactory,
    storageFactory,
    clientsFactory,
    $mdPanel
  ) {
    var self = this;

    $scope.client = clientsFactory;
    $scope.currOrg = $scope.client.getUser().user.organization;
    $scope.myFiles = [];
    $scope.usedSpace = 0;

    $scope.$parent.setTitle("Os Meus Ficheiros");

    $scope.$parent.log({
      type: "module view",
      details: "Os Meus Ficheiros",
      date: new Date(),
    });

    $scope.organizationData = organizationFactory.data;

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.listFiles = function () {
      $scope.usedSpace = 0;
      storageFactory.listFilesInContainer($scope.currOrg).then((data) => {
        $scope.myFiles = data.data.map((el) => {
          $scope.usedSpace = $scope.usedSpace + el.size;
          return {
            container: el.container,
            name: el.name,
            mtime: el.mtime.replace("T", " ").split(".")[0],
            size: Math.round(el.size / 1024),
          };
        });
      });
    };

    $scope.listFiles();

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "MyFilesPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/myFilesPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        locals: {
          filesScope: $scope,
          usedSpace: $scope.usedSpace,
          myFiles: $scope.myFiles,
        },
        onDomRemoved: function () {},
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanelUpload = function () {
      $scope.panelRef.close();
      $scope.listFiles();
      $scope.$parent.thenfulFileUpload();
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
    };

    $scope.uploadPanel = function (event) {
      $scope.openPanel();
    };

    $scope.deleteFile = function (file) {
      storageFactory.deleteFile(file.container, file.name).then(() => {
        $scope.listFiles();
      });
    };

    $scope.downloadFile = function (file) {
      storageFactory.downloadFile(file.container, file.name);
    };

    $scope.parseFileSize = function (fileS) {
      let fileSize = fileS / 1024;
      if (fileSize / 1024 > 1) {
        if (fileSize / 1024 / 1024 > 1) {
          fileSize = Math.round((fileSize / 1024 / 1024) * 100) / 100;
          return fileSize + " GB";
        } else {
          fileSize = Math.round((fileSize / 1024) * 100) / 100;
          return fileSize + " MB";
        }
      } else {
        fileSize = Math.round(fileSize * 100) / 100;
        return fileSize + " kB";
      }
    };
  },
]);
