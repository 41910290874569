var app = angular.module("analytics");

app.controller("MyDocumentsCtrl", [
  "$scope",
  "organizationFactory",
  "storageFactory",
  "clientsFactory",
  "$state",
  function (
    $scope,
    organizationFactory,
    storageFactory,
    clientsFactory,
    $state
  ) {
    var self = this;

    $scope.client = clientsFactory;
    $scope.currOrg = $scope.client.getUser().user.organization;
    $scope.currentUser = clientsFactory.getUser().user;

    if ($scope.currentUser.hasToResetPassword) {
      $state.go("client");
    }

    $scope.$parent.setTitle("Documentos");

    $scope.$parent.log({
      type: "module view",
      details: "Documentos",
      date: new Date(),
    });

    $scope.organizationData = organizationFactory.data;

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    storageFactory.listFilesInContainer($scope.currOrg).then((data) => {
      $scope.myDocs = data.data.map((el) => {
        return {
          container: el.container,
          name: el.name,
          mtime: el.mtime.replace("T", " ").split(".")[0],
          size: Math.round(el.size / 1024),
        };
      });
    });
  },
]);
