var app = angular.module("analytics");

app.controller("CategoriesCtrl", [
  "$scope",
  "$mdPanel",
  "categoryFactory",
  "exportUiGridService",
  function ($scope, $mdPanel, categoryFactory, exportUiGridService) {
    var self = this;

    $scope.$parent.setTitle("Categories");

    var mode = "create";

    var columns = [
      {
        field: "id",
        visible: false,
      },
      {
        field: "name",
        displayName: "Nome",
        sort: {
          priority: 0,
          direction: "asc",
        },
      },
      {
        field: "description",
        displayName: "Descrição Reports",
      },
      {
        field: "order",
        displayName: "Ordem Reports",
      },
      {
        field: "moduleDescription",
        displayName: "Descrição Módulos",
      },
      {
        field: "moduleOrder",
        displayName: "Ordem Módulos",
      },
    ];

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: "Export all data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "all", "all");
          },
          order: 110,
        },
        {
          title: "Export visible data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "visible", "visible");
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    categoryFactory.setCategoryScope($scope);

    $scope.clearForm = function () {
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      categoryFactory.setSelectedCategory(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          categoryFactory.setSelectedCategory(row.entity);
        } else {
          $scope.selectedRow = null;
          categoryFactory.setSelectedCategory(null);
        }
      });
    };

    function getCategories() {
      categoryFactory.getAll().then(function (data) {
        $scope.gridOptions.data = data;
      });
    }

    getCategories();

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: true,
        controller: "CategoryPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/categoryPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getCategories();
        },
        locals: {
          categoryScope: $scope,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
      //getCategories();
    };

    $scope.create = function (event) {
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      mode = "modify";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      categoryFactory.delete($scope.selectedRow.id).then(function () {
        getCategories();
      });
    };
  },
]);
