var app = angular.module("analytics");

app.controller("OrganizationsCtrl", [
  "$scope",
  "$mdPanel",
  "uiGridConstants",
  "organizationFactory",
  "storageFactory",
  "clientsFactory",
  "roleFactory",
  "roleMappingFactory",
  "permissionsFactory",
  "exportUiGridService",
  "$mdToast",
  "productFactory",
  "categoryFactory",
  "reportFactory",
  "moduleFactory",
  "genericFactory",
  "$mdDialog",
  "$state",
  function (
    $scope,
    $mdPanel,
    uiGridConstants,
    organizationFactory,
    storageFactory,
    clientsFactory,
    roleFactory,
    roleMappingFactory,
    permissionsFactory,
    exportUiGridService,
    $mdToast,
    productFactory,
    categoryFactory,
    reportFactory,
    moduleFactory,
    genericFactory,
    $mdDialog,
    $state
  ) {
    var self = this;
    var mode = "create";

    $scope.isAdmin = clientsFactory.isAdmin();
    $scope.isOrgAdmin = clientsFactory.isOrgAdmin();
    $scope.isCPAdmin = clientsFactory.isCPAdmin();

    if (!$scope.isAdmin) {
      $state.go("home");
    } else {
      getOrganizations();

      clientsFactory.getAll().then(function (clients) {
        $scope.clientsData = clients;
      });

      $scope.categoryData = categoryFactory.data;
      productFactory.getAll().then(function (products) {
        $scope.productData = products;
      });
    }

    $scope.genericFactory = genericFactory;
    $scope.classPlanEvalSyncYear = null;

    $scope.$parent.setTitle("Organizations");

    $scope.$parent.log({
      type: "module view",
      details: "Organizações",
      date: new Date(),
    });

    var columns = [
      {
        field: "id",
        visible: false,
      },
      {
        field: "name",
        displayName: "Organization Name",
        sort: {
          direction: uiGridConstants.ASC,
          ignoreSort: false,
          priority: 0,
        },
      },
      {
        field: "orgType",
        displayName: "Organization Type",
      },
      {
        field: "description",
      },
      {
        field: "report",
      },
    ];

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: "Export all data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "all", "all");
          },
          order: 110,
        },
        {
          title: "Export visible data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "visible", "visible");
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    organizationFactory.setParentScope($scope);

    $scope.showClassPlanButtons = function () {
      if ($scope.classPlanButtonsFlag == null) {
        $scope.classPlanButtonsFlag = true;
      } else {
        $scope.classPlanButtonsFlag = !$scope.classPlanButtonsFlag;
      }
    };

    $scope.clearForm = function () {
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      organizationFactory.setSelected(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          organizationFactory.setSelected(row.entity);
        } else {
          $scope.selectedRow = null;
          $scope.clearForm();
        }
      });
    };

    function getOrganizations() {
      organizationFactory.getAll().then(function (data) {
        $scope.gridOptions.data = data;
      });
    }

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "OrganizationPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/organizationPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getOrganizations();
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.openClonePanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "OrganizationClonePanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/organizationClonePanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getOrganizations();
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.openReportBatchAttribution = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "OrganizationReportBatchAttributionPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/organizationReportBatchAttributionPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getOrganizations();
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
    };

    $scope.create = function (event) {
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      mode = "modify";
      $scope.openPanel();
    };

    $scope.clone = function (event) {
      $scope.openClonePanel();
    };

    $scope.generatePublicURL = function (event) {
      let url = `${window.location.origin}/public.html?orgId=${$scope.selectedRow.id}`;
      copyToClipboard(url);
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("URL copiado para o clipboard")
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    function copyToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }

    $scope.delete = function (event) {
      var confirm = $mdDialog
        .confirm()
        .title("Eliminar este registo?")
        .ariaLabel("Confirmar eliminação")
        .ok("Sim")
        .cancel("Não");

      $mdDialog.show(confirm).then(
        function () {
          $scope.$parent.showLoader();
          organizationFactory.delete($scope.selectedRow.id).then(function () {
            $scope.$parent.hideLoader();
            getOrganizations();
            storageFactory.deleteContainer($scope.selectedRow.id);
          });
        },
        function () {
          return;
        }
      );
    };

    $scope.syncClassPlanEvaluations = function (event, evalMode, classPlanEvalSyncYear) {
      $scope.$parent.showLoader();
      $mdToast.show(
        $mdToast
          .simple()
          .textContent(
            "Avaliações do " + evalMode + " a serem sincronizadas para o ano " + classPlanEvalSyncYear + "..."
          )
          .position("top right")
          .hideDelay(5000)
          .theme("success-toast")
      );

      organizationFactory
        .syncClassPlanEvaluations({
          id: $scope.selectedRow.id,
          evalMode: evalMode,
          year: classPlanEvalSyncYear,
        })
        .then(function (result) {
          if (result.data) {
            $mdToast.show(
              $mdToast
                .simple()
                .textContent("Avaliações sincronizadas")
                .position("top right")
                .hideDelay(5000)
                .theme("success-toast")
            );
          } else {
            $mdToast.show(
              $mdToast
                .simple()
                .textContent("Ocorreu um erro")
                .position("top right")
                .hideDelay(5000)
                .theme("success-toast")
            );
          }
        })
        .finally(function () {
          $scope.$parent.hideLoader();
        });
    };

    $scope.syncClassPlanProfCoursePlan = function (event, evalMode) {
      $scope.$parent.showLoader();
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Planos de cursos profissionais a serem sincronizados...")
          .position("top right")
          .hideDelay(5000)
          .theme("success-toast")
      );

      organizationFactory
        .syncClassPlanProfCoursePlan({ id: $scope.selectedRow.id, evalMode: evalMode })
        .then(function (result) {
          if (result.data) {
            $mdToast.show(
              $mdToast
                .simple()
                .textContent("Planos sincronizados")
                .position("top right")
                .hideDelay(5000)
                .theme("success-toast")
            );
          } else {
            $mdToast.show(
              $mdToast
                .simple()
                .textContent("Ocorreu um erro")
                .position("top right")
                .hideDelay(5000)
                .theme("success-toast")
            );
          }
        })
        .finally(function () {
          $scope.$parent.hideLoader();
        });
    };

    $scope.syncAAAF = function (event) {
      $scope.$parent.showLoader();

      organizationFactory
        .syncAAAF($scope.selectedRow.id)
        .then(function () {
          $mdToast.show(
            $mdToast
              .simple()
              .textContent("AAAF Synchronized")
              .position("top right")
              .hideDelay(3000)
              .theme("success-toast")
          );
        })
        .finally(function () {
          $scope.$parent.hideLoader();
        });
    };

    $scope.syncASE = function (event) {
      $scope.$parent.showLoader();

      organizationFactory
        .syncASE($scope.selectedRow.id)
        .then(function () {
          $mdToast.show(
            $mdToast
              .simple()
              .textContent("ASE Synchronized")
              .position("top right")
              .hideDelay(3000)
              .theme("success-toast")
          );
        })
        .finally(function () {
          $scope.$parent.hideLoader();
        });
    };

    $scope.syncProfessors = function (mode) {
      $scope.$parent.showLoader();

      let dataLength = 0;
      let professorsWithInvalidDataLength = 0;
      let duplicateProfessorsLength = 0;
      let professorsSynchronized = 0;
      let missingSubjects = {};
      let newPermissionClients = [];

      organizationFactory.syncProfessors($scope.selectedRow.id).then(function (data) {
        if (data.data != null && data.data.length > 0) {
          let professorsWithInvalidData = [];
          let duplicateProfessors = [];
          let defaultProfessorModules = [];
          let professorsToCreate = {};
          let updatedClients = [];
          let profSubsAlreadyAddedFlag = false;

          $scope.clientsData = $scope.clientsData.filter((client) => client.organization == $scope.selectedRow.id);

          data.data.sort(function (a, b) {
            if (a.nome_professor != null && b.nome_professor != null) {
              if (
                a["nome_professor"].normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                b["nome_professor"].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return -1;
              }
              if (
                a["nome_professor"].normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                b["nome_professor"].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              ) {
                return 1;
              }
            }
          });

          if ($scope.selectedRow.orgUserCounter == null) {
            $scope.selectedRow.orgUserCounter = 1;
          }

          moduleFactory.getAll().then(function (mods) {
            //Check if can be commented

            /* for (let i = 0; i < mods.length; i++) {
                if ($scope.getProName(mods[i].product) == "Plano de Turma" && (mods[i].name == "Caracterização de Turma" || mods[i].name == "AFC Articulação Horizontal" || mods[i].name == "AFC Articulação Vertical" || mods[i].name == "Avaliação" || mods[i].name == "Grelha de Critérios de Avaliação" || mods[i].name == "Grelha de Medidas de Suporte" || mods[i].name == "Indisciplina" || mods[i].name == "Instrumentos de Avaliação" || mods[i].name == "Documentos")) {
                  defaultProfessorModules.push(mods[i].value);
                } else {
                  continue;
                }
              } */

            for (let j = 0; j < data.data.length; j++) {
              let prof = data.data[j];

              let newProf = {};
              let profNames = [];

              // Get professors of current scholar year
              if (prof.ano_lectivo == null || prof.ano_lectivo != getCurrentScholarYear()) {
                continue;
              }

              /* if (prof.ano_lectivo == null) {
                  continue;
                } */

              if (
                prof.nif_professor != null &&
                prof.nome_professor != null &&
                prof.ano_lectivo != null &&
                prof.escola != null &&
                prof.turma != null &&
                prof.curso != null &&
                prof.disciplina != null
              ) {
                // Get professor name array to get first and last name
                profNames = prof.nome_professor.split(" ");
              } else {
                if (
                  prof.nif_professor != null &&
                  professorsWithInvalidData.indexOf(prof.nif_professor) == -1 &&
                  professorsToCreate[prof.nif_professor] == null
                ) {
                  // Reject professor without nif
                  professorsWithInvalidData.push(prof.nif_professor);
                }
                // Check if there is no more data.data to end professor refresh
                if (j == data.data.length - 1 && newPermissionClients.length == 0 && updatedClients.length == 0) {
                  endProfRefresh();
                }
                continue;
              }

              let duplicateClient = false;
              for (let m = 0; m < $scope.clientsData.length; m++) {
                let client = $scope.clientsData[m];
                if (prof.nif_professor != null && client.nif == prof.nif_professor) {
                  duplicateClient = true;
                  break;
                }
              }

              // If in default mode (clicked on the 'Carregar Professors' button) the duplicate professors will not be updated
              if (mode == "default") {
                if (duplicateClient) {
                  if (prof.nif_professor != null && duplicateProfessors.indexOf(prof.nif_professor) == -1) {
                    duplicateProfessors.push(prof.nif_professor);
                  }
                  /* if (j == data.data.length - 1 && newPermissionClients.length == 0 && updatedClients.length == 0) {
                      endProfRefresh();
                    } */
                  continue;
                }
              }

              /* if (j == data.data.length - 1 && newPermissionClients.length == 0 && updatedClients.length == 0) {
                  endProfRefresh();
                } */

              if (professorsToCreate[prof.nif_professor] == null) {
                newProf.nif = prof.nif_professor;
                newProf.name = profNames[0];
                newProf.surname = profNames[profNames.length - 1];
                newProf.fullName = newProf.name + " " + newProf.surname;
                newProf.extendedName = prof.nome_professor;
                newProf.organization = $scope.selectedRow.id;
                newProf.email =
                  "pt" + $scope.selectedRow.orgUserCounter + "@" + $scope.selectedRow.simplifiedOrgName + ".pt";
                if (
                  newProf.institutionalMail == null &&
                  prof.e_mail != null &&
                  prof.e_mail != "" &&
                  validateEmail(prof.e_mail)
                ) {
                  newProf.email = prof.e_mail;
                  newProf.institutionalMail = prof.e_mail;
                }
                newProf.password = Math.random().toString(36).slice(-8);
                newProf.tempPassword = newProf.password;
                newProf.hasToResetPassword = true;
                newProf.language = "pt-PT";
                newProf.profile = "user";

                newProf.year = new Array(prof.ano_lectivo.toString());
                newProf.course = new Array(prof.curso);
                newProf.school = new Array(prof.escola);
                newProf.class = new Array(prof.turma);

                let subjectClass = {};
                subjectClass.subject = prof.disciplina;
                subjectClass.class = prof.turma;
                newProf.subject = new Array(subjectClass);

                professorsToCreate[prof.nif_professor] = newProf;

                // Increment orgUserCounter if the user does not exist
                if (!duplicateClient) {
                  $scope.selectedRow.orgUserCounter += 1;
                }
              } else {
                // If professor is already inserted in the professorsToCreate map, then it will be updated with the following info
                if (
                  professorsToCreate[prof.nif_professor].institutionalMail == null &&
                  prof.e_mail != null &&
                  prof.e_mail != "" &&
                  validateEmail(prof.e_mail)
                ) {
                  professorsToCreate[prof.nif_professor].email = prof.e_mail;
                  professorsToCreate[prof.nif_professor].institutionalMail = prof.e_mail;
                }

                //course
                if (professorsToCreate[prof.nif_professor].course.indexOf(prof.curso) == -1) {
                  professorsToCreate[prof.nif_professor].course.push(prof.curso);
                }

                //school
                if (professorsToCreate[prof.nif_professor].school.indexOf(prof.escola) == -1) {
                  professorsToCreate[prof.nif_professor].school.push(prof.escola);
                }

                //class
                if (professorsToCreate[prof.nif_professor].class.indexOf(prof.turma) == -1) {
                  professorsToCreate[prof.nif_professor].class.push(prof.turma);
                }

                //subject
                let subjectClass = {};
                subjectClass.subject = prof.disciplina;
                subjectClass.class = prof.turma;
                professorsToCreate[prof.nif_professor].subject.push(subjectClass);
              }
            }

            //Exit if no professor needs to be created

            if (Object.values(professorsToCreate).length == 0) {
              $mdToast.show(
                $mdToast
                  .simple()
                  .textContent("Não existem professores para criar")
                  .position("top right")
                  .hideDelay(10000)
                  .theme("fail-toast")
              );
              $scope.$parent.hideLoader();
              return;
            }

            // Insert missing subjects

            let fromTableFieldsCollections = ["Class_Plan_Classes", "Class_Plan_Subjects"];
            let fromTableFields = ["class", "subject"];
            let missingSubjectsPromisses = [];
            let courseTypes = $scope.getFromTableData("Class_Plan_Course_Types");

            for (let j = 0; j < Object.values(professorsToCreate).length; j++) {
              let newProf = Object.values(professorsToCreate)[j];

              fromTableFields.forEach(function (field, index) {
                let fromTableData = $scope.getFromTableData(fromTableFieldsCollections[index]);
                if (newProf[field] != null && newProf[field] != []) {
                  let value = newProf[field];
                  for (let j = 0; j < value.length; j++) {
                    let valueEl = value[j];
                    let filteredRow = null;
                    if (field == "class") {
                      filteredRow = fromTableData.filter(
                        (el) => el[field] === valueEl && newProf.year.indexOf(el.year) != -1
                      )[0];
                      if (filteredRow == null) {
                        for (let w = 0; w < 13; w++) {
                          filteredRow = fromTableData.filter(
                            (el) => w + "º ano " + el[field] === valueEl && newProf.year.indexOf(el.year) != -1
                          )[0];
                          if (filteredRow != null) {
                            break;
                          }
                        }
                      }
                      if (filteredRow != null) {
                        if (newProf.schoolYear == null) {
                          newProf.schoolYear = JSON.parse(JSON.stringify(filteredRow.schoolYear));
                          //Parse subjectClass courseType and school year according to the class info
                          newProf.subject.forEach((subjectClass) => {
                            if (subjectClass.class == valueEl) {
                              subjectClass.courseType = filteredRow.courseType;
                              subjectClass.schoolYear = filteredRow.schoolYear;
                            }
                          });
                        } else {
                          filteredRow.schoolYear.forEach((schoYear) => {
                            if (newProf.schoolYear.indexOf(schoYear) == -1) {
                              newProf.schoolYear.push(JSON.parse(JSON.stringify(filteredRow.schoolYear[0])));
                            }
                          });
                          //Parse subjectClass courseType and school year according to the class info
                          newProf.subject.forEach((subjectClass) => {
                            if (subjectClass.class == valueEl) {
                              subjectClass.courseType = filteredRow.courseType;
                              subjectClass.schoolYear = filteredRow.schoolYear[0];
                            }
                          });
                        }
                      }
                    } else if (field == "subject") {
                      /* filteredRow = fromTableData.filter(el => el[field] === valueEl.subject && el.schoolYear == valueEl.schoolYear)[0];
                        fromTableData.forEach(el => {
                          if (el[field] === valueEl.subject && el.schoolYear == valueEl.schoolYear) {
                            filteredRow = el;
                          }
                        }); */
                      let professionalSubjectFlag = false;
                      if (valueEl.courseType) {
                        let filteredCourseType = courseTypes.filter((el) => el.id === valueEl.courseType)[0];
                        if (filteredCourseType != null) {
                          if (filteredCourseType.courseType == "Profissional") {
                            professionalSubjectFlag = true;
                          }
                        }
                      }
                      for (let t = 0; t < fromTableData.length; t++) {
                        let el = fromTableData[t];
                        if (
                          !professionalSubjectFlag &&
                          el[field] === valueEl.subject &&
                          el.schoolYear == valueEl.schoolYear
                        ) {
                          filteredRow = el;
                        }
                        if (
                          professionalSubjectFlag &&
                          el[field] === valueEl.subject &&
                          el.schoolYear == valueEl.schoolYear &&
                          el.courseType &&
                          valueEl.courseType &&
                          el.courseType == valueEl.courseType
                        ) {
                          filteredRow = el;
                        }
                        if (el.profSubFlag) {
                          profSubsAlreadyAddedFlag = true;
                          if (filteredRow != null) {
                            break;
                          }
                        }
                      }
                    }
                    //Insert missing subjects
                    if (filteredRow == null) {
                      if (
                        valueEl.courseType != null &&
                        valueEl.schoolYear != null &&
                        valueEl.subject != null &&
                        missingSubjects[valueEl.courseType + valueEl.schoolYear + valueEl.subject] == null
                      ) {
                        if (valueEl.schoolYear.length > 1) {
                        } else {
                          missingSubjects[valueEl.courseType + valueEl.schoolYear + valueEl.subject] = valueEl;
                        }
                      }
                    }
                  }
                }
              });
            }

            if (!profSubsAlreadyAddedFlag) {
              classPlanAddMissingProfessionalSubjects(missingSubjects);
            }

            if (Object.keys(missingSubjects).length != 0) {
              genericFactory.setRouteName("Class_Plan_Subjects");
              for (const subject in missingSubjects) {
                if (missingSubjects.hasOwnProperty(subject)) {
                  let missSub = missingSubjects[subject];
                  let newMissingSubject = {};
                  newMissingSubject.organization = $scope.selectedRow.id;
                  newMissingSubject.courseType = missSub.courseType;
                  newMissingSubject.schoolYear = missSub.schoolYear;
                  newMissingSubject.subject = missSub.subject;
                  newMissingSubject.createdAt = Date.now();
                  newMissingSubject.createdBy = "admin";
                  if (missSub.profSubFlag) {
                    newMissingSubject.profSubFlag = true;
                  }
                  missingSubjectsPromisses.push(
                    genericFactory.create(newMissingSubject).then((createdMissingSubject) => {
                      console.log("missing subject inserted.");
                    })
                    /* .then(function () {
                        if (Object.keys(missingSubjectsPromisses).indexOf(subject) == Object.keys(missingSubjectsPromisses).length - 1) {
                          continueProfessorsParsing(missingSubjectsPromisses, mode);
                        }
                      })
                      .catch(function () {
                        if (Object.keys(missingSubjectsPromisses).indexOf(subject) == Object.keys(missingSubjectsPromisses).length - 1) {
                          continueProfessorsParsing(missingSubjectsPromisses, mode);
                        }
                      }) */
                  );
                }
              }
              continueProfessorsParsing(missingSubjectsPromisses, mode);
            } else {
              continueProfessorsParsing(missingSubjectsPromisses, mode);
            }
          });

          //Parse professor data

          function continueProfessorsParsing(missingSubjectsPromisses, mode) {
            Promise.all(missingSubjectsPromisses).then(function () {
              genericFactory.setRouteName("Class_Plan_Subjects");
              genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (newClassPlanSubjects) {
                let subjectMod = $scope.modMetadata.filter((mod) => mod.collection == "Class_Plan_Subjects")[0];
                subjectMod.data = newClassPlanSubjects;

                let fromTableFieldsCollections = [
                  "Class_Plan_Courses",
                  "Class_Plan_Classes",
                  "Class_Plan_Subjects",
                  "Class_Plan_Clusters",
                  "Class_Plan_Schools",
                ];
                let fromTableFields = ["course", "class", "subject", "cluster", "school"];
                let courseTypes = $scope.getFromTableData("Class_Plan_Course_Types");

                for (let j = 0; j < Object.values(professorsToCreate).length; j++) {
                  let newProf = Object.values(professorsToCreate)[j];
                  let newProfParsedCourseTypes = [];

                  fromTableFields.forEach(function (field, index) {
                    let fromTableData = $scope.getFromTableData(fromTableFieldsCollections[index]);
                    if (field == "cluster") {
                      newProf.cluster = new Array(fromTableData[0].id);
                    } else {
                      if (newProf[field] != null && newProf[field] != []) {
                        let value = newProf[field];
                        for (let j = 0; j < value.length; j++) {
                          let valueEl = value[j];
                          let filteredRow = null;
                          if (field == "class") {
                            filteredRow = fromTableData.filter(
                              (el) => el[field] === valueEl && newProf.year.indexOf(el.year) != -1
                            )[0];
                            if (filteredRow == null) {
                              for (let w = 0; w < 13; w++) {
                                filteredRow = fromTableData.filter(
                                  (el) => w + "º ano " + el[field] === valueEl && newProf.year.indexOf(el.year) != -1
                                )[0];
                                if (filteredRow != null) {
                                  break;
                                }
                              }
                            }
                            if (filteredRow != null) {
                              if (newProf.schoolYear == null) {
                                newProf.schoolYear = JSON.parse(JSON.stringify(filteredRow.schoolYear));
                                //Parse subjectClass courseType and school year according to the class info
                                newProf.subject.forEach((subjectClass) => {
                                  if (subjectClass.class == valueEl) {
                                    subjectClass.classId = filteredRow.id;
                                    subjectClass.courseType = filteredRow.courseType;
                                    subjectClass.schoolYear = filteredRow.schoolYear[0];
                                  }
                                });
                              } else {
                                filteredRow.schoolYear.forEach((schoYear) => {
                                  if (newProf.schoolYear.indexOf(schoYear) == -1) {
                                    newProf.schoolYear.push(JSON.parse(JSON.stringify(filteredRow.schoolYear[0])));
                                  }
                                });
                                //Parse subjectClass courseType and school year according to the class info
                                newProf.subject.forEach((subjectClass) => {
                                  if (subjectClass.class == valueEl) {
                                    subjectClass.classId = filteredRow.id;
                                    subjectClass.courseType = filteredRow.courseType;
                                    subjectClass.schoolYear = filteredRow.schoolYear[0];
                                  }
                                });
                              }

                              //Insert subject mapping with courseType and schoolYear
                              newProf.subject.forEach((subjectClass) => {
                                let subjectClassCopy = JSON.parse(JSON.stringify(subjectClass));
                                let newMapping = {};
                                newMapping.courseType = subjectClassCopy.courseType;
                                newMapping.schoolYear = subjectClassCopy.schoolYear;
                                newMapping.subject = subjectClassCopy.subject;
                                if (subjectClassCopy.class == valueEl) {
                                  if (newProf.subModClassMapping == null) {
                                    newProf.subModClassMapping = [];
                                    newMapping.class = JSON.parse(JSON.stringify(subjectClassCopy.classId));
                                    newProf.subModClassMapping.push(newMapping);
                                  } else {
                                    let subModClassMappingAlreadyInsertedFlag = false;
                                    newProf.subModClassMapping.forEach((profSubjectMap) => {
                                      if (
                                        profSubjectMap.class == valueEl &&
                                        profSubjectMap.subject == newMapping.subject
                                      ) {
                                        subModClassMappingAlreadyInsertedFlag = true;
                                      }
                                    });
                                    if (!subModClassMappingAlreadyInsertedFlag) {
                                      newMapping.class = JSON.parse(JSON.stringify(subjectClassCopy.classId));
                                      newProf.subModClassMapping.push(newMapping);
                                    }
                                  }
                                }
                              });

                              //Sort subject mapping
                              if (newProf.subModClassMapping) {
                                newProf.subModClassMapping.sort(function (a, b) {
                                  if (a["schoolYear"] < b["schoolYear"]) {
                                    return -1;
                                  }
                                  if (a["schoolYear"] > b["schoolYear"]) {
                                    return 1;
                                  }
                                });
                              }
                            }
                          } else if (field == "subject") {
                            /* filteredRow = fromTableData.filter(el => el[field] === valueEl.subject && el.schoolYear == valueEl.schoolYear); */

                            //Check if multiple subjects were matched
                            /* if (Array.isArray(filteredRow) && filteredRow.length > 1) {
                                for (let m = 0; m < filteredRow.length;) {
                                  let filteredRowEl = filteredRow[m];
                                  if (filteredRowEl.courseType != valueEl.courseType) {
                                    filteredRow.splice(m, 1);
                                  } else {
                                    m++;
                                  }
                                }
                              } */

                            let professionalSubjectFlag = false;
                            if (valueEl.courseType) {
                              let filteredCourseType = courseTypes.filter((el) => el.id === valueEl.courseType)[0];
                              if (filteredCourseType != null) {
                                if (filteredCourseType.courseType == "Profissional") {
                                  professionalSubjectFlag = true;
                                }
                              }
                            }
                            for (let t = 0; t < fromTableData.length; t++) {
                              let el = fromTableData[t];
                              if (
                                !professionalSubjectFlag &&
                                el[field] === valueEl.subject &&
                                el.schoolYear == valueEl.schoolYear
                              ) {
                                filteredRow = el;
                                break;
                              }
                              if (
                                professionalSubjectFlag &&
                                el[field] === valueEl.subject &&
                                el.schoolYear == valueEl.schoolYear &&
                                el.courseType &&
                                valueEl.courseType &&
                                el.courseType == valueEl.courseType
                              ) {
                                filteredRow = el;
                                break;
                              }
                            }

                            if (Array.isArray(filteredRow)) {
                              filteredRow = filteredRow[0];
                            }

                            //Parse the subject on subModClassMapping. Insert school years of prof subjects that were remove or were not previously added
                            if (filteredRow != null) {
                              newProf.subModClassMapping.forEach((subModMap) => {
                                if (
                                  subModMap.subject == filteredRow.subject &&
                                  subModMap.schoolYear == filteredRow.schoolYear
                                ) {
                                  subModMap.subject = filteredRow.id;
                                  if (newProf.schoolYear && newProf.schoolYear.indexOf(subModMap.schoolYear) == -1) {
                                    newProf.schoolYear.push(JSON.parse(JSON.stringify(subModMap.schoolYear)));
                                  }
                                  if (newProf.courseType && newProf.courseType.indexOf(subModMap.courseType) == -1) {
                                    newProf.courseType.push(subModMap.courseType);
                                  }
                                }
                              });
                            }
                          } else if (field == "course") {
                            filteredRow = fromTableData.filter((el) => el[field] === valueEl)[0];
                            //Course Type
                            if (filteredRow != null) {
                              let courseTypes = $scope.getFromTableData("Class_Plan_Course_Types");
                              let filteredCourseType = courseTypes.filter((el) => el.id === filteredRow.courseType)[0];
                              if (filteredCourseType != null) {
                                if (newProf.courseType == null) {
                                  newProf.courseType = [];
                                }
                                if (newProf.courseType.indexOf(filteredCourseType.id) == -1) {
                                  newProf.courseType.push(filteredCourseType.id);
                                  if (newProfParsedCourseTypes.indexOf(filteredCourseType.courseType) == -1) {
                                    newProfParsedCourseTypes.push(filteredCourseType.courseType);
                                  }
                                }
                              }
                            }
                          } else {
                            filteredRow = fromTableData.filter((el) => el[field] === valueEl)[0];
                          }
                          if (filteredRow != null) {
                            newProf[field][j] = filteredRow.id;
                          } else {
                            if (!isNaN(Number(valueEl))) {
                              filteredRow = fromTableData.filter((el) => el[field] === Number(valueEl))[0];
                              if (filteredRow != null) {
                                newProf[field][j] = filteredRow.id;
                              }
                            }
                          }
                        }
                      }
                    }
                  });

                  fromTableFields.forEach(function (field) {
                    newProf[field] = newProf[field].filter((v, i, a) => a.indexOf(v) === i);
                  });

                  let clientAlreadyAdded = false;

                  for (let u = 0; u < $scope.clientsData.length; u++) {
                    let client = $scope.clientsData[u];
                    if (client.nif == newProf.nif) {
                      clientAlreadyAdded = true;
                      if (mode == "updateProfs") {
                        client.profile = "user";
                        client.year = newProf.year;
                        client.schoolYear = newProf.schoolYear;
                        client.cluster = newProf.cluster;
                        client.courseType = newProf.courseType;
                        client.course = newProf.course;
                        client.school = newProf.school;
                        client.subject = newProf.subject;
                        client.class = newProf.class;
                        if (
                          (client.institutionalMail == null || client.institutionalMail == "") &&
                          newProf.institutionalMail != null &&
                          newProf.institutionalMail != "" &&
                          validateEmail(newProf.institutionalMail) &&
                          newProf.institutionalMail != "no.email@nowhere.com"
                        ) {
                          client.institutionalMail = newProf.institutionalMail;
                        }
                        /* if (
                          client.institutionalMail != null &&
                          client.institutionalMail != "" &&
                          validateEmail(client.institutionalMail) &&
                          client.institutionalMail != "no.email@nowhere.com"
                        ) {
                          client.email = client.institutionalMail;
                        } */
                        /* client.subModClassMapping = newProf.subModClassMapping; */
                        client.hasToResetPassword = true;
                        if (client.classDirector) {
                          client.classDirector = null;
                        }
                        if (client.classDirectorSchoolYears) {
                          client.classDirectorSchoolYears = null;
                        }

                        let professorModules = [];
                        if (newProfParsedCourseTypes.length > 0) {
                          professorModules = getProfessorModulesByCourseType(newProfParsedCourseTypes);
                        }

                        updatedClients.push(
                          clientsFactory.modify(client.id, client).then((updatedClient) => {
                            permissionsFactory
                              .getByProperty("user", updatedClient.id)
                              .then(function (clientPermissions) {
                                if (
                                  clientPermissions != null &&
                                  Array.isArray(clientPermissions) &&
                                  clientPermissions.length > 0
                                ) {
                                  clientPermissions = clientPermissions[0];
                                  clientPermissions.modules = professorModules;
                                  clientPermissions.reports = [];
                                  permissionsFactory
                                    .modify(clientPermissions.id, clientPermissions)
                                    .then(function () {
                                      console.log("updated client");
                                      if (j == Object.values(professorsToCreate).length - 1) {
                                        endProfRefresh();
                                      }
                                    })
                                    .catch(function () {
                                      console.log("updated client");
                                      if (j == Object.values(professorsToCreate).length - 1) {
                                        endProfRefresh();
                                      }
                                    });
                                } else {
                                  console.log("updated client");
                                  if (j == Object.values(professorsToCreate).length - 1) {
                                    endProfRefresh();
                                  }
                                }
                              });
                          })
                        );
                      }
                      break;
                    }
                  }

                  if (!clientAlreadyAdded) {
                    clientsFactory
                      .create({
                        nif: newProf.nif,
                        name: newProf.name,
                        surname: newProf.surname,
                        fullName: newProf.fullName,
                        extendedName: newProf.extendedName,
                        organization: newProf.organization,
                        email: newProf.email,
                        institutionalMail: newProf.institutionalMail,
                        password: newProf.password,
                        tempPassword: newProf.tempPassword,
                        hasToResetPassword: newProf.hasToResetPassword,
                        language: newProf.language,
                        profile: newProf.profile,
                        //Class Plan fields
                        year: newProf.year,
                        schoolYear: newProf.schoolYear,
                        cluster: newProf.cluster,
                        courseType: newProf.courseType,
                        course: newProf.course,
                        school: newProf.school,
                        subject: newProf.subject,
                        class: newProf.class,
                        /*  subModClassMapping: newProf.subModClassMapping, */
                      })
                      .then(function (createdClient) {
                        if (createdClient.profile === "orgAdmin") {
                          roleFactory.getAll().then(function (roles) {
                            let orgAdminRole = roles.filter((e) => e.name === "orgAdmin");

                            if (orgAdminRole.length) {
                              orgAdminRole = orgAdminRole[0];

                              roleMappingFactory.create({
                                principalType: "USER",
                                principalId: createdClient.id,
                                roleId: orgAdminRole.id,
                              });
                            }
                          });
                        }

                        let professorModules = [];
                        if (newProfParsedCourseTypes.length > 0) {
                          professorModules = getProfessorModulesByCourseType(newProfParsedCourseTypes);
                        }

                        newPermissionClients.push(
                          permissionsFactory
                            .create({
                              user: createdClient.id,
                              reports: [],
                              modules: professorModules,
                            })
                            .then(function (createdData) {
                              professorsSynchronized += 1;
                              if (j == Object.values(professorsToCreate).length - 1) {
                                endProfRefresh();
                              }
                            })
                            .catch(function () {
                              if (j == Object.values(professorsToCreate).length - 1) {
                                endProfRefresh();
                              }
                            })
                        );
                      });
                  }

                  //End prof refresh if only inserting new profs and the last one was already added, pretty rare
                  if (mode == "default" && clientAlreadyAdded && j == Object.values(professorsToCreate).length - 1) {
                    endProfRefresh();
                  }
                }
              });
            });
          }

          function endProfRefresh() {
            if (Object.keys(professorsToCreate).length > 0 && newPermissionClients.length > 0) {
              organizationFactory.modify($scope.selectedRow.id, $scope.selectedRow);
            }
            clientsFactory.getByProperty("organization", $scope.selectedRow.id).then(function (clients) {
              Promise.all(updatedClients).then(function () {
                Promise.all(newPermissionClients).then(function () {
                  //Remove from professorsWIthInvalidData all the professors that were created
                  for (let k = 0; k < clients.length; k++) {
                    let profNif = clients[k].nif;
                    if (profNif != null) {
                      let invalidProfIndex = professorsWithInvalidData.indexOf(profNif);
                      if (invalidProfIndex != null) {
                        professorsWithInvalidData.splice(invalidProfIndex, 1);
                        if (professorsWithInvalidData.length == 0) {
                          break;
                        }
                      }
                    }
                  }
                  professorsWithInvalidDataLength = professorsWithInvalidData.length;

                  if (updatedClients.length > 0 || newPermissionClients.length > 0) {
                    $mdToast.show(
                      $mdToast
                        .simple()
                        .textContent(
                          "Profs Created: " +
                            professorsSynchronized +
                            ". Profs Updated: " +
                            updatedClients.length +
                            ". Profs Missing Data: " +
                            professorsWithInvalidDataLength
                        )
                        .position("top right")
                        .hideDelay(10000)
                        .theme("success-toast")
                    );
                  } else {
                    $mdToast.show(
                      $mdToast
                        .simple()
                        .textContent("Nenhum professor foi criado/atualizado")
                        .position("top right")
                        .hideDelay(10000)
                        .theme("fail-toast")
                    );
                  }
                  $scope.clientsData = clients;
                  $scope.$parent.hideLoader();
                });
              });
            });
          }

          function endProfUpdate() {
            Promise.all(updatedClients).then(function () {
              if (updatedClients.length > 0) {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent("Professores atualizados: " + updatedClients.length)
                    .position("top right")
                    .hideDelay(10000)
                    .theme("success-toast")
                );
              } else {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent("Nenhum professor atualizado")
                    .position("top right")
                    .hideDelay(10000)
                    .theme("fail-toast")
                );
              }
              clientsFactory.getAll().then(function (clients) {
                $scope.clientsData = clients;
                $scope.$parent.hideLoader();
              });
            });
          }

          function endProfSync() {
            if (Object.keys(professorsToCreate).length > 0) {
              organizationFactory.modify($scope.selectedRow.id, $scope.selectedRow);
            }

            Promise.all(newPermissionClients).then(function () {
              dataLength = data.length;
              professorsWithInvalidDataLength = professorsWithInvalidData.length;
              duplicateProfessorsLength = duplicateProfessors.length;

              if (dataLength > 0) {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent(
                      "Professors Synchronized: " +
                        professorsSynchronized +
                        " (" +
                        professorsWithInvalidDataLength +
                        " with missing data and " +
                        duplicateProfessorsLength +
                        " duplicated)"
                    )
                    .position("top right")
                    .hideDelay(10000)
                    .theme("success-toast")
                );
              } else {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent("No Professors Data")
                    .position("top right")
                    .hideDelay(3000)
                    .theme("fail-toast")
                );
              }
              clientsFactory.getAll().then(function (clients) {
                $scope.clientsData = clients;
                $scope.$parent.hideLoader();
              });
            });
          }
        }
      });
    };

    $scope.getProName = function (proId) {
      var filteredPro = $scope.productData.filter((e) => e.id === proId);

      if (filteredPro.length) {
        return filteredPro[0].product;
      } else {
        return "";
      }
    };

    $scope.getCatName = function (catId) {
      var filteredCat = $scope.categoryData.filter((e) => e.id === catId);

      if (filteredCat.length) {
        return filteredCat[0].name;
      } else {
        return "";
      }
    };

    $scope.getFromTableData = function (fromTable) {
      let mod = $scope.modMetadata.filter((o) => o.collection == fromTable)[0];
      return mod.data;
    };

    $scope.loadPTInfo = function (mode) {
      $scope.$parent.showLoader();

      moduleFactory.getAll().then(function (modMetadata) {
        modMetadata.forEach(function (mod) {
          if (mod.product && Array.isArray(mod.product)) {
            mod.proName = [];
            mod.product.forEach((pro) => {
              if ($scope.getProName(pro) != null && $scope.getProName(pro) != "") {
                mod.proName.push($scope.getProName(pro));
              }
            });
            mod.proName = mod.proName.toString();
          }
          mod.catName = $scope.getCatName(mod.category);
        });
        getModData(modMetadata, mode);
      });
    };

    function getModData(modMetadata, mode) {
      if (mode == "ref") {
        for (let i = 0; i < modMetadata.length; ) {
          // Get mods of category "Administração"
          if (modMetadata[i].category != "610bca0150a38100953b639a") {
            modMetadata.splice(i, 1);
          } else {
            i++;
          }
        }
      }

      if (
        mode == "clusterSchoolClass" ||
        mode == "student" ||
        mode == "courses" ||
        mode == "professors" ||
        mode == "updateProfSubjects" ||
        mode == "updateProfs"
      ) {
        for (let i = 0; i < modMetadata.length; ) {
          // Get mods of category "Administração"
          if (modMetadata[i].category != "610bca0150a38100953b639a") {
            modMetadata.splice(i, 1);
          } else {
            i++;
          }
        }
      }

      $scope.modMetadata = modMetadata;
      let modDataPromises = [];

      for (let i = 0; i < modMetadata.length; i++) {
        let mod = modMetadata[i];
        genericFactory.setRouteName(mod.collection);
        if (mode == "ref") {
          modDataPromises.push(
            genericFactory.getByProperty("organization", "5a146532e511ce4db73a09cd").then(function (modData) {
              mod.data = modData;
              return modData;
            })
          );
        }
        if (
          mode == "professors" ||
          mode == "clusterSchoolClass" ||
          mode == "student" ||
          mode == "courses" ||
          mode == "updateProfSubjects" ||
          mode == "updateProfs"
        ) {
          modDataPromises.push(
            genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (modData) {
              mod.data = modData;
              return modData;
            })
          );
        }
        if (i == modMetadata.length - 1) {
          if (mode == "ref") {
            loadModData($scope.modMetadata, modDataPromises);
          }
          if (mode == "clusterSchoolClass" || mode == "student" || mode == "courses") {
            Promise.all(modDataPromises).then(function (result) {
              $scope.getClassPlanInfo($scope.modMetadata, mode);
            });
          }
          if (mode == "professors") {
            Promise.all(modDataPromises).then(function (result) {
              $scope.syncProfessors("default");
            });
          }
          if (mode == "updateProfSubjects") {
            Promise.all(modDataPromises).then(function (result) {
              $scope.updatedProfSubjects();
            });
          }
          if (mode == "updateProfs") {
            Promise.all(modDataPromises).then(function (result) {
              $scope.syncProfessors("updateProfs");
            });
          }
        }
      }
    }

    function loadModData(modMetadata, modDataPromises) {
      Promise.all(modDataPromises).then(function (result) {
        for (let k = 0; k < modMetadata.length; ) {
          let mod = modMetadata[k];
          if (mod.data == null || (Array.isArray(mod.data) && mod.data.length == 0) || mod.collection == "Logs") {
            modMetadata.splice(k, 1);
          } else {
            k++;
          }
        }

        let elsWillBeCreatedFlag = false;
        let numElsToBeCreated = 0;
        let numElsCreated = 0;
        let newElsToCreate = [];
        for (let w = 0; w < modMetadata.length; w++) {
          let mod = modMetadata[w];
          genericFactory.setRouteName(mod.collection);
          genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (selectedOrgModData) {
            if (selectedOrgModData.length > 0) {
              mod.data = selectedOrgModData;
              mod.alreadyHasData = true;
              console.log("org already has info about: " + mod.name);
              if (w == modMetadata.length - 1 && !elsWillBeCreatedFlag) {
                newElsMongoMapping(modMetadata);
              } else if (w == modMetadata.length - 1 && elsWillBeCreatedFlag) {
                async.eachSeries(
                  newElsToCreate,
                  function iteratee(item, callback) {
                    async.setImmediate(function () {
                      genericFactory.setRouteName(item.collection);
                      genericFactory.create(item).then(() => {
                        numElsCreated += 1;
                        console.log("el inserted.");
                        callback();
                      });
                    });
                  },
                  function done() {
                    if (numElsCreated == numElsToBeCreated) {
                      newElsMongoMapping(modMetadata);
                    }
                  },
                  function (err) {
                    if (err) {
                      console.log(err);
                    } else {
                      //Done
                    }
                  }
                );
              }
            } else {
              elsWillBeCreatedFlag = true;
              let modData = mod.data;
              genericFactory.setRouteName(mod.collection);

              for (let j = 0; j < modData.length; j++) {
                let modEl = JSON.parse(JSON.stringify(modData[j]));
                let newEl = modEl;

                newEl.oldID = newEl.id;
                newEl.collection = JSON.parse(JSON.stringify(mod.collection));
                delete newEl.id;

                newEl["organization"] = $scope.selectedRow.id;
                newEl["createdAt"] = Date.now();
                newEl["createdBy"] = "admin";
                newElsToCreate.push(newEl);
                numElsToBeCreated += 1;
                if (w == modMetadata.length - 1 && j == modData.length - 1) {
                  async.eachSeries(
                    newElsToCreate,
                    function iteratee(item, callback) {
                      async.setImmediate(function () {
                        genericFactory.setRouteName(item.collection);
                        genericFactory.create(item).then(() => {
                          numElsCreated += 1;
                          console.log("el inserted.");
                          callback();
                        });
                      });
                    },
                    function done() {
                      if (numElsCreated == numElsToBeCreated) {
                        newElsMongoMapping(modMetadata);
                      }
                    },
                    function (err) {
                      if (err) {
                        console.log(err);
                      } else {
                        //Done
                      }
                    }
                  );
                }
              }
            }
          });
        }
      });
    }

    function newElsMongoMapping(modMetadata) {
      console.log("all els inserted.");

      let newModDataPromises = [];
      for (let w = 0; w < modMetadata.length; w++) {
        let mod = modMetadata[w];
        if (mod.alreadyHasData == null) {
          genericFactory.setRouteName(mod.collection);
          newModDataPromises.push(
            genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (selectedOrgModData) {
              console.log("new mod data.");
              mod.data = selectedOrgModData;
            })
          );
        }
      }
      $scope.$parent.hideLoader();
      Promise.all(newModDataPromises).then(function (result) {
        console.log("all mod data.");
        $scope.activeTab = {};
        for (let w = 0; w < modMetadata.length; w++) {
          let mod = modMetadata[w];
          if (mod.alreadyHasData == null) {
            $scope.activeTab = mod.tabs["Geral"];
            genericFactory.setRouteName(mod.collection);
            utilFunctions["mongoMapping"]($scope, mod.data, "default", true, mod.collection);
          }
        }
      });
    }

    $scope.getClassPlanInfo = function (modMetadata, mode) {
      $scope.$parent.showLoader();

      organizationFactory.getClassPlanInfo($scope.selectedRow.id).then(function (classPlanInfo) {
        console.log(classPlanInfo.data);

        if (mode == "student") {
          //Students

          let students = [];
          classPlanInfo.data.forEach((classPlanEl) => {
            if (
              classPlanEl.ano_lectivo &&
              [
                "2016",
                "2017",
                "2018",
                "2019",
                "2020",
                "2021",
                "2022",
                "2023",
                "2024",
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022,
                2023,
                2024,
              ].indexOf(classPlanEl.ano_lectivo) != -1 &&
              classPlanEl.nome_aluno &&
              classPlanEl.turma &&
              classPlanEl.escola &&
              classPlanEl.agrupamento &&
              classPlanEl.id_aluno
            ) {
              let alreadyAddedStudentFlag = false;
              students.forEach((student) => {
                // Distinguish students only by their id_aluno
                if (student.id_aluno == classPlanEl.id_aluno) {
                  alreadyAddedStudentFlag = true;
                }
              });
              if (!alreadyAddedStudentFlag) {
                students.push(classPlanEl);
              }
            }
          });

          if (students.length > 0) {
            let studentsCollection = "";
            if (
              $scope.selectedRow.studentsCollection == null ||
              $scope.selectedRow.studentsCollection == "Class Plan"
            ) {
              studentsCollection = "Class_Plan_Students";
            } else if ($scope.selectedRow.studentsCollection == "KSTK") {
              studentsCollection = "KSTK_Students";
            }
            genericFactory.setRouteName(studentsCollection);
            genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (selectedOrgPTStudents) {
              genericFactory.setRouteName("Class_Plan_Clusters");
              genericFactory
                .getByProperty("organization", $scope.selectedRow.id)
                .then(function (selectedOrgPTClusters) {
                  genericFactory.setRouteName("Class_Plan_Schools");
                  genericFactory
                    .getByProperty("organization", $scope.selectedRow.id)
                    .then(function (selectedOrgPTSchools) {
                      genericFactory.setRouteName("Class_Plan_Classes");
                      genericFactory
                        .getByProperty("organization", $scope.selectedRow.id)
                        .then(function (selectedOrgPTClasses) {
                          genericFactory.setRouteName(studentsCollection);
                          /* if (selectedOrgPTStudents.length > 0) {
                        $scope.$parent.hideLoader();
                        return;
                      } */

                          // Distinguish students only by their id_aluno
                          if (Array.isArray(selectedOrgPTStudents) && selectedOrgPTStudents.length > 0) {
                            removeMatchingDuplicateElements(
                              selectedOrgPTStudents,
                              students,
                              ["id_aluno"],
                              ["id_aluno"],
                              [null]
                            );
                          }
                          if (students.length > 0) {
                            let newStudentPromises = [];

                            students.forEach((stud) => {
                              let studentEl = {};

                              Object.defineProperty(stud, "name", Object.getOwnPropertyDescriptor(stud, "nome_aluno"));
                              delete stud["nome_aluno"];

                              Object.defineProperty(stud, "year", Object.getOwnPropertyDescriptor(stud, "ano_lectivo"));
                              delete stud["ano_lectivo"];

                              if (stud.year) {
                                stud.year = stud.year.toString();
                              }

                              Object.defineProperty(
                                stud,
                                "cluster",
                                Object.getOwnPropertyDescriptor(stud, "agrupamento")
                              );
                              delete stud["agrupamento"];

                              if (stud.nif_aluno && stud.nif == null) {
                                Object.defineProperty(stud, "nif", Object.getOwnPropertyDescriptor(stud, "nif_aluno"));
                                delete stud["nif_aluno"];
                              }

                              Object.defineProperty(stud, "school", Object.getOwnPropertyDescriptor(stud, "escola"));
                              delete stud["escola"];

                              Object.defineProperty(
                                stud,
                                "schoolYear",
                                Object.getOwnPropertyDescriptor(stud, "ano_escolaridade")
                              );
                              delete stud["ano_escolaridade"];

                              Object.defineProperty(stud, "class", Object.getOwnPropertyDescriptor(stud, "turma"));
                              delete stud["turma"];

                              Object.defineProperty(
                                stud,
                                "courseType",
                                Object.getOwnPropertyDescriptor(stud, "tipo_curso")
                              );
                              delete stud["tipo_curso"];

                              Object.defineProperty(stud, "course", Object.getOwnPropertyDescriptor(stud, "curso"));
                              delete stud["curso"];

                              Object.defineProperty(
                                stud,
                                "educationLevel",
                                Object.getOwnPropertyDescriptor(stud, "nivel_ensino")
                              );
                              delete stud["nivel_ensino"];

                              if (stud.sexo) {
                                if (stud.sexo == "Masculino") {
                                  stud.sexo = "M";
                                } else if (stud.sexo == "Feminino") {
                                  stud.sexo = "F";
                                } else {
                                  stud.sexo = "Outro";
                                }
                              }

                              if (stud.data_nascimento) {
                                stud.data_nascimento = new Date(stud.data_nascimento).getTime();
                              }

                              /* var ageDifMs = Date.now() - {}.birthdayMS.getTime();
                          var ageDate = new Date(ageDifMs);
                          Math.abs(ageDate.getUTCFullYear() - 1970) */

                              /* studentEl.aluno = stud.aluno;
                          studentEl.name = stud.nome_aluno;
                          studentEl.year = stud.ano_lectivo;
                          studentEl.nif = stud.nif;
                          studentEl.sexo = stud.sexo;
                          studentEl.data_nascimento = stud.data_nascimento;
                          studentEl.idade = stud.idade;
                          studentEl.idade_escolar = stud.idade_escolar;
                          studentEl.over_age = stud.over_age;
                          studentEl.naturalidade = stud.naturalidade;
                          studentEl.localidade = stud.localidade;

                          studentEl.cluster = stud.agrupamento;
                          studentEl.school = stud.escola;
                          let schoolYearArr = [];
                          schoolYearArr.push(stud.ano_escolaridade);
                          studentEl.schoolYear = schoolYearArr;
                          studentEl.class = stud.turma;
                          studentEl.courseType = stud.tipo_curso;
                          studentEl.course = stud.curso;
                          //no postgres está 3.º Ciclo e no mongo está 3º Ciclo
                          studentEl.educationLevel = stud.nivel_ensino;
                          studentEl.mixedClass = stud.turma_mista; */

                              stud["organization"] = $scope.selectedRow.id;
                              stud["createdAt"] = Date.now();
                              stud["createdBy"] = "admin";

                              newStudentPromises.push(stud);
                            });

                            let numInsertedStudents = 0;
                            async.eachSeries(
                              newStudentPromises,
                              function iteratee(item, callback) {
                                async.setImmediate(function () {
                                  genericFactory.create(item).then(() => {
                                    console.log("student inserted.");
                                    numInsertedStudents += 1;
                                    callback();
                                  });
                                });
                              },
                              function done() {
                                if (numInsertedStudents == newStudentPromises.length) {
                                  genericFactory
                                    .getByProperty("organization", $scope.selectedRow.id)
                                    .then(function (newStudents) {
                                      let studentMod = $scope.modMetadata.filter(
                                        (mod) => mod.collection == studentsCollection
                                      )[0];
                                      studentMod.data = newStudents;

                                      let studentDataToMap = newStudents.filter(
                                        (stud) =>
                                          [
                                            "2016",
                                            "2017",
                                            "2018",
                                            "2019",
                                            "2020",
                                            "2021",
                                            "2022",
                                            "2023",
                                            "2024",
                                          ].indexOf(stud.year) != -1
                                      );

                                      $scope.activeTab = studentMod.tabs["Geral"];
                                      utilFunctions["mongoMapping"](
                                        $scope,
                                        studentDataToMap,
                                        "default",
                                        null,
                                        null,
                                        null,
                                        true
                                      );

                                      console.log($scope.modMetadata);
                                      /* $scope.$parent.hideLoader(); */
                                    });
                                }
                              },
                              function (err) {
                                if (err) {
                                  console.log(err);
                                } else {
                                  //Done
                                }
                              }
                            );

                            /* Promise.all(newStudentPromises).then(function (result) {
                          genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (newStudents) {

                            let studentMod = $scope.modMetadata.filter(mod => mod.collection == "Class_Plan_Students")[0];
                            studentMod.data = newStudents;

                            $scope.activeTab = studentMod.tabs["Geral"];
                            utilFunctions["mongoMapping"]($scope, studentMod.data, "default");

                            console.log($scope.modMetadata);
                            $scope.$parent.hideLoader();
                          })
                        }) */
                          } else {
                            $scope.$parent.hideLoader();
                            return;
                          }
                        });
                    });
                });
            });
          } else {
            $scope.$parent.hideLoader();
            return;
          }
        } else if (mode == "clusterSchoolClass") {
          //Cluster
          let clusters = [];
          classPlanInfo.data.forEach((classPlanEl) => {
            if (classPlanEl.agrupamento && clusters.indexOf(classPlanEl.agrupamento) == -1) {
              clusters.push(classPlanEl.agrupamento);
            }
          });
          if (clusters.length > 0) {
            genericFactory.setRouteName("Class_Plan_Clusters");
            genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (selectedOrgPTClusters) {
              if (Array.isArray(selectedOrgPTClusters) && selectedOrgPTClusters.length > 0) {
                removeMatchingDuplicateElements(selectedOrgPTClusters, clusters, "cluster");
                /* $scope.$parent.hideLoader();
                  return; */
              }

              let newClusterPromises = [];

              if (clusters.length > 0) {
                clusters.forEach((cluster) => {
                  let clustersEl = {};
                  clustersEl.cluster = cluster;
                  clustersEl["organization"] = $scope.selectedRow.id;
                  clustersEl["createdAt"] = Date.now();
                  clustersEl["createdBy"] = "admin";

                  newClusterPromises.push(
                    genericFactory.create(clustersEl).then(() => {
                      console.log("cluster inserted.");
                    })
                  );
                });
              }

              Promise.all(newClusterPromises).then(function (result) {
                genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (newClusters) {
                  if (clusters.length > 0) {
                    let clusterMod = $scope.modMetadata.filter((mod) => mod.collection == "Class_Plan_Clusters")[0];
                    clusterMod.data = newClusters;
                  }

                  //Schools

                  let schools = [];
                  classPlanInfo.data.forEach((classPlanEl) => {
                    if (classPlanEl.escola && classPlanEl.agrupamento) {
                      let alreadyAddedSchoolFlag = false;
                      schools.forEach((school) => {
                        if (school.escola == classPlanEl.escola && school.agrupamento == classPlanEl.agrupamento) {
                          alreadyAddedSchoolFlag = true;
                        }
                      });
                      if (!alreadyAddedSchoolFlag) {
                        schools.push(classPlanEl);
                      }
                    }
                  });

                  if (schools.length > 0) {
                    genericFactory.setRouteName("Class_Plan_Schools");
                    genericFactory
                      .getByProperty("organization", $scope.selectedRow.id)
                      .then(function (selectedOrgPTSchools) {
                        if (Array.isArray(selectedOrgPTSchools) && selectedOrgPTSchools.length > 0) {
                          removeMatchingDuplicateElements(
                            selectedOrgPTSchools,
                            schools,
                            ["cluster", "school"],
                            ["agrupamento", "escola"],
                            [newClusters]
                          );
                          /* $scope.$parent.hideLoader();
                          return; */
                        }

                        let newSchoolPromises = [];

                        if (schools.length > 0) {
                          schools.forEach((school) => {
                            let schoolsEl = {};
                            schoolsEl.cluster = school.agrupamento;
                            schoolsEl.school = school.escola;
                            schoolsEl["organization"] = $scope.selectedRow.id;
                            schoolsEl["createdAt"] = Date.now();
                            schoolsEl["createdBy"] = "admin";

                            newSchoolPromises.push(
                              genericFactory.create(schoolsEl).then(() => {
                                console.log("school inserted.");
                              })
                            );
                          });
                        }

                        Promise.all(newSchoolPromises).then(function (result) {
                          genericFactory
                            .getByProperty("organization", $scope.selectedRow.id)
                            .then(function (newSchools) {
                              if (schools.length > 0) {
                                let schoolMod = $scope.modMetadata.filter(
                                  (mod) => mod.collection == "Class_Plan_Schools"
                                )[0];
                                schoolMod.data = newSchools;

                                $scope.activeTab = schoolMod.tabs["Geral"];
                                utilFunctions["mongoMapping"](
                                  $scope,
                                  schoolMod.data,
                                  "default",
                                  null,
                                  "Class_Plan_Schools"
                                );
                              }

                              //Classes

                              let classes = [];
                              classPlanInfo.data.forEach((classPlanEl) => {
                                if (
                                  classPlanEl.ano_lectivo &&
                                  [
                                    "2016",
                                    "2017",
                                    "2018",
                                    "2019",
                                    "2020",
                                    "2021",
                                    "2022",
                                    "2023",
                                    "2024",
                                    2016,
                                    2017,
                                    2018,
                                    2019,
                                    2020,
                                    2021,
                                    2022,
                                    2023,
                                    2024,
                                  ].indexOf(classPlanEl.ano_lectivo) != -1 &&
                                  classPlanEl.ano_escolaridade &&
                                  classPlanEl.turma &&
                                  classPlanEl.escola &&
                                  classPlanEl.agrupamento
                                ) {
                                  let alreadyAddedClassFlag = false;
                                  for (let j = 0; j < classes.length; j++) {
                                    let clss = classes[j];
                                    if (
                                      clss.ano_lectivo == classPlanEl.ano_lectivo &&
                                      clss.turma == classPlanEl.turma &&
                                      clss.escola == classPlanEl.escola &&
                                      clss.agrupamento == classPlanEl.agrupamento
                                    ) {
                                      alreadyAddedClassFlag = true;
                                      if (
                                        clss.ano_escolaridade != null &&
                                        Array.isArray(clss.ano_escolaridade) &&
                                        clss.ano_escolaridade.indexOf(classPlanEl.ano_escolaridade) == -1
                                      ) {
                                        clss.ano_escolaridade.push(classPlanEl.ano_escolaridade);
                                      }
                                      break;
                                    }
                                  }
                                  if (!alreadyAddedClassFlag) {
                                    let schoolYearArr = [];
                                    schoolYearArr.push(classPlanEl.ano_escolaridade);
                                    classPlanEl.ano_escolaridade = schoolYearArr;
                                    classes.push(classPlanEl);
                                  }
                                }
                              });

                              if (classes.length > 0) {
                                genericFactory.setRouteName("Class_Plan_Classes");
                                genericFactory
                                  .getByProperty("organization", $scope.selectedRow.id)
                                  .then(function (selectedOrgPTClasses) {
                                    if (Array.isArray(selectedOrgPTClasses) && selectedOrgPTClasses.length > 0) {
                                      removeMatchingDuplicateElements(
                                        selectedOrgPTClasses,
                                        classes,
                                        ["year", "cluster", "school", "class"],
                                        ["ano_lectivo", "agrupamento", "escola", "turma"],
                                        [null, newClusters, newSchools]
                                      );
                                      /* $scope.$parent.hideLoader();
                                  return; */
                                    }

                                    let newClassPromises = [];

                                    if (classes.length > 0) {
                                      classes.forEach((clss) => {
                                        let classEl = {};
                                        classEl.year = clss.ano_lectivo;
                                        classEl.cluster = clss.agrupamento;
                                        classEl.school = clss.escola;
                                        classEl.schoolYear = clss.ano_escolaridade;
                                        classEl.class = clss.turma;
                                        classEl.courseType = clss.tipo_curso;
                                        classEl.course = clss.curso;
                                        classEl.educationLevel = clss.nivel_ensino;
                                        if (classEl.schoolYear.length > 1) {
                                          classEl.mixedClass = "Sim";
                                        } else {
                                          classEl.mixedClass = "Não";
                                        }

                                        classEl["organization"] = $scope.selectedRow.id;
                                        classEl["createdAt"] = Date.now();
                                        classEl["createdBy"] = "admin";

                                        newClassPromises.push(
                                          genericFactory.create(classEl).then(() => {
                                            console.log("class inserted.");
                                          })
                                        );
                                      });

                                      Promise.all(newClassPromises).then(function (result) {
                                        genericFactory
                                          .getByProperty("organization", $scope.selectedRow.id)
                                          .then(function (newClasses) {
                                            if (classes.length > 0) {
                                              let classMod = $scope.modMetadata.filter(
                                                (mod) => mod.collection == "Class_Plan_Classes"
                                              )[0];
                                              classMod.data = newClasses;

                                              $scope.activeTab = classMod.tabs["Geral"];
                                              utilFunctions["mongoMapping"](
                                                $scope,
                                                classMod.data,
                                                "default",
                                                null,
                                                "Class_Plan_Classes"
                                              );
                                            }

                                            console.log($scope.modMetadata);
                                            $scope.$parent.hideLoader();
                                          });
                                      });
                                    } else {
                                      $scope.$parent.hideLoader();
                                      return;
                                    }
                                  });
                              } else {
                                $scope.$parent.hideLoader();
                                return;
                              }
                            });
                        });
                      });
                  } else {
                    $scope.$parent.hideLoader();
                    return;
                  }
                });
              });
            });
          } else {
            $scope.$parent.hideLoader();
            return;
          }
        } else if (mode == "courses") {
          //Course Type
          let courseTypes = [];
          classPlanInfo.data.forEach((classPlanEl) => {
            if (classPlanEl.tipo_curso && courseTypes.indexOf(classPlanEl.tipo_curso) == -1) {
              courseTypes.push(classPlanEl.tipo_curso);
            }
          });
          if (courseTypes.length > 0) {
            genericFactory.setRouteName("Class_Plan_Course_Types");
            genericFactory
              .getByProperty("organization", $scope.selectedRow.id)
              .then(function (selectedOrgPTCourseTypes) {
                //Remove course types that were already added
                if (Array.isArray(selectedOrgPTCourseTypes) && selectedOrgPTCourseTypes.length > 0) {
                  removeMatchingDuplicateElements(selectedOrgPTCourseTypes, courseTypes, "courseType");
                }

                let newCourseTypePromises = [];
                if (courseTypes.length > 0) {
                  courseTypes.forEach((courseType) => {
                    let courseTypesEl = {};
                    courseTypesEl.courseType = courseType;
                    courseTypesEl["organization"] = $scope.selectedRow.id;
                    courseTypesEl["createdAt"] = Date.now();
                    courseTypesEl["createdBy"] = "admin";

                    newCourseTypePromises.push(
                      genericFactory.create(courseTypesEl).then(() => {
                        console.log("courseType inserted.");
                      })
                    );
                  });
                }

                Promise.all(newCourseTypePromises).then(function (result) {
                  genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (newCourseTypes) {
                    let courseTypeMod = $scope.modMetadata.filter(
                      (mod) => mod.collection == "Class_Plan_Course_Types"
                    )[0];
                    courseTypeMod.data = newCourseTypes;

                    //Courses

                    let courses = [];
                    classPlanInfo.data.forEach((classPlanEl) => {
                      if (classPlanEl.curso && classPlanEl.tipo_curso) {
                        let alreadyAddedCourseFlag = false;
                        courses.forEach((course) => {
                          if (course.curso == classPlanEl.curso && course.tipo_curso == classPlanEl.tipo_curso) {
                            alreadyAddedCourseFlag = true;
                          }
                        });
                        if (!alreadyAddedCourseFlag) {
                          courses.push(classPlanEl);
                        }
                      }
                    });

                    if (courses.length > 0) {
                      genericFactory.setRouteName("Class_Plan_Courses");
                      genericFactory
                        .getByProperty("organization", $scope.selectedRow.id)
                        .then(function (selectedOrgPTCourses) {
                          //Remove courses that were already added
                          if (Array.isArray(selectedOrgPTCourses) && selectedOrgPTCourses.length > 0) {
                            removeMatchingDuplicateElements(
                              selectedOrgPTCourses,
                              courses,
                              ["courseType", "course"],
                              ["tipo_curso", "curso"],
                              [newCourseTypes]
                            );
                          }

                          if (courses.length > 0) {
                            let newCoursePromises = [];

                            courses.forEach((course) => {
                              let coursesEl = {};
                              coursesEl.courseType = course.tipo_curso;
                              coursesEl.course = course.curso;
                              coursesEl["organization"] = $scope.selectedRow.id;
                              coursesEl["createdAt"] = Date.now();
                              coursesEl["createdBy"] = "admin";

                              newCoursePromises.push(
                                genericFactory.create(coursesEl).then(() => {
                                  console.log("course inserted.");
                                })
                              );
                            });

                            Promise.all(newCoursePromises).then(function (result) {
                              genericFactory
                                .getByProperty("organization", $scope.selectedRow.id)
                                .then(function (newCourses) {
                                  let courseMod = $scope.modMetadata.filter(
                                    (mod) => mod.collection == "Class_Plan_Courses"
                                  )[0];
                                  courseMod.data = newCourses;

                                  $scope.activeTab = courseMod.tabs["Geral"];
                                  utilFunctions["mongoMapping"](
                                    $scope,
                                    courseMod.data,
                                    "default",
                                    null,
                                    "Class_Plan_Courses"
                                  );

                                  console.log($scope.modMetadata);
                                  $scope.$parent.hideLoader();
                                });
                            });
                          } else {
                            $scope.$parent.hideLoader();
                            return;
                          }
                        });
                    } else {
                      $scope.$parent.hideLoader();
                      return;
                    }
                  });
                });
              });
          } else {
            $scope.$parent.hideLoader();
            return;
          }
        }
      });
    };

    $scope.updatedProfSubjects = function () {
      $scope.$parent.showLoader();

      organizationFactory.syncProfessors($scope.selectedRow.id).then(function (profData) {
        if (profData != null && profData.length > 0) {
          //Professional Subjects

          genericFactory.setRouteName("Class_Plan_Subjects");
          genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (selectedOrgPTSubjects) {
            //Check if professional subjects where already added

            let profSubsAlreadyAddedFlag = false;
            for (let k = 0; k < selectedOrgPTSubjects.length; k++) {
              let el = selectedOrgPTSubjects[k];
              if (el.profSubFlag) {
                profSubsAlreadyAddedFlag = true;
                break;
              }
            }

            if (profSubsAlreadyAddedFlag) {
              $scope.$parent.hideLoader();
              return;
            }

            let newProfSubPromises = [];

            //Gather all subjects to add

            /* let profSubjectsToInsert = [];
              profData.forEach(profDataEl => {
                if (profDataEl.nif_professor != null && profDataEl.nome_professor != null && profDataEl.ano_lectivo != null && profDataEl.escola != null && profDataEl.turma != null && profDataEl.curso != null && profDataEl.disciplina != null) {
                  let alreadyAddedProfSubFlag = false;
                  profSubjectsToInsert.forEach(profSubToInsert => {
                    if (profSubToInsert.curso == profDataEl.curso && profSubToInsert.turma == profDataEl.turma && profSubToInsert.disciplina == profDataEl.disciplina) {
                      alreadyAddedProfSubFlag = true;
                    }
                  });
                  if (!alreadyAddedProfSubFlag) {
                    profSubjectsToInsert.push(profDataEl);
                  }
                }
              }); 

              if (profSubjectsToInsert.length == 0) {
                $scope.$parent.hideLoader();
                return;
              }*/

            //Parse new prof subjects fields and check each course type and school year

            /* let courseTypes = $scope.getFromTableData("Class_Plan_Course_Types");
              let courses = $scope.getFromTableData("Class_Plan_Courses");
              let classes = $scope.getFromTableData("Class_Plan_Classes");
              let schoolYears = $scope.getFromTableData("Class_Plan_School_Years");

              for (let w = 0; w < profSubjectsToInsert.length;) {
                let prof = profSubjectsToInsert[w];
                let profCourse = courses.filter(crs => crs.course == prof.curso)[0];
                if (profCourse != null) {
                  prof.courseType = profCourse.courseType;
                  let profCourseType = courseTypes.filter(crsTy => crsTy.id == prof.courseType)[0];
                  if (profCourseType != null) {
                    prof.parsedCourseType = profCourseType.courseType;
                    if (prof.parsedCourseType == "Profissional") {
                      let profClass = classes.filter(clss => clss.class == prof.turma)[0];
                      if (profClass != null) {
                        let profSchoolYear = schoolYears.filter(sY => sY.id == profClass.schoolYear)[0];
                        if (profSchoolYear != null) {
                          prof.schoolYear = profSchoolYear.schoolYear;
                          w++;
                        } else {
                          profSubjectsToInsert.splice(w, 1);
                          continue;
                        }
                      } else {
                        profSubjectsToInsert.splice(w, 1);
                        continue;
                      }
                    } else {
                      profSubjectsToInsert.splice(w, 1);
                      continue;
                    }
                  } else {
                    profSubjectsToInsert.splice(w, 1);
                    continue;
                  }
                } else {
                  profSubjectsToInsert.splice(w, 1);
                  continue;
                }
              } */

            //Insert new subjects

            /* profSubjectsToInsert.forEach(subject => {
                let profSubjectsToInsertEl = {};
                profSubjectsToInsertEl.courseType = subject.parsedCourseType;
                profSubjectsToInsertEl.course = [subject.curso];
                profSubjectsToInsertEl.schoolYear = subject.schoolYear;
                profSubjectsToInsertEl.subject = subject.disciplina;
                profSubjectsToInsertEl["organization"] = $scope.selectedRow.id;
                profSubjectsToInsertEl["createdAt"] = Date.now();
                profSubjectsToInsertEl["createdBy"] = "admin";
                profSubjectsToInsert.profSubFlag = true;
                newProfSubPromises.push(genericFactory.create(profSubjectsToInsertEl).then(() => {
                  console.log("prof sub inserted.")
                }));
              }); */

            //Insert FCT and PAP professional evaluations as subjects

            newProfSubPromises.push(
              genericFactory.create({
                courseType: "Profissional",
                schoolYear: 10,
                subject: "FCT",
                organization: $scope.selectedRow.id,
                createdAt: Date.now(),
                createdBy: "admin",
                profSubFlag: true,
              })
            );

            newProfSubPromises.push(
              genericFactory.create({
                courseType: "Profissional",
                schoolYear: 11,
                subject: "FCT",
                organization: $scope.selectedRow.id,
                createdAt: Date.now(),
                createdBy: "admin",
                profSubFlag: true,
              })
            );

            newProfSubPromises.push(
              genericFactory.create({
                courseType: "Profissional",
                schoolYear: 12,
                subject: "FCT",
                organization: $scope.selectedRow.id,
                createdAt: Date.now(),
                createdBy: "admin",
                profSubFlag: true,
              })
            );

            newProfSubPromises.push(
              genericFactory.create({
                courseType: "Profissional",
                schoolYear: 10,
                subject: "PAP",
                organization: $scope.selectedRow.id,
                createdAt: Date.now(),
                createdBy: "admin",
                profSubFlag: true,
              })
            );

            newProfSubPromises.push(
              genericFactory.create({
                courseType: "Profissional",
                schoolYear: 11,
                subject: "PAP",
                organization: $scope.selectedRow.id,
                createdAt: Date.now(),
                createdBy: "admin",
                profSubFlag: true,
              })
            );

            newProfSubPromises.push(
              genericFactory.create({
                courseType: "Profissional",
                schoolYear: 12,
                subject: "PAP",
                organization: $scope.selectedRow.id,
                createdAt: Date.now(),
                createdBy: "admin",
                profSubFlag: true,
              })
            );

            Promise.all(newProfSubPromises).then(function (result) {
              genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (newProfSubs) {
                let subjectMod = $scope.modMetadata.filter((mod) => mod.collection == "Class_Plan_Subjects")[0];
                subjectMod.data = newProfSubs;

                $scope.activeTab = subjectMod.tabs["Geral"];
                utilFunctions["mongoMapping"]($scope, subjectMod.data, "default", null, "Class_Plan_Subjects");

                //Insert FCT and PAP professional evaluations as professional modules

                genericFactory.setRouteName("Class_Plan_Prof_Modules");
                let newProfModPromises = [];

                newProfModPromises.push(
                  genericFactory.create({
                    module: "FCT",
                    moduleCod: "FCT",
                    organization: $scope.selectedRow.id,
                    createdAt: Date.now(),
                    createdBy: "admin",
                  })
                );

                newProfModPromises.push(
                  genericFactory.create({
                    module: "PAP",
                    moduleCod: "PAP",
                    organization: $scope.selectedRow.id,
                    createdAt: Date.now(),
                    createdBy: "admin",
                  })
                );

                Promise.all(newProfModPromises).then(function (result) {
                  genericFactory.getByProperty("organization", $scope.selectedRow.id).then(function (newProfMods) {
                    let profModModule = $scope.modMetadata.filter(
                      (mod) => mod.collection == "Class_Plan_Prof_Modules"
                    )[0];
                    profModModule.data = newProfMods;

                    $scope.activeTab = profModModule.tabs["Geral"];
                    utilFunctions["mongoMapping"](
                      $scope,
                      profModModule.data,
                      "default",
                      null,
                      "Class_Plan_Prof_Modules"
                    );

                    console.log($scope.modMetadata);
                    $scope.$parent.hideLoader();
                  });
                });
              });
            });
          });
        }
      });
    };

    $scope.createAnalyticsReportStructure = function (mode) {
      $scope.$parent.showLoader();
      let reportStructure;
      if (mode == "cluster") {
        reportStructure = [
          {
            name: "Quadro Geral",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "59e51d95f032032e744d7a9a",
            order: 1,
            public: false,
            catName: "Indicadores Globais",
          },
          {
            name: "Resultados Finais de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c50b9969b9129865c97c8",
            order: 1,
            public: false,
            catName: "Avaliação Final",
          },
          {
            name: "Informação de # de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e1b969b9129865c97c0",
            order: 1,
            public: false,
            catName: "População",
          },
          {
            name: "Resultados de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4dd9969b9129865c97bf",
            order: 1,
            public: false,
            catName: "Avaliação",
          },
          {
            name: "Aprovação e Transição de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e32969b9129865c97c1",
            order: 1,
            public: false,
            catName: "Sucesso Final",
          },
          {
            name: "Absentismo de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e44969b9129865c97c2",
            order: 1,
            public: false,
            catName: "Absentismo",
          },
          {
            name: "Risco em Resultados de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "592f62898d57d117d360c681",
            order: 1,
            public: false,
            catName: "Risco na Avaliação",
          },
          {
            name: "Resultados vs Objetivos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e8a969b9129865c97c5",
            order: 1,
            public: false,
            catName: "Avaliação vs Objetivos",
          },
          {
            name: "Sucesso na Avaliação",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4dd9969b9129865c97bf",
            order: 2,
            public: false,
            catName: "Avaliação",
          },
          {
            name: "Informação sobre EE",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e1b969b9129865c97c0",
            order: 2,
            public: false,
            catName: "População",
          },
          {
            name: "Avaliação Interna vs Provas Nacionais",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c50b9969b9129865c97c8",
            order: 2,
            public: false,
            catName: "Avaliação Final",
          },
        ];
      } else if (mode == "municipality") {
        reportStructure = [
          {
            allowFullScreen: true,
            catName: "População",
            category: "5a7c4e1b969b9129865c97c0",
            height: 800,
            name: "Informação de # Alunos",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Avaliação",
            category: "5a7c4dd9969b9129865c97bf",
            height: 800,
            name: "Resultados de Alunos",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Sucesso Final",
            category: "5a7c4e32969b9129865c97c1",
            height: 800,
            name: "Aprovação e Transição de Alunos",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Relatórios",
            category: "5a7c4e57969b9129865c97c3",
            height: 800,
            name: "Relatório de Alunos - Avaliação",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Absentismo",
            category: "5a7c4e44969b9129865c97c2",
            height: 800,
            name: "Absentismo de Alunos",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Indicadores Globais",
            category: "59e51d95f032032e744d7a9a",
            height: 800,
            name: "Indicadores por Freguesia",
            order: 2,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Indicadores Globais",
            category: "59e51d95f032032e744d7a9a",
            height: 800,
            name: "Quadro Geral",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            allowFullScreen: true,
            catName: "Avaliação Final",
            category: "5a7c50b9969b9129865c97c8",
            height: 800,
            name: "Resultados Finais de Alunos",
            order: 1,
            organization: $scope.selectedRow.id,
            width: 800,
            public: false,
          },
          {
            name: "Risco em Resultados de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "592f62898d57d117d360c681",
            order: 1,
            public: false,
            catName: "Risco",
          },
          {
            name: "Sucesso na Avaliação",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4dd9969b9129865c97bf",
            order: 2,
            public: false,
          },
          {
            name: "Cenários de Avaliação",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e8a969b9129865c97c5",
            order: 1,
            public: false,
          },
          {
            name: "Informação de # Turmas e Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e1b969b9129865c97c0",
            order: 2,
            public: false,
          },
          {
            name: "Informação sobre EE",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e1b969b9129865c97c0",
            order: 3,
            public: false,
            catName: "População",
          },
        ];
      } else if (mode == "cimbal") {
        reportStructure = [
          {
            name: "Quadro Geral",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "59e51d95f032032e744d7a9a",
            order: 1,
            public: false,
          },
          {
            name: "População - Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e1b969b9129865c97c0",
            order: 1,
            public: false,
          },
          {
            name: "Informação sobre EE",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e1b969b9129865c97c0",
            order: 2,
            public: false,
          },
          {
            name: "Resultados de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4dd9969b9129865c97bf",
            order: 1,
            public: false,
          },
          {
            name: "Sucesso na Avaliação",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4dd9969b9129865c97bf",
            order: 2,
            public: false,
          },
          {
            name: "Aprovação e Transição de Alunos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "5a7c4e32969b9129865c97c1",
            order: 1,
            public: false,
          },
          {
            name: "Utilização de Transportes Escolares",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "610c159250a38100953b645d",
            order: 1,
            public: false,
          },
          {
            name: "Escola a Tempo Inteiro",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "610c157350a38100953b645c",
            order: 1,
            public: false,
          },
          {
            name: "Auxílios Económicos Ativos",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "610c161350a38100953b645f",
            order: 1,
            public: false,
          },
          {
            name: "Edificado",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "610c17c750a38100953b6462",
            order: 1,
            public: false,
          },
          {
            name: "Pessoal Não Docente",
            width: 800,
            height: 800,
            allowFullScreen: true,
            organization: $scope.selectedRow.id,
            category: "610c215e50a38100953b646b",
            order: 1,
            public: false,
          },
        ];
      }

      reportFactory.getByProperty("organization", $scope.selectedRow.id).then(function (reports) {
        if (reports != null && Array.isArray(reports) && reports.length > 0) {
          $scope.$parent.hideLoader();
          $mdToast.show(
            $mdToast
              .simple()
              .textContent("A organização já tem reports associados.")
              .position("top right")
              .hideDelay(5000)
              .theme("fail-toast")
          );
        } else {
          let newReportPromises = [];
          reportStructure.forEach((repStruct) => {
            newReportPromises.push(
              reportFactory.create(repStruct).then((rep) => {
                console.log("report inserted.");
              })
            );
            Promise.all(newReportPromises).then(function () {
              $scope.$parent.hideLoader();
            });
          });
        }
      });
    };

    function classPlanAddMissingProfessionalSubjects(missingSubjects) {
      let courseTypes = $scope.getFromTableData("Class_Plan_Course_Types");
      let schoolYears = $scope.getFromTableData("Class_Plan_School_Years");
      let professionalCourseTypeID = null;
      let schoolYear10ID = null;
      let schoolYear11ID = null;
      let schoolYear12ID = null;

      for (let a = 0; a < courseTypes.length; a++) {
        let cT = courseTypes[a];
        if (cT.courseType == "Profissional") {
          professionalCourseTypeID = cT.id;
          break;
        }
      }
      for (let b = 0; b < schoolYears.length; b++) {
        let sY = schoolYears[b];
        if (sY.schoolYear == 10) {
          schoolYear10ID = sY.id;
        } else if (sY.schoolYear == 11) {
          schoolYear11ID = sY.id;
        } else if (sY.schoolYear == 12) {
          schoolYear12ID = sY.id;
        }
      }

      if (professionalCourseTypeID && schoolYear10ID && schoolYear11ID && schoolYear12ID) {
        missingSubjects["Profissional" + 10 + "FCT"] = {
          courseType: professionalCourseTypeID,
          schoolYear: schoolYear10ID,
          subject: "FCT",
          organization: $scope.selectedRow.id,
          createdAt: Date.now(),
          createdBy: "admin",
          profSubFlag: true,
        };

        missingSubjects["Profissional" + 11 + "FCT"] = {
          courseType: professionalCourseTypeID,
          schoolYear: schoolYear11ID,
          subject: "FCT",
          organization: $scope.selectedRow.id,
          createdAt: Date.now(),
          createdBy: "admin",
          profSubFlag: true,
        };

        missingSubjects["Profissional" + 12 + "FCT"] = {
          courseType: professionalCourseTypeID,
          schoolYear: schoolYear12ID,
          subject: "FCT",
          organization: $scope.selectedRow.id,
          createdAt: Date.now(),
          createdBy: "admin",
          profSubFlag: true,
        };

        missingSubjects["Profissional" + 10 + "PAP"] = {
          courseType: professionalCourseTypeID,
          schoolYear: schoolYear10ID,
          subject: "PAP",
          organization: $scope.selectedRow.id,
          createdAt: Date.now(),
          createdBy: "admin",
          profSubFlag: true,
        };

        missingSubjects["Profissional" + 11 + "PAP"] = {
          courseType: professionalCourseTypeID,
          schoolYear: schoolYear11ID,
          subject: "PAP",
          organization: $scope.selectedRow.id,
          createdAt: Date.now(),
          createdBy: "admin",
          profSubFlag: true,
        };

        missingSubjects["Profissional" + 12 + "PAP"] = {
          courseType: professionalCourseTypeID,
          schoolYear: schoolYear12ID,
          subject: "PAP",
          organization: $scope.selectedRow.id,
          createdAt: Date.now(),
          createdBy: "admin",
          profSubFlag: true,
        };
      }
    }
  },
]);
