import { changeInterfaceByProduct } from "./productInterfaceStylizer";

var app = angular.module("analytics");

app.controller("PermissionsCtrl", [
  "$scope",
  "$mdPanel",
  "uiGridConstants",
  "i18nService",
  "categoryFactory",
  "organizationFactory",
  "reportFactory",
  "clientsFactory",
  "clientPreferencesFactory",
  "permissionsFactory",
  "moduleFactory",
  "roleMappingFactory",
  "exportUiGridService",
  "localizationFactory",
  "genericFactory",
  "$mdDialog",
  "$state",
  function (
    $scope,
    $mdPanel,
    uiGridConstants,
    i18nService,
    categoryFactory,
    organizationFactory,
    reportFactory,
    clientsFactory,
    clientPreferencesFactory,
    permissionsFactory,
    moduleFactory,
    roleMappingFactory,
    exportUiGridService,
    localizationFactory,
    genericFactory,
    $mdDialog,
    $state
  ) {
    var self = this;
    $scope.localizationFactory = localizationFactory;
    $scope.$mdDialog = $mdDialog;
    $scope.mode = "create";
    self.gotClientData = false;
    self.gotClientPreferencesData = false;
    self.gotReportData = false;
    self.gotModuleData = false;
    $scope.topFilters = {};
    $scope.topFilterData = {};
    $scope.topFilterData["Organização"] = organizationFactory.data.map((org) => (org = org.name));

    $scope.isAdmin = clientsFactory.isAdmin();
    $scope.isOrgAdmin = clientsFactory.isOrgAdmin();
    $scope.isCPAdmin = clientsFactory.isCPAdmin();
    $scope.isVFXEduAdmin = clientsFactory.isVFXEduAdmin();

    $scope.email = "";
    $scope.password = "";
    $scope.name = "";
    $scope.surname = "";
    $scope.organization = "";

    $scope.genericFactory = genericFactory;
    $scope.genericFactoryData = {};

    $scope.getCurrentLang = function () {
      let lang = getCookie("KSTKLang");
      if (lang == null || lang == "pt-PT") {
        return "pt";
      } else if (lang == "en-GB") {
        return "en";
      }
    };

    i18nService.setCurrentLang($scope.getCurrentLang());

    $scope.currentUser = clientsFactory.getUser().user;

    $scope.getOrgType = function (orgId) {
      var filteredOrg = organizationFactory.data.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].orgType;
      } else {
        return "";
      }
    };

    if ($scope.currentUser && $scope.currentUser.orgType) {
      changeInterfaceByProduct($scope.currentUser.orgType);
    }

    if (!$scope.isAdmin && !$scope.isOrgAdmin && !$scope.isCPAdmin && !$scope.isVFXEduAdmin) {
      $state.go("home");
    } else {
      clientsFactory.getAll().then(function (clientsData) {
        $scope.clientsData = clientsData;
        self.gotClientData = true;

        $scope.draw();
      });

      clientPreferencesFactory.getAll().then(function (clientPreferencesData) {
        $scope.clientPreferencesData = clientPreferencesData;
        self.gotClientPreferencesData = true;

        $scope.draw();
      });

      reportFactory.getAll().then(function (reportsData) {
        $scope.reportData = reportsData;
        self.gotReportData = true;

        $scope.draw();
      });

      moduleFactory.getAll().then(function (moduleData) {
        $scope.moduleData = moduleData;
        self.gotModuleData = true;

        $scope.draw();
      });
    }

    $scope.organizationData = organizationFactory.data;
    $scope.categoryData = categoryFactory.data;

    $scope.$parent.setTitle(localizationFactory.translateText("permissions"));

    $scope.$parent.log({
      type: "module view",
      details: "Permissões",
      date: new Date(),
    });

    $scope.$watch(
      "[topFilters]",
      function (filters) {
        if (
          !(Object.keys($scope.topFilters).length === 0 && $scope.topFilters.constructor === Object) &&
          $scope.gridApi.grid.columns.length > 0
        ) {
          let index = 4;
          if (!$scope.showTempPassFlag) {
            index = 4;
          } else {
            index = 5;
          }
          for (var filter in Object.values(filters)[0]) {
            if (Object.values(filters)[0][filter] === "Todos" || Object.values(filters)[0][filter] === "") {
              $scope.gridApi.grid.columns[index].filters[0].term = "";
            } else {
              $scope.gridApi.grid.columns[index].filters[0].term = Object.values(filters)[0][filter];
            }
          }
        }
      },
      true
    );

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.getOrgType = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].orgType;
      } else {
        return "";
      }
    };

    $scope.getModStructure = function () {
      if ($scope.moduleData) {
        let modStructure = {};
        $scope.moduleData
          .sort(function compareModules(a, b) {
            if (
              a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
              b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) {
              return -1;
            }

            if (
              a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
              b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) {
              return 1;
            }

            return 0;
          })
          .forEach((mod) => {
            if (mod.product != null && mod.category != null && !mod.disabled) {
              mod.product.forEach((product) => {
                if (modStructure[product] == null) {
                  modStructure[product] = {};
                }
                if (modStructure[product][mod.category] == null) {
                  modStructure[product][mod.category] = [];
                }
                modStructure[product][mod.category].push(mod);
              });
            } else {
              if (modStructure["other"] == null) {
                modStructure["other"] = [];
              }
              modStructure["other"].push(mod);
            }
          });
        return modStructure;
      }
    };

    $scope.draw = function () {
      if (self.gotClientData && self.gotClientPreferencesData && self.gotReportData && self.gotModuleData) {
        getPermissions();
      }
    };

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.getReportName = function (repId) {
      var filteredRep = $scope.reportData.filter((e) => e.id === repId);

      if (filteredRep.length) {
        return filteredRep[0].name;
      } else {
        return "";
      }
    };

    $scope.getModuleName = function (moduleId) {
      var filteredMod = $scope.moduleData.filter((e) => e.value === moduleId);

      if (filteredMod.length) {
        return filteredMod[0].name;
      } else {
        return "";
      }
    };

    $scope.getUserMail = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].email;
      } else {
        return "";
      }
    };

    $scope.getUserInstitutionalMail = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].institutionalMail;
      } else {
        return "";
      }
    };

    $scope.getUserWelcomeMessageDate = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].welcomeMessageDateMs;
      } else {
        return "";
      }
    };

    $scope.getUserHasToResetPasswordFlag = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].hasToResetPassword;
      } else {
        return "";
      }
    };

    $scope.getUserName = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        if (filteredUser[0].extendedName != null) {
          return filteredUser[0].extendedName;
        } else if (filteredUser[0].name != null && filteredUser[0].surname != null) {
          return filteredUser[0].name + " " + filteredUser[0].surname;
        } else if (filteredUser[0].name != null) {
          return filteredUser[0].name;
        }
      } else {
        return "";
      }
    };

    $scope.getUserTempPassword = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].tempPassword;
      } else {
        return "";
      }
    };

    $scope.getUserLoggedInFlag = function (userId) {
      let filteredUserPreference = $scope.clientPreferencesData.filter((cP) => cP.user === userId);

      if (filteredUserPreference.length) {
        return "Sim";
      } else {
        return "Não";
      }
    };

    $scope.getUserOrganization = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].organization;
      } else {
        return "";
      }
    };

    $scope.getUserKSTKFlag = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        return filteredUser[0].isKSTK;
      } else {
        return "";
      }
    };

    $scope.getUserProfile = function (userId, userOrgType) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);
      if (filteredUser.length) {
        let profile = filteredUser[0].profile;
        let kstkUserFlag = filteredUser[0].isKSTK;
        if (kstkUserFlag) {
          return "KSTK";
        } else if (profile == "userAnalytics") {
          return "Utilizador Analytics";
        } else if (userOrgType == "Education") {
          if ($scope.isCPAdmin) {
            switch (profile) {
              case "user":
                return "Professor";
              case "cpClassDirector":
                return "Diretor de Turma";
              case "cpSupervisor":
                return "Coordenador";
              case "cpAdmin":
                return "Admin PT";
              default:
                return "Professor";
            }
          } else {
            switch (profile) {
              case "user":
                return localizationFactory.translateText("userProfile");
              case "cpClassDirector":
                return "Diretor de Turma";
              case "cpSupervisor":
                return "Coordenador";
              case "cpAdmin":
                return "Admin PT";
              case "vfxEduAdmin":
                return "Admin Edu VFX";
              case "orgAdmin":
                return "Admin Org";
              default:
                return localizationFactory.translateText("userProfile");
            }
          }
        } else if (profile == "orgAdmin") {
          return "Admin Org";
        } else {
          return localizationFactory.translateText("userProfile");
        }
      } else {
        return "";
      }
    };

    // Lean Game

    $scope.getUserLeanGameSession = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        if (filteredUser[0].game != null) {
          let filteredGame = $scope.genericFactoryData["game"].filter((el) => el.id == filteredUser[0].game);
          if (filteredGame != null && filteredGame.length > 0) {
            return filteredGame[0].game;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    $scope.getUserLeanGameRole = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        if (filteredUser[0].role != null) {
          return $scope.genericFactoryData["role"].filter((el) => el.id == filteredUser[0].role)[0].role;
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    $scope.getUserLeanGameTeam = function (userId) {
      var filteredUser = $scope.clientsData.filter((e) => e.id === userId);

      if (filteredUser.length) {
        if (filteredUser[0].team != null) {
          return filteredUser[0].team;
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    var columns = [
      {
        field: "userMail",
        enableCellEdit: true,
        displayName: "E-mail",
      },
      {
        field: "name",
        enableCellEdit: true,
        displayName: localizationFactory.translateText("name"),
        sort: {
          direction: uiGridConstants.ASC,
          ignoreSort: false,
          priority: 0,
        },
      },
      {
        field: "profile",
        enableCellEdit: false,
        displayName: localizationFactory.translateText("profile"),
      },
      {
        field: "orgName",
        enableCellEdit: false,
        displayName: localizationFactory.translateText("organization"),
        filter: {
          condition: function (searchTerm, cellValue, row, column) {
            if (cellValue != null || cellValue != undefined) {
              var re = new RegExp("^" + searchTerm + "$", "gi");
              return cellValue.match(re);
            }
            return null;
          },
        },
      },
      {
        field: "reportsName",
        enableCellEdit: false,
        displayName: localizationFactory.translateText("reports"),
      },
      {
        field: "modulesName",
        enableCellEdit: false,
        displayName: localizationFactory.translateText("modules"),
      },
    ];

    if ($scope.isAdmin || $scope.isCPAdmin) {
      columns = columns.concat([
        {
          field: "userHasLoggedInFlag",
          enableCellEdit: false,
          displayName: localizationFactory.translateText("hasLoggedIn"),
        },
      ]);
    }

    // Lean Game columns
    if ($scope.currentUser.organization != null && $scope.getOrgName($scope.currentUser.organization) == "Lean Game") {
      $scope.genericFactory.setRouteName("Lean_Game_Configurations");
      $scope.genericFactory
        .getByProperty("organization", $scope.currentUser.organization)
        .then(function (gameConfigurations) {
          $scope.genericFactoryData["game"] = gameConfigurations;
          $scope.genericFactory.setRouteName("Lean_Game_Roles");
          $scope.genericFactory.getByProperty("organization", $scope.currentUser.organization).then(function (roles) {
            $scope.genericFactoryData["role"] = roles;
          });
        });

      columns = columns.concat([
        {
          field: "game",
          enableCellEdit: false,
          displayName: "Game Session",
        },
        {
          field: "role",
          enableCellEdit: false,
          displayName: "Role",
        },
        {
          field: "team",
          enableCellEdit: false,
          displayName: "Team",
        },
      ]);
    }

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      flatEntityAccess: true,
      exporterMenuPdf: false,
      exporterMenuCsv: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: localizationFactory.translateText("gridMenuExportAllExcel"),
          action: function ($event) {
            exportUiGridService.exportToExcel(
              "sheet 1",
              $scope.gridApi,
              "all",
              "all",
              localizationFactory.translateText("records")
            );
          },
          order: 110,
        },
        {
          title: localizationFactory.translateText("gridMenuExportVisibleExcel"),
          action: function ($event) {
            exportUiGridService.exportToExcel(
              "sheet 1",
              $scope.gridApi,
              "visible",
              "visible",
              localizationFactory.translateText("records")
            );
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    $scope.showTempPassFlag = false;

    $scope.showTempCPPass = function () {
      let columns;
      if (!$scope.showTempPassFlag) {
        $scope.$parent.alertShowTempCPPasswords();
        $scope.showTempPassFlag = true;
        columns = [
          {
            field: "userMail",
            displayName: "E-mail",
            enableCellEdit: true,
          },
          {
            field: "tempPassword",
            displayName: localizationFactory.translateText("temporaryPassword"),
            enableCellEdit: true,
          },
          {
            field: "name",
            displayName: localizationFactory.translateText("name"),
            enableCellEdit: true,
            sort: {
              direction: uiGridConstants.ASC,
              ignoreSort: false,
              priority: 0,
            },
          },
          {
            field: "profile",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("profile"),
          },
          {
            field: "orgName",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("organization"),
            filter: {
              condition: function (searchTerm, cellValue, row, column) {
                if (cellValue != null || cellValue != undefined) {
                  var re = new RegExp("^" + searchTerm + "$", "gi");
                  return cellValue.match(re);
                }
                return null;
              },
            },
          },
          {
            field: "reportsName",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("reports"),
          },
          {
            field: "modulesName",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("modules"),
          },
          {
            field: "userHasLoggedInFlag",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("hasLoggedIn"),
          },
        ];
      } else {
        $scope.showTempPassFlag = false;
        columns = [
          {
            field: "userMail",
            enableCellEdit: true,
            displayName: "E-mail",
          },
          {
            field: "name",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("name"),
            sort: {
              direction: uiGridConstants.ASC,
              ignoreSort: false,
              priority: 0,
            },
          },
          {
            field: "profile",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("profile"),
          },
          {
            field: "orgName",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("organization"),
            filter: {
              condition: function (searchTerm, cellValue, row, column) {
                if (cellValue != null || cellValue != undefined) {
                  var re = new RegExp("^" + searchTerm + "$", "gi");
                  return cellValue.match(re);
                }
                return null;
              },
            },
          },
          {
            field: "reportsName",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("reports"),
          },
          {
            field: "modulesName",
            enableCellEdit: false,
            displayName: localizationFactory.translateText("modules"),
          },
        ];
        if ($scope.isAdmin || $scope.isCPAdmin) {
          columns = columns.concat([
            {
              field: "userHasLoggedInFlag",
              enableCellEdit: false,
              displayName: localizationFactory.translateText("hasLoggedIn"),
            },
          ]);
        }
      }
      getPermissions();

      $scope.gridOptions.columnDefs = columns;

      if ($scope.gridApi) {
        $scope.gridApi.grid.refreshCanvas();
      }
    };

    permissionsFactory.setParentScope($scope);

    $scope.clearForm = function () {
      // permissionsFactory.setSelected({});
      $scope.gridApi.selection.clearSelectedRows();
      $scope.userReports = null;
      $scope.selectedRow = null;
      $scope.userModules = null;
      permissionsFactory.setSelected(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          permissionsFactory.setSelected(row.entity);
          $scope.selectedRowOrganization = $scope.clientsData.filter(function (el) {
            return el.id === $scope.selectedRow.user;
          })[0].organization;

          //Reports
          $scope.userReports = {};

          $scope.reportData.forEach(function (currRep) {
            if (currRep.organization === $scope.selectedRowOrganization) {
              $scope.userReports[currRep.id] = false;
            }
          });

          row.entity.reports.forEach(function (currRep) {
            $scope.userReports[currRep] = true;
          });

          //Modules
          $scope.userModules = {};

          $scope.moduleData.forEach(function (currModule) {
            $scope.userModules[currModule.value] = false;
          });

          row.entity.modules.forEach(function (currModule) {
            $scope.userModules[currModule] = true;
          });
        } else {
          $scope.selectedRow = null;
          $scope.userReports = {};
          permissionsFactory.setSelected(null);
        }
      });

      gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
        rowEntity[colDef.name] = oldValue;
      });
    };

    function filterPermissionsByUser(perm, users) {
      return perm.filter((p) => {
        let permUserInUsers = users.filter((u) => u.id === p.user);

        return permUserInUsers.length;
      });
    }

    function getPermissions() {
      let userOrg = clientsFactory.getUser().user.organization;

      clientsFactory.getAll().then(function (clientsData) {
        if (clientsFactory.isOrgAdmin() || clientsFactory.isCPAdmin() || $scope.isVFXEduAdmin) {
          $scope.clientsData = clientsData.filter((c) => c.organization === userOrg);
        } else {
          $scope.clientsData = clientsData;
        }

        permissionsFactory.getAll().then(function (data) {
          var tempData = [];
          let permissionsExceptKSTKUsers = [];
          if (clientsFactory.isOrgAdmin() || clientsFactory.isCPAdmin() || $scope.isVFXEduAdmin) {
            permissionsExceptKSTKUsers = data.filter(
              (c) => $scope.getUserKSTKFlag(c.user) == null || $scope.getUserKSTKFlag(c.user) == false
            );
            angular.copy(permissionsExceptKSTKUsers, tempData);
          } else {
            angular.copy(data, tempData);
          }
          if (clientsFactory.isOrgAdmin() || clientsFactory.isCPAdmin() || $scope.isVFXEduAdmin) {
            tempData = filterPermissionsByUser(tempData, $scope.clientsData);
          }
          tempData.forEach((el) => (el.reportsName = []));
          tempData.forEach((el) => (el.modulesName = []));

          tempData.forEach(function (currPerm) {
            currPerm.userMail = $scope.getUserMail(currPerm.user);
            currPerm.userInstitutionalMail = $scope.getUserInstitutionalMail(currPerm.user);
            currPerm.welcomeMessageDateMs = $scope.getUserWelcomeMessageDate(currPerm.user);
            currPerm.name = $scope.getUserName(currPerm.user);

            let userOrg = $scope.getUserOrganization(currPerm.user);
            let userOrgType = $scope.getOrgType(userOrg);
            currPerm.orgName = $scope.getOrgName(userOrg);
            currPerm.profile = $scope.getUserProfile(currPerm.user, userOrgType);

            if ($scope.isAdmin || $scope.isCPAdmin) {
              currPerm.tempPassword = $scope.getUserTempPassword(currPerm.user);
              currPerm.userHasLoggedInFlag = $scope.getUserLoggedInFlag(currPerm.user);
            }
            currPerm.reportsName = [];
            currPerm.reports.forEach(function (currRep) {
              currPerm.reportsName.push($scope.getReportName(currRep));
            });
            currPerm.reportsName = currPerm.reportsName.toString();

            currPerm.modulesName = [];
            currPerm.modules.forEach(function (currMod) {
              let modName = $scope.getModuleName(currMod);
              if (modName != null && modName != "") {
                currPerm.modulesName.push(modName);
              }
            });
            currPerm.modulesName = currPerm.modulesName.toString();

            // Lean Game columns
            if ($scope.getOrgName($scope.currentUser.organization) == "Lean Game") {
              currPerm.game = $scope.getUserLeanGameSession(currPerm.user);
              currPerm.role = $scope.getUserLeanGameRole(currPerm.user);
              currPerm.team = $scope.getUserLeanGameTeam(currPerm.user);
            }
          });

          $scope.gridOptions.data = tempData;
        });
      });
    }

    // getPermissions();

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "PermissionsPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/permissionsPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: false,
        zIndex: 1,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getPermissions();
        },
        locals: {
          permissionScope: $scope,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      if ($scope.panelRef) $scope.panelRef.close();
    };
    $scope.$on("$destroy", function () {
      $scope.closePanel();
    });

    $scope.create = function (event) {
      $scope.clearForm();
      $scope.mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      $scope.mode = "modify";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      var confirm = $mdDialog
        .confirm()
        .title($scope.translateText("deleteRecordDialog"))
        .ariaLabel("Confirmar eliminação")
        .ok($scope.translateText("yes"))
        .cancel($scope.translateText("no"));

      $mdDialog.show(confirm).then(
        function () {
          $scope.$parent.showLoader();
          clientsFactory.delete($scope.selectedRow.user).then(function () {
            permissionsFactory.delete($scope.selectedRow.id).then(function () {
              roleMappingFactory.getByProp("principalId", $scope.selectedRow.user).then(function (mappings) {
                if (mappings.length) {
                  roleMappingFactory.delete(mappings[0].id);
                }
              });
              $scope.$parent.hideLoader();
              getPermissions();
            });
          });
        },
        function () {
          return;
        }
      );
    };

    $scope.welcomeNewClient = function (info) {
      if (info.userInstitutionalMail != null && info.userInstitutionalMail != "") {
        /* if (info.userHasLoggedInFlag == "Sim") {
          $scope.$parent.alertUserHasNoNeedToChangePassword();
          return;
        } */
        info.email = info.userInstitutionalMail;
        info.welcomeNewClientEmailSubject = localizationFactory.translateText("welcomeNewClientEmailSubject");
        info.welcomeNewClientEmailDesc1 = localizationFactory.translateText("welcomeNewClientEmailDesc1");
        info.welcomeNewClientEmailDesc2 = localizationFactory.translateText("welcomeNewClientEmailDesc2");
        if (info.welcomeMessageDateMs) {
          var confirm = $mdDialog
            .confirm()
            .title(localizationFactory.translateText("credentialsAlreadySentDialogHeader"))
            .textContent(
              localizationFactory.translateText("credentialsAlreadySentDialogDesc1") +
                " " +
                new Date(info.welcomeMessageDateMs).toLocaleString() +
                ". " +
                localizationFactory.translateText("credentialsAlreadySentDialogDesc2")
            )
            .ariaLabel("Credenciais já enviadas")
            .ok(localizationFactory.translateText("yes"))
            .cancel(localizationFactory.translateText("no"));

          $mdDialog.show(confirm).then(
            function () {
              sendWelcomeMessage(info);
            },
            function () {
              return;
            }
          );
        } else {
          sendWelcomeMessage(info);
        }
      } else {
        $scope.$parent.alertUserHasNoInstitutionalMail();
      }

      function sendWelcomeMessage(info) {
        return clientsFactory
          .welcomeNewClient(info)
          .then(function () {
            $scope.$parent.welcomeMessageSentSuccessFully();
            getPermissions();
            return;
          })
          .catch(function () {
            $scope.$parent.welcomeMessageNotSent();
            getPermissions();
            return;
          });
      }
    };
  },
]);
