import React from "react";

export class KSTKAnalyticsButton extends React.Component {
  constructor(props) {
    super(props);
  }
  handleClickFunction(e, targetElement, clickFunctionArgs) {
    if (this.props.clickFunction) {
      this.props.clickFunction(e, targetElement, clickFunctionArgs);
    }
  }
  handleDisabledFunction() {
    if (this.props.disabledFunction) {
      return this.props.disabledFunction();
    }
  }
  render() {
    return (
      <button
        title={this.props.title}
        form={this.props.form}
        className={this.props.action}
        type={this.props.type}
        value={this.props.value}
        disabled={this.handleDisabledFunction()}
        onClick={(e) => this.handleClickFunction(e, this.props.targetElement, this.props.clickFunctionArgs)}
      >
        {this.props.text}
        {this.props.icon ? (
          <div className="kstkButtonIcon">
            <ion-icon className="kstkButtonIcon" name={this.props.icon}></ion-icon>
          </div>
        ) : null}
      </button>
    );
  }
}
