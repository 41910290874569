import React from "react";

export class KSTKAnalyticsTag extends React.Component {
  constructor(props) {
    super(props);
  }
  getBackgroundColor(text) {
    if (text) {
      switch (text) {
        case "Novo":
          return "rgb(58 191 124 / 20%)";
        case "New":
          return "rgb(58 191 124 / 20%)";
        case "Atualização":
          return "rgb(221 234 255)";
        case "Update":
          return "rgb(221 234 255)";
        case "Aviso":
          return "rgb(255 203 61 / 20%)";
        case "Warning":
          return "rgb(255 203 61 / 20%)";
        default:
          break;
      }
    }
  }
  getTextColor(text) {
    if (text) {
      switch (text) {
        case "Novo":
          return "#36BC79";
        case "New":
          return "#36BC79";
        case "Atualização":
          return "rgb(70 143 251)";
        case "Update":
          return "rgb(70 143 251)";
        case "Aviso":
          return "#D89C00";
        case "Warning":
          return "#D89C00";
        default:
          break;
      }
    }
  }
  render() {
    var getBackgroundColorFunction = this.getBackgroundColor;
    var getTextColorFunction = this.getTextColor;
    return (
      <div
        class="kstkAnalyticsTag"
        style={{
          backgroundColor: getBackgroundColorFunction(this.props.text),
          color: getTextColorFunction(this.props.text),
        }}
      >
        {this.props.text.toUpperCase()}
      </div>
    );
  }
}
