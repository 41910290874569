import React from "react";
import KSTKAnalyticsSmartContentsList from "./KSTKAnalyticsSmartContentsList";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";

export class KSTKAnalyticsSmartContents extends React.Component {
  constructor(props) {
    super(props);
    this.openCloseSmartContents = this.openCloseSmartContents.bind(this);
    this.state = {};
  }

  componentDidMount() {
    if (window.location.hash.indexOf("#!/home") != -1) {
      this.setState(
        Object.assign({}, this.state, {
          atHomepage: true,
        })
      );
    } else {
      this.setState(
        Object.assign({}, this.state, {
          atHomepage: false,
        })
      );
    }
  }

  componentWillUpdate(nextProps) {
    if (this.state.openSmartContents != nextProps.openSmartContents && nextProps.openSmartContents != null) {
      this.setState(
        Object.assign({}, this.state, {
          openSmartContents: nextProps.openSmartContents,
        })
      );
    }

    if (this.state.atHomepage != nextProps.atHomepage && nextProps.atHomepage != null) {
      this.setState(
        Object.assign({}, this.state, {
          atHomepage: nextProps.atHomepage,
        })
      );
    }
  }

  openCloseSmartContents() {
    this.setState(
      Object.assign({}, this.state, {
        openSmartContents: false,
      })
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.smartContents &&
        this.props.smartContents.length > 0 &&
        (this.state.atHomepage == true || this.state.openSmartContents == true) ? (
          <div className="kstkAnalyticsSmartContentsWrapper">
            {/* 
          <div className="kstkAnalyticsSmartContentsHeader">
            <h3>Guias de Apoio & Notificações</h3>
          </div> 
          */}
            <React.Fragment>
              {/* {this.state.atHomepage == false ? (
                <div className="kstkAnalyticsSmartContentsClose">
                  <KSTKAnalyticsButton action="kstkSmallTertiaryButton" icon="close-outline" clickFunction={this.openCloseSmartContents} />
                </div>
              ) : null} */}

              <div className="kstkAnalyticsSmartContents">
                {(this.props.smartContentsMode == null || this.props.smartContentsMode == "guides") &&
                this.props.smartContents.filter((smartContent) => smartContent.type == "Guia").length > 0 ? (
                  <React.Fragment>
                    <div className="kstkAnalyticsSmartContentsTitle">
                      <h3>{this.props.translateText("smartContentsGuides")}</h3>
                    </div>
                    <KSTKAnalyticsSmartContentsList
                      list={this.props.smartContents.filter((smartContent) => smartContent.type == "Guia")}
                      downloadSmartContentsAttachedFiles={this.props.downloadSmartContentsAttachedFiles}
                    />
                  </React.Fragment>
                ) : null}
                {(this.props.smartContentsMode == null || this.props.smartContentsMode == "notifications") &&
                this.props.smartContents.filter((smartContent) => smartContent.type == "Notificação").length > 0 ? (
                  <React.Fragment>
                    <div className="kstkAnalyticsSmartContentsTitle">
                      <h3>{this.props.translateText("smartContentsNotifications")}</h3>
                    </div>
                    <KSTKAnalyticsSmartContentsList
                      list={this.props.smartContents.filter((smartContent) => smartContent.type == "Notificação")}
                      translateText={this.props.translateText}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </React.Fragment>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
