"use strict";

var app = angular.module("analytics");

app.controller("PolicyDialogCtrl", [
  "$scope",
  "$mdDialog",
  "clientsFactory",
  "$state",
  "localizationFactory",
  function ($scope, $mdDialog, clientsFactory, $state, localizationFactory) {
    $scope.localizationFactory = localizationFactory;
    $scope.policyCheckbox = null;
    $scope.closePolicyDialog = function () {
      $mdDialog.hide();
      $scope.currentUser = clientsFactory.getUser().user;
      $scope.currentUser.privPolicyTermsUseFlag = true;
      clientsFactory.updateUsr($scope.currentUser);
      clientsFactory.modify($scope.currentUser.id, $scope.currentUser).then(function () {});
      if ($scope.currentUser.hasToResetPassword) {
        $state.go("client");
      }
    };
  },
]);
