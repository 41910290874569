import React, { useState } from "react";
import { KSTKAnalyticsTag } from "./KSTKAnalyticsTag";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";
import KSTKAnalyticsPopUp from "./KSTKAnalyticsPopUp";

const KSTKAnalyticsSmartContentsList = ({ list, downloadSmartContentsAttachedFiles, translateText }) => {
  const [openPopUps, setOpenPopUps] = useState([]);
  const [openNotification, setOpenNotification] = useState(null);
  const [openGuide, setOpenGuide] = useState(null);

  const openClosePopUps = (e, id) => {
    e.stopPropagation();
    const newPopUpsArray = [...openPopUps];
    if (openPopUps.indexOf(id) == -1) {
      newPopUpsArray.push(id);
    } else {
      newPopUpsArray.splice(newPopUpsArray.indexOf(id), 1);
    }
    setOpenPopUps(newPopUpsArray);
  };

  const openCloseNotification = (e, id) => {
    e.stopPropagation();
    if (openNotification == id) {
      setOpenNotification(null);
    } else {
      setOpenNotification(id);
    }
  };

  const openCloseGuide = (e, id) => {
    e.stopPropagation();
    if (openGuide == id) {
      setOpenGuide(null);
    } else {
      setOpenGuide(id);
    }
  };

  return (
    <div class="kstkAnalyticsSmartContentsColumn">
      {list &&
        list.length > 0 &&
        Object.values(list)
          .sort(function compareSmartContents(a, b) {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return 0;
          })
          .filter((smartContent) => smartContent.type == "Guia")
          .slice(0, 15)
          .map((smartContent) => (
            <React.Fragment>
              <div className="kstkAnalyticsSmartContentWrapper">
                <div className="kstkAnalyticsSmartContent">
                  <div className="kstkAnalyticsSmartContentHeader">
                    <div className="kstkAnalytiscSmartContentTitle">{smartContent.smartContentName}</div>
                    {smartContent.uploadedFiles != null && smartContent.uploadedFiles.length > 0 ? (
                      <React.Fragment>
                        <KSTKAnalyticsButton
                          action="kstkSmallSecondaryIconButton"
                          icon="download-outline"
                          title="Descarregar ficheiro(os)"
                          targetElement={smartContent.id}
                          clickFunction={downloadSmartContentsAttachedFiles}
                        />
                      </React.Fragment>
                    ) : null}
                    {smartContent.smartContentLink != null && smartContent.smartContentLink != "" ? (
                      <React.Fragment>
                        <a target="_blank" rel="noopener noreferrer" href={smartContent.smartContentLink}>
                          <KSTKAnalyticsButton
                            action="kstkSmallSecondaryIconButton"
                            icon="open-outline"
                            title="Abrir link"
                          />
                        </a>
                      </React.Fragment>
                    ) : null}
                    {smartContent.guideVideo != null && smartContent.guideVideo != "" ? (
                      <React.Fragment>
                        <KSTKAnalyticsButton
                          action="kstkSmallSecondaryIconButton"
                          icon="videocam-outline"
                          targetElement={smartContent.id + "video"}
                          clickFunction={openClosePopUps}
                          title="Abrir vídeo"
                        />
                        {openPopUps && openPopUps.indexOf(smartContent.id + "video") != -1 ? (
                          <KSTKAnalyticsPopUp
                            id={smartContent.id + "video"}
                            togglePopUp={openClosePopUps}
                            popUpInfo={smartContent}
                            link="https://www.youtube.com/watch?v=4ZA3pJAukJ4&ab_channel=HimnosFutbolChile"
                          />
                        ) : null}
                      </React.Fragment>
                    ) : null}
                    {smartContent.guide != null &&
                    Array.isArray(smartContent.guide) &&
                    smartContent.guide.length > 0 ? (
                      openGuide == smartContent.id + "guide" ? (
                        <KSTKAnalyticsButton
                          action="kstkSmallSecondaryIconButton"
                          icon="chevron-up"
                          targetElement={smartContent.id + "guide"}
                          clickFunction={openCloseGuide}
                          title="Ver passos de guia"
                        />
                      ) : (
                        <KSTKAnalyticsButton
                          action="kstkSmallSecondaryIconButton"
                          icon="chevron-down"
                          targetElement={smartContent.id + "guide"}
                          clickFunction={openCloseGuide}
                          title="Fechar passos de guia"
                        />
                      )
                    ) : null}
                  </div>
                  {/* <div className="kstkAnalyticsSmartContentCreationDate">
                    {new Date(smartContent.createdAt).toLocaleDateString()}
                  </div> */}
                  {smartContent.guide != null && openGuide == smartContent.id + "guide" ? (
                    <div className="kstkAnalyticsSmartContentGuideStepsWrapper" id={smartContent.id + "guide"}>
                      {smartContent.guide.map((step) => (
                        <div className="kstkAnalyticsSmartContentGuideStep">
                          <div className="kstkAnalyticsSmartContentGuideStepNumber">
                            <b>{step.step}º - </b>
                          </div>
                          {step.stepDesc}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          ))}
      {list &&
        list.length > 0 &&
        Object.values(list)
          .sort(function compareSmartContents(a, b) {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return 0;
          })
          // Get only notifications from 3 months ago
          .filter((smartContent) => smartContent.createdAt > new Date().getTime() - 7889400000)
          .filter((smartContent) => smartContent.type == "Notificação")
          .slice(0, 4)
          .map((smartContent) => (
            <React.Fragment>
              <div className="kstkAnalyticsSmartContentWrapper">
                <div className="kstkAnalyticsSmartContent">
                  <div className="kstkAnalyticsSmartContentHeader">
                    {smartContent.notificationType ? (
                      <KSTKAnalyticsTag text={translateText(smartContent.notificationType)} />
                    ) : null}
                    <div className="kstkAnalytiscSmartContentTitle">{smartContent.smartContentName}</div>
                    {smartContent.smartContentLink != null && smartContent.smartContentLink != "" ? (
                      <React.Fragment>
                        <a target="_blank" rel="noopener noreferrer" href={smartContent.smartContentLink}>
                          <KSTKAnalyticsButton
                            action="kstkSmallSecondaryIconButton"
                            icon="open-outline"
                            title="Abrir link"
                          />
                        </a>
                      </React.Fragment>
                    ) : null}
                    {smartContent.notificationText != null ? (
                      openNotification == smartContent.id + "notificationText" ? (
                        <KSTKAnalyticsButton
                          action="kstkSmallSecondaryIconButton"
                          icon="chevron-up"
                          targetElement={smartContent.id + "notificationText"}
                          clickFunction={openCloseNotification}
                          title="Fechar notificação"
                        />
                      ) : (
                        <KSTKAnalyticsButton
                          action="kstkSmallSecondaryIconButton"
                          icon="chevron-down"
                          targetElement={smartContent.id + "notificationText"}
                          clickFunction={openCloseNotification}
                          title="Ver notificação"
                        />
                      )
                    ) : null}
                  </div>
                  <div className="kstkAnalyticsSmartContentCreationDate">
                    {new Date(smartContent.createdAt).toLocaleDateString()}
                  </div>
                  {smartContent.notificationText != null && openNotification == smartContent.id + "notificationText" ? (
                    <div className="kstkAnalyticsSmartContentText" id={smartContent.id + "notificationText"}>
                      {smartContent.notificationText}
                    </div>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          ))}
    </div>
  );
};

export default KSTKAnalyticsSmartContentsList;
