import React, { useState, useEffect } from "react";
import KSTKAnalyticsInput from "./KSTKAnalyticsInput";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";

const KSTKAnalyticsClient = ({ currentUser, updateUser, translateText }) => {
  const [userInfo, setUserInfo] = useState({ password: null, passwordRepeat: null });
  const [passwordCheckMissingParametersByField, setPasswordCheckMissingParametersByField] = useState({
    password: null,
    passwordRepeat: null,
  });

  const updateUserInfo = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const isClientFormUpdated = () => {
    return (
      userInfo.clientName == null ||
      userInfo.clientSurname == null ||
      userInfo.clientName == "" ||
      userInfo.clientSurname == ""
    );
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    if (
      userInfo.password != null &&
      userInfo.password != "" &&
      userInfo.passwordRepeat != null &&
      userInfo.passwordRepeat != ""
    ) {
      if (passwordCheck(userInfo.password, "password") && passwordCheck(userInfo.passwordRepeat, "passwordRepeat")) {
        return updateUser(userInfo);
      }
    } else {
      return updateUser(userInfo);
    }
  };

  const passwordCheck = (password, passwordField) => {
    let passwordCheckFlag = true;
    let passwordCheckMissingParameters = [];

    if (password) {
      // Validate lower case letters
      var lowerCaseLetters = /[a-z]/g;
      if (!password.match(lowerCaseLetters)) {
        passwordCheckFlag = false;
        passwordCheckMissingParameters.push(translateText("atLeastALowerCaseCharacter"));
      }

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if (!password.match(upperCaseLetters)) {
        passwordCheckFlag = false;
        passwordCheckMissingParameters.push(translateText("atLeastAnUpperCaseCharacter"));
      }

      // Validate special characters
      var specialCharacters = /[*.!@#$%^&(){}[\]:;<>,.?\/~_\+\-=|]/g;
      if (!password.match(specialCharacters)) {
        passwordCheckFlag = false;
        passwordCheckMissingParameters.push(translateText("atLeastASpecialCharacter"));
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if (!password.match(numbers)) {
        passwordCheckFlag = false;
        passwordCheckMissingParameters.push(translateText("atLeastANumber"));
      }

      // Validate length
      if (password.length < 8 || password.length > 32) {
        passwordCheckFlag = false;
        passwordCheckMissingParameters.push(translateText("between8And32Characters"));
      }

      // Validate both passwords
      if (userInfo.password !== userInfo.passwordRepeat) {
        passwordCheckFlag = false;
      }

      if (passwordCheckMissingParametersByField[passwordField] != passwordCheckMissingParameters.toString()) {
        setPasswordCheckMissingParametersByField((prevState) => ({
          ...prevState,
          [passwordField]: passwordCheckMissingParameters.toString(),
        }));
      }

      return passwordCheckFlag;
    } else {
      return false;
    }
  };

  const passwordCheckIcon = (passwordField) => {
    if (passwordCheck(userInfo[passwordField], passwordField)) {
      return "checkmark-outline";
    } else {
      return "close-outline";
    }
  };

  const passwordCheckIconStyle = (passwordField) => {
    if (passwordCheck(userInfo[passwordField], passwordField)) {
      return { color: "green" };
    } else {
      return { color: "red" };
    }
  };

  useEffect(() => {
    setUserInfo((prevState) => ({ ...prevState, ["clientName"]: currentUser.name }));
    setUserInfo((prevState) => ({ ...prevState, ["clientSurname"]: currentUser.surname }));
    setUserInfo((prevState) => ({ ...prevState, ["email"]: currentUser.email }));

    return () => {};
  }, []);

  return (
    <div className="kstkAnalyticsClientWrapper">
      <div className="kstkAnalyticsClientModalWrapper">
        <div className="kstkAnalyticsClientFormWrapper">
          {/* <div className="kstkAnalyticsClientIcon">
            <ion-icon name="person-circle-outline"></ion-icon>
          </div> */}
          <h1>{translateText("settings")}</h1>
          <div className="kstkAnalyticsClientForm">
            <form onSubmit={handleUpdateUser}>
              <KSTKAnalyticsInput
                autocomplete="username"
                label="E-mail"
                type="text"
                name="e-mail"
                value={userInfo.email}
                disabled="true"
              />
              <KSTKAnalyticsInput
                autocomplete="new-password"
                id="password"
                label={translateText("newPassword")}
                type="password"
                name="password"
                value={userInfo.password}
                onInputChange={updateUserInfo}
                inputAfterIconFunction={passwordCheckIcon}
                inputAfterIconStyleFunction={passwordCheckIconStyle}
              />
              <div className="kstkAnalyticsClientPasswordMissingParameters">
                {userInfo.password != null &&
                userInfo.password != "" &&
                passwordCheckMissingParametersByField.password != null &&
                passwordCheckMissingParametersByField.password.length > 0
                  ? translateText("thePasswordShouldContain") +
                    " " +
                    passwordCheckMissingParametersByField.password.replace(/,/g, ", ") +
                    "."
                  : null}
              </div>
              <KSTKAnalyticsInput
                autocomplete="new-password"
                id="passwordRepeat"
                label={translateText("confirmPassword")}
                type="password"
                name="passwordRepeat"
                value={userInfo.passwordRepeat}
                onInputChange={updateUserInfo}
                inputAfterIconFunction={passwordCheckIcon}
                inputAfterIconStyleFunction={passwordCheckIconStyle}
              />
              <div className="kstkAnalyticsClientPasswordMissingParameters">
                {userInfo.passwordRepeat != null &&
                userInfo.passwordRepeat != "" &&
                passwordCheckMissingParametersByField.passwordRepeat != null &&
                passwordCheckMissingParametersByField.passwordRepeat.length > 0
                  ? "A password deve conter: " +
                    passwordCheckMissingParametersByField.passwordRepeat.replace(/,/g, ", ") +
                    "."
                  : null}
              </div>
              <div className="kstkAnalyticsClientPasswordMissingParameters">
                {((userInfo.password != null && userInfo.password != "") ||
                  (userInfo.passwordRepeat != null && userInfo.passwordRepeat != "")) &&
                userInfo.password !== userInfo.passwordRepeat
                  ? translateText("differentPasswords")
                  : null}
              </div>
              <KSTKAnalyticsInput
                label={translateText("name")}
                type="text"
                name="clientName"
                value={userInfo.clientName}
                onInputChange={updateUserInfo}
              />
              <KSTKAnalyticsInput
                label={translateText("surname")}
                type="text"
                name="clientSurname"
                value={userInfo.clientSurname}
                onInputChange={updateUserInfo}
              />
              <div className="kstkAnalyticsClientFormButtons">
                <KSTKAnalyticsButton
                  action="kstkSmallPrimaryButton"
                  type="submit"
                  text={translateText("save")}
                  disabledFunction={isClientFormUpdated}
                  clickFunction={null}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KSTKAnalyticsClient;
