"use strict";

var app = angular.module("analytics");

app.controller("MyFilesPanelCtrl", [
  "$scope",
  "filesScope",
  "storageFactory",
  "clientsFactory",
  "$mdDialog",
  "usedSpace",
  "myFiles",
  function (
    $scope,
    filesScope,
    storageFactory,
    clientsFactory,
    $mdDialog,
    usedSpace,
    myFiles
  ) {
    $scope.filesScope = filesScope;
    $scope.uploadedFiles = myFiles;
    $scope.filesToUpload = [];
    $scope.usedSpace = usedSpace;
    $scope.toUploadFilesSize = 0;
    $scope.parsedToUplaodFilesSize = "";
    $scope.storageOverflow = false;
    $scope.invalidNames = false;
    $scope.errorMessage = "";

    $scope.orgID = clientsFactory.getUser().user.organization;
    $scope.orgContainer;

    storageFactory
      .getContainer($scope.orgID)
      .then((container) => {
        $scope.orgContainer = container;
      })
      .catch((response) => {
        if (response.status === 404) {
          storageFactory.createContainer($scope.orgID).then((created) => {
            $scope.orgContainer = created;
          });
        }
      });

    $scope.save = function () {
      /* if(!$scope.checkFileNames($scope.filesToUpload)) {
        return;
      } */

      if ($scope.filesToUpload !== null) {
        //storageFactory.uploadFile(orgID, $scope.filesToUpload);
        $scope.filesScope.showLoader();
        Promise.all(
          storageFactory.uploadFiles($scope.orgID, $scope.filesToUpload)
        ).then(function (success) {
          $scope.filesScope.hideLoader();
          filesScope.closePanelUpload();
        });

        /* $scope.filesScope.showLoader();
        async.eachSeries($scope.filesToUpload, function iteratee(item, callback) {
          async.setImmediate(function () {
            let arr = [];
            arr.push(item);
            storageFactory.uploadFiles($scope.orgID, arr);
          });
          setTimeout(callback, 0);
          console.log(1);
        }, function done() {
          $scope.filesScope.hideLoader();
          filesScope.closePanelUpload();
          console.log("done");
        }, function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
          }
        }); */
      }
    };

    $scope.uploadedFile = function (element) {
      $scope.$apply(function ($scope) {
        $scope.filesToUpload = element.files;
        $scope.errorMessage = "";

        if (!$scope.checkFilesSize($scope.filesToUpload)) {
          $scope.storageOverflow = true;
          $scope.errorMessage =
            "Não tem espaço suficiente para carregar o(s) ficheiro(s) selecionado(s). ";
        } else {
          $scope.storageOverflow = false;
        }

        if (!$scope.checkFilesName($scope.filesToUpload)) {
          $scope.invalidNames = true;
          $scope.errorMessage =
            $scope.errorMessage +
            "Não pode carregar ficheiro(s) com o mesmo nome que outro(s) já carregado(s).";
        } else {
          $scope.invalidNames = false;
        }
      });
    };

    $scope.checkFilesSize = function checkFilesSize(files) {
      $scope.toUploadFilesSize = 0;

      for (let u = 0; u < files.length; u++) {
        const file = files[u];
        $scope.toUploadFilesSize = $scope.toUploadFilesSize + file.size;
      }
      $scope.parsedToUplaodFilesSize = $scope.filesScope.parseFileSize(
        $scope.toUploadFilesSize
      );
      if ($scope.toUploadFilesSize + $scope.usedSpace > 1073741824) {
        return false;
        $mdDialog.show(
          $mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("Tamanho dos Ficheiros a Carregar")
            .textContent(
              "É impossível carregar os ficheiros selecionados pois não há espaço disponível suficiente."
            )
            .ariaLabel("Tamanho dos Ficheiros a Carregar")
            .ok("Ok")
        );
      } else {
        return true;
      }
    };

    $scope.checkFilesName = function checkFilesName(files) {
      for (let i = 0; i < $scope.uploadedFiles.length; i++) {
        let uploadedFile = $scope.uploadedFiles[i];
        for (let u = 0; u < files.length; u++) {
          let toUploadFile = files[u];
          if (toUploadFile.name == uploadedFile.name) {
            return false;
          }
        }
      }
      return true;
    };

    /* $scope.checkFileNames = function checkFileNames(files) {
      for (let u = 0; u < files.length; u++) {
        const element = files[u];
        if (element.name.match(/^[0-9]{4}\./g) == null) {
          $mdDialog.show(
            $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('Nome de Ficheiro Incorrecto')
            .textContent("O nome do ficheiro deve corresponder a um ano, por exemplo '2016.zip'.")
            .ariaLabel('Nome de Ficheiro Incorrecto')
            .ok('Ok')
          );
          return false;
        }
      }
      return true;
    }; */
  },
]);
