var app = angular.module("analytics");

app.controller("ReportsCtrl", [
  "$scope",
  "$mdPanel",
  "categoryFactory",
  "organizationFactory",
  "reportFactory",
  "storageFactory",
  "exportUiGridService",
  "uiGridConstants",
  function (
    $scope,
    $mdPanel,
    categoryFactory,
    organizationFactory,
    reportFactory,
    storageFactory,
    exportUiGridService,
    uiGridConstants
  ) {
    var self = this;
    var mode = "create";

    $scope.$parent.setTitle("Reports");

    $scope.organizationData = organizationFactory.data;
    $scope.categoryData = categoryFactory.data;
    $scope.topFilters = {};
    $scope.topFilterData = {};
    $scope.topFilterData["Organização"] = organizationFactory.data.map((org) => (org = org.name));

    $scope.getOrgName = function (orgId) {
      var filteredOrg = $scope.organizationData.filter((e) => e.id === orgId);

      if (filteredOrg.length) {
        return filteredOrg[0].name;
      } else {
        return "";
      }
    };

    $scope.getCatName = function (catId) {
      var filteredCat = $scope.categoryData.filter((e) => e.id === catId);

      if (filteredCat.length) {
        return filteredCat[0].name;
      } else {
        return "";
      }
    };

    $scope.$watch(
      "[topFilters]",
      function (filters) {
        if (
          !(Object.keys($scope.topFilters).length === 0 && $scope.topFilters.constructor === Object) &&
          $scope.gridApi.grid.columns.length > 0
        ) {
          for (var filter in Object.values(filters)[0]) {
            if (Object.values(filters)[0][filter] === "Todos" || Object.values(filters)[0][filter] === "") {
              $scope.gridApi.grid.columns[1].filters[0].term = "";
            } else {
              $scope.gridApi.grid.columns[1].filters[0].term = Object.values(filters)[0][filter];
            }
          }
        }
      },
      true
    );

    var columns = [
      {
        field: "orgName",
        sort: {
          direction: uiGridConstants.ASC,
          ignoreSort: false,
          priority: 0,
        },
      },
      {
        field: "name",
        displayName: "Report Name",
        sort: {
          direction: uiGridConstants.ASC,
          ignoreSort: false,
          priority: 1,
        },
      },
      {
        field: "src",
        visible: false,
      },
      {
        field: "organization",
        visible: false,
        filter: {
          condition: function (searchTerm, cellValue, row, column) {
            if (cellValue != null || cellValue != undefined) {
              var re = new RegExp("^" + searchTerm + "$", "gi");
              return cellValue.match(re);
            }
            return null;
          },
        },
      },
      {
        field: "catName",
        displayName: "Category",
      },
      {
        field: "srcType",
        displayName: "Source Type",
      },
      {
        field: "public",
      },
      {
        field: "category",
        visible: false,
      },
      {
        field: "order",
      },
    ];

    $scope.gridOptions = {
      enableRowSelection: true,
      enableFullRowSelection: true,
      multiSelect: false,
      enableFiltering: true,
      enableGridMenu: true,
      enableColumnResizing: true,
      exporterOlderExcelCompatibility: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: "Export all data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "all", "all");
          },
          order: 110,
        },
        {
          title: "Export visible data as EXCEL",
          action: function ($event) {
            exportUiGridService.exportToExcel("sheet 1", $scope.gridApi, "visible", "visible");
          },
          order: 111,
        },
      ],
      columnDefs: columns,
    };

    reportFactory.setParentScope($scope);

    $scope.clearForm = function () {
      // reportFactory.setSelected({});
      $scope.gridApi.selection.clearSelectedRows();
      $scope.selectedRow = null;
      reportFactory.setSelected(null);
    };

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged($scope, function (row) {
        if (row.isSelected) {
          $scope.selectedRow = row.entity;
          reportFactory.setSelected(row.entity);
        } else {
          $scope.selectedRow = null;
          reportFactory.setSelected(null);
        }
      });
    };

    function getReports() {
      reportFactory.getAll().then(function (data) {
        data.forEach(function (rep) {
          rep.orgName = $scope.getOrgName(rep.organization);
          rep.catName = $scope.getCatName(rep.category);
          rep.srcType = rep.reportBuilderSrc != null ? "RB" : "PBI";
        });

        $scope.gridOptions.data = data;
      });
    }

    getReports();

    $scope.openPanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "ReportsPanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/reportsPanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        onDomRemoved: function () {
          $scope.selectedRow = null;
          getReports();
        },
        locals: {
          categoryScope: $scope,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.panelRef = result;
      });
    };

    $scope.closePanel = function () {
      $scope.panelRef.close();
      // if (mode === "create") {
      //     getReports();
      // }
    };

    $scope.create = function (event) {
      $scope.clearForm();
      mode = "create";
      $scope.openPanel();
    };

    $scope.modify = function (event) {
      mode = "modify";
      $scope.openPanel();
    };

    $scope.delete = function (event) {
      reportFactory.delete($scope.selectedRow.id).then(function () {
        getReports();
        storageFactory.deleteContainer($scope.selectedRow.id);
      });
    };
  },
]);
