import React, { useEffect, useState, useRef } from "react";
import "../../stylesheets/react.scss";

const KSTKAnalyticsSideNav = ({ org, menu, catModuleReportClick }) => {
  const [openSubItems, setOpenSubItems] = useState([]);

  const handleCatModuleReportClick = (elementId, mode, arg1, arg2) => {
    catModuleReportClick(elementId, mode, arg1, arg2);
    if (open) {
      mobileTabletMenuOpenCloseSideNav();
    }
  };

  const catHasSubItems = (catId, catType) => {
    if (catType == "module") {
      return (
        menu.filter((menuEntry) => menuEntry.category == catId && menuEntry.tabs != null && !menuEntry.moduleCat)
          .length > 1
      );
    } else if (catType == "report") {
      return (
        menu.filter((menuEntry) => menuEntry.category == catId && menuEntry.tabs == null && !menuEntry.moduleCat)
          .length > 1
      );
    }
  };

  const closeAllSubItems = () => {
    openSubItems.forEach((subItem) => {
      document.getElementById(subItem).style.display = "none";
    });
    setOpenSubItems([]);
  };

  const isElementHidden = (element) => {
    return window.getComputedStyle(element, null).getPropertyValue("display") === "none";
  };

  const openCloseSubItemsMenu = (e, menuId) => {
    e.stopPropagation();
    const newCardsArray = [...openSubItems];
    if (isElementHidden(document.getElementById(menuId))) {
      document.getElementById(menuId).style.display = "flex";
      newCardsArray.push(menuId);
    } else {
      document.getElementById(menuId).style.display = "none";
      newCardsArray.splice(newCardsArray.indexOf(menuId), 1);
    }
    setOpenSubItems(newCardsArray);
  };

  /* Mobile menu functions */

  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    toggleSideNav(true);
    setOpen(false);
  };

  const mobileTabletMenuOpenCloseSideNav = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
      closeAllSubItems();
    }
    toggleSideNav();
  };

  const toggleSideNav = (closeFlag) => {
    let sideNavWrapper = document.getElementsByClassName("kstkSideNavWrapper")[0];
    let sideNavShortFooter = document.getElementsByClassName("kstkSideNavShortFooterWrapper")[0];
    let sideNavLongFooter = document.getElementsByClassName("kstkSideNavLongFooterWrapper")[0];
    if (window.getComputedStyle(sideNavWrapper, null).getPropertyValue("width") === "280px" || closeFlag) {
      sideNavWrapper.style.width = "50px";
    } else {
      sideNavWrapper.style.width = "280px";
    }
    if (window.getComputedStyle(sideNavShortFooter, null).getPropertyValue("display") === "none" || closeFlag) {
      sideNavShortFooter.style.display = "flex";
    } else {
      sideNavShortFooter.style.display = "none";
    }
    if (window.getComputedStyle(sideNavLongFooter, null).getPropertyValue("visibility") === "visible" || closeFlag) {
      sideNavLongFooter.style.visibility = "hidden";
      sideNavLongFooter.style.opacity = "0";
    } else {
      sideNavLongFooter.style.visibility = "visible";
      sideNavLongFooter.style.opacity = "1";
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div className="kstkSideNavWrapper" onMouseLeave={() => closeAllSubItems()}>
      <div className="kstkSideNavHeader" onClick={(e) => handleCatModuleReportClick(null, "changePage", "home")}>
        <div className="kstkLogo"></div>
        <div className="kstkSideNavHeaderProductName">
          {org && org.orgType == "Education" ? (
            <span>
              Predictive
              <br />
              Education
            </span>
          ) : org && org.orgType == "Business" ? (
            <span>
              Predictive
              <br />
              Business
            </span>
          ) : org && org.orgType == "Analytics" ? (
            <span>
              Predictive
              <br />
              Analytics
            </span>
          ) : null}
        </div>
      </div>
      <div className="kstkSideNav" ref={node}>
        <div className="kstkSideNavMobileTabletMenu" onClick={() => mobileTabletMenuOpenCloseSideNav()}>
          <ion-icon name="menu"></ion-icon>
        </div>
        {menu ? (
          <React.Fragment>
            {/* Modules header */}
            {menu.filter((menuEntry) => menuEntry.moduleCat).length > 0 ? (
              <div className="kstkSideMenuTitleEntry">
                <div className="kstkSideMenuTitlePlaceholder">M</div>
                <div className="kstkSideMenuTitle">Módulos</div>
              </div>
            ) : null}
            {/* Modules menu entries */}
            {menu
              .filter((menuEntry) => menuEntry.moduleCat)
              .map((catInfo) => (
                <div className="kstkSideMenuEntry">
                  <div
                    className="kstkSideMenuCategory"
                    id={catInfo.id + "catMod"}
                    onClick={() =>
                      catHasSubItems(catInfo.id, "module")
                        ? handleCatModuleReportClick(catInfo.id + "catMod", "changePage", "genericCategoryView", {
                            catID: catInfo.id,
                            menuMode: "moduleView",
                          })
                        : handleCatModuleReportClick(
                            catInfo.id + "catMod",
                            "moduleReportClick",
                            menu.filter(
                              (menuEntry) =>
                                menuEntry.category == catInfo.id && !menuEntry.moduleCat && menuEntry.tabs != null
                            )[0]
                          )
                    }
                  >
                    <div className="kstkSideNavMenuEntryIcon">
                      {catInfo.icon ? <ion-icon name={catInfo.icon}></ion-icon> : <ion-icon name="grid"></ion-icon>}
                    </div>
                    <div className="kstkSideMenuCategoryName">{catInfo.name}</div>
                    <div className="spacer"></div>
                    {catHasSubItems(catInfo.id, "module") ? (
                      <div
                        className="kstkSideNavMenuEntrySubItemsChevron"
                        onClick={(e) => openCloseSubItemsMenu(e, catInfo.id + "modules")}
                      >
                        {openSubItems.indexOf(catInfo.id + "modules") == -1 ? (
                          <ion-icon name="chevron-down"></ion-icon>
                        ) : (
                          <ion-icon name="chevron-up"></ion-icon>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="kstkSideMenuCategorySubItems" id={catInfo.id + "modules"}>
                    {menu
                      .filter(
                        (menuEntry) =>
                          menuEntry.category == catInfo.id && !menuEntry.moduleCat && menuEntry.tabs != null
                      )
                      .map((catModule) => (
                        <div
                          className="kstkSideMenuCategorySubItem"
                          id={catInfo.id + "catMod" + catModule.id}
                          onClick={() =>
                            handleCatModuleReportClick(
                              catInfo.id + "catMod" + catModule.id,
                              "moduleReportClick",
                              catModule
                            )
                          }
                        >
                          {catModule.name}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            {/* Reports header */}
            {/* {Object.values(menu).filter((menuEntry) => menuEntry.reports && menuEntry.reports.length > 0).length > 0 ? (
              <div className="kstkSideMenuTitle">Indicadores</div>
            ) : null} */}
            {menu.filter((menuEntry) => menuEntry.reportCat).length > 0 ? (
              <div className="kstkSideMenuTitleEntry">
                {menu.filter((menuEntry) => menuEntry.moduleCat).length > 0 ? (
                  <div className="kstkSideNavSeparator"></div>
                ) : null}
                <div className="kstkSideMenuTitlePlaceholder">I</div>
                <div className="kstkSideMenuTitle">Indicadores</div>
              </div>
            ) : null}
            {/* Report menu entries */}
            {menu
              .filter((menuEntry) => menuEntry.reportCat)
              .map((catInfo) => (
                <div className="kstkSideMenuEntry">
                  <div
                    className="kstkSideMenuCategory"
                    id={catInfo.id + "catRep"}
                    onClick={() =>
                      catHasSubItems(catInfo.id, "report")
                        ? handleCatModuleReportClick(catInfo.id + "catRep", "changePage", "genericCategoryView", {
                            catID: catInfo.id,
                            menuMode: "reportView",
                          })
                        : handleCatModuleReportClick(
                            catInfo.id + "catRep",
                            "moduleReportClick",
                            menu.filter(
                              (menuEntry) =>
                                menuEntry.category == catInfo.id && !menuEntry.reportCat && menuEntry.tabs == null
                            )[0]
                          )
                    }
                  >
                    <div className="kstkSideNavMenuEntryIcon">
                      {catInfo.icon ? (
                        <ion-icon name={catInfo.icon}></ion-icon>
                      ) : (
                        <ion-icon name="bar-chart"></ion-icon>
                      )}
                    </div>
                    <div className="kstkSideMenuCategoryName">{catInfo.name}</div>
                    <div className="spacer"></div>
                    {catHasSubItems(catInfo.id, "report") ? (
                      <div
                        className="kstkSideNavMenuEntrySubItemsChevron"
                        onClick={(e) => openCloseSubItemsMenu(e, catInfo.id + "reports")}
                      >
                        {openSubItems.indexOf(catInfo.id + "reports") == -1 ? (
                          <ion-icon name="chevron-down"></ion-icon>
                        ) : (
                          <ion-icon name="chevron-up"></ion-icon>
                        )}
                      </div>
                    ) : null}
                  </div>

                  {catHasSubItems(catInfo.id, "report") ? (
                    <div className="kstkSideMenuCategorySubItems" id={catInfo.id + "reports"}>
                      {menu
                        .filter(
                          (menuEntry) =>
                            menuEntry.category == catInfo.id && !menuEntry.reportCat && menuEntry.tabs == null
                        )
                        .map((catReport) => (
                          <div
                            className="kstkSideMenuCategorySubItem"
                            id={catInfo.id + "catRep" + catReport.id}
                            onClick={() =>
                              handleCatModuleReportClick(
                                catInfo.id + "catRep" + catReport.id,
                                "moduleReportClick",
                                catReport
                              )
                            }
                          >
                            {catReport.name}
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              ))}
          </React.Fragment>
        ) : null}
        <div className="spacer"></div>
      </div>
      <div className="kstkSideNavFooterWrapper">
        <div className="kstkSideNavLongFooterWrapper">
          <div className="kstkSideNavLongFooter">
            {org && org.orgLogoSrc ? <img class="kstkSideNavLongFooterOrgLogo" src={org.orgLogoSrc} /> : null}
            <div className="kstkSideNavFooterOrgName">{org && org.orgName ? org.orgName : null}</div>
          </div>
        </div>
        <div className="kstkSideNavShortFooterWrapper">
          {org && org.orgLogoSrc ? (
            <div className="kstkSideNavShortFooter">
              <img class="kstkSideNavShortFooterOrgLogo" src={org.orgLogoSrc} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default KSTKAnalyticsSideNav;
